import yup, { dateSchema, textSchema, remarksSchema } from "utils/yup";

export const basicSchema = yup.object().shape({
  installationWorkplace: yup.object().required(),
  shippedOn: dateSchema
    .required()
    .test(
      "shippedOn",
      "移動開始日は設置日と同じかそれより前である必要があります。",
      function (value) {
        const { installedOn } = this.parent;
        if (installedOn) {
          return value <= installedOn;
        }
        return true;
      }
    ),
  installedOn: dateSchema
    .required()
    .test(
      "installedOn",
      "設置日は移動開始日と同じかそれより後である必要があります。",
      function (value) {
        const { shippedOn } = this.parent;
        if (shippedOn) {
          return value >= shippedOn;
        }
        return true;
      }
    ),
  vehicle: yup.object().nullable(),
  personInChargeName: textSchema,
  remarks: remarksSchema.nullable(),
});
