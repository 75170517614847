/**
 * Generates a list of data for a specific month, filling in missing dates with hyphens ('-').
 * @param {Array} apiData - The API response data containing schedule information.
 * @param {number} year - The year for which data should be generated.
 * @param {number} month - The month (1-12) for which data should be generated.
 * @returns {Array} - An array of objects representing schedule data for the specified month.
 */
export const generateDataWithHyphens = (apiData, year, month) => {
  // Calculate the start and end dates of the specified month
  const startDate = new Date(year, month - 1, 1); // Month is 0-indexed, so subtract 1
  const endDate = new Date(year, month, 0); // Get the last day of the specified month

  // Initialize an array to store the result data
  const resultData = [];

  // Initialize a currentDate variable to iterate through dates
  let currentDate = startDate;

  // Iterate through dates in the specified month
  while (currentDate <= endDate) {
    const dayOfMonth = currentDate.getDate();

    // Format the date as "YYYY-MM-DD"
    const dateString = `${year}-${month
      .toString()
      .padStart(2, "0")}-${dayOfMonth.toString().padStart(2, "0")}`;

    const apiDataForDate = apiData.find(
      (item) => item.scheduleDate === dateString
    );
    if (apiDataForDate) {
      resultData.push(apiDataForDate);
    } else {
      resultData.push({
        scheduleDate: dateString,
        unassignedCount: null,
        assignedCount: null,
        lastUpdatedOn: null,
      });
    }
    currentDate.setDate(currentDate.getDate() + 1);
  }

  return resultData;
};

/**
 * Formats a given number of seconds into a human-readable time string.
 *
 * @param {number} seconds - The number of seconds to format.
 * @returns {string} A formatted time string in the format "X minutes and Y seconds".
 */
export const formatTime = (seconds) => {
  // Calculate the number of minutes and remaining seconds
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;

  // Create a formatted time string based on the calculated values
  let timeString = minutes ? `${minutes} 分 と` : "";
  timeString += ` ${remainingSeconds} 秒`;

  return timeString;
};
