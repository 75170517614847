import { DomainOutlined } from "@mui/icons-material";
import { Avatar, Button, Link, Typography } from "@mui/material";
import { DateTime } from "luxon";
import { PhoneNumberFormatUtil } from "utils/format";
import { InformationItem } from "views/atoms";
import { FullScreenDialog } from "views/molecules";
import { DetailsFrame } from "views/organisms/DetailsFrame";
import { CorporationForm } from "views/organisms/Masters/Corporation";

/**
 * 事業者の表示を行うコンテナプレゼンテーションです
 * @param {object} classes クラス名
 * @param {object} value 事業者情報
 * @param {boolean} open ダイアログを有効か無効にする
 * @param {funct} onOpenDialog ダイアログ処理
 * @param {funct} onCloseDialog ダイアログ処理
 * @returns
 */
export const Presententer = (props) => {
  return (
    <>
      <DetailsFrame
        caption={
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Avatar
              sx={{
                marginRight: 3,
                width: 58,
                height: 58,
              }}
            >
              <DomainOutlined sx={{ fontSize: "2.5rem" }} />
            </Avatar>
            <Typography variant="h5">{props?.value?.name}</Typography>
          </div>
        }
        actions={
          <Button
            variant="contained"
            color="primary"
            onClick={props.onOpenDialog}
            disabled={!props.value}
          >
            {"事業者情報を編集する"}
          </Button>
        }
      >
        <div className={props.classes.box_content}>
          <InformationItem label="法人番号">
            {props.value?.corporateNumber}
          </InformationItem>
          <InformationItem label="代表者">
            {props.value?.representativeName}
          </InformationItem>
          <InformationItem label="設立">
            {props.value?.establishmentedOn
              ? DateTime.fromISO(props.value.establishmentedOn).toFormat(
                  "yyyy/MM/dd"
                )
              : ""}
          </InformationItem>
          <InformationItem label="本社所在地">
            {props.value?.headOffice &&
              `〒${props?.value?.headOffice?.postalCode ?? ""} ${props?.value?.headOffice?.city ?? ""}${props?.value?.headOffice?.streetAddress  ?? ""}${props?.value?.headOffice?.otherAddress ?? ""}`}
          </InformationItem>
          <InformationItem label="本社電話番号">
            {PhoneNumberFormatUtil.formatNational(
              props?.value?.headOffice?.phone
            )}
          </InformationItem>
          <InformationItem label="本社FAX番号">
            {PhoneNumberFormatUtil.formatNational(props.value?.headOffice?.fax)}
          </InformationItem>
          <InformationItem label="本社メールアドレス">
            {props.value?.headOffice?.email}
          </InformationItem>
          <InformationItem label="企業ホームページ">
            {props.value?.url && (
              <Link
                href={props.value.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                {props.value.url}
              </Link>
            )}
          </InformationItem>
        </div>
        <FullScreenDialog
          formId="corporation-form"
          open={props.open}
          title="事業者情報の編集"
          textConfirm="保存"
          onClose={props.onCloseDialog}
          isSubmit={props.isSubmit}
          disabled={props.isSubmit}
        >
          <CorporationForm
            companyId={props.value?.id}
            id="corporation-form"
            onSubmit={props.onSubmit}
            value={props.value}
          />
        </FullScreenDialog>
      </DetailsFrame>
    </>
  );
};

export default Presententer;
