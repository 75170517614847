import { Document, Page, pdfjs } from "react-pdf";
import { useState, useMemo } from "react";
import { CircularProgress } from "@mui/material";

import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";

pdfjs.GlobalWorkerOptions.workerSrc = `${process.env.PUBLIC_URL}/pdf.worker.min.mjs`;

/**
 * PDFを表示するコンポーネント。
 * @param {object} props プロパティ
 * @param {object} props.file PDFファイル
 * @param {number} props.width 横幅
 * @returns {JSX.Element}
 */
export const SinglePDF = ({ file = null, width = undefined }) => {
  const [numPages, setNumPages] = useState(null);

  const options = useMemo(() => {
    return {
      cMapUrl: `${process.env.PUBLIC_URL}/cmaps/`,
      cMapPacked: true,
      standardFontDataUrl: `${process.env.PUBLIC_URL}/standard_fonts/`,
    };
  }, []);

  if (!file) {
    return <></>;
  }

  return (
    <Document
      file={file}
      onLoadSuccess={({ numPages }) => setNumPages(numPages)}
      loading={<CircularProgress />}
      options={options}
    >
      {Array.from(new Array(numPages), (el, index) => (
        <Page key={`page_${index + 1}`} pageNumber={index + 1} width={width} />
      ))}
    </Document>
  );
};
