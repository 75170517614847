import { useState, useMemo, useRef, useEffect } from "react";
import { unionBy, difference, uniqBy, differenceBy } from "lodash";
import { API, graphqlOperation } from "utils/graphqlOperation";
import {
  searchUserAccountsByOffset,
  searchVehiclesByOffset,
  searchWasteCollectionSchedulesByOffset,
  getLogisticsWorkAllocationPointsByCourse,
} from "api/graphql/queries";
import { debugLog } from "utils/log";
import { companySelector } from "ducks/Company";
import { useSelector } from "react-redux";
import { handleOnDragEnd } from "utils/BeautifulDnDFunctions";
import {
  moveToUnassigned,
  moveToCourse,
  reorderOfSchedule,
  reorderFromDropResult,
} from "views/organisms/Allocation/Schedule/Utils";
import { usePreviousValue } from "utils/hooks";
import { findArrayDifferences } from "views/organisms/Allocation/utils";
import { searchForKeys } from "utils/functions";
import { useIsFirstRender } from "utils/hooks";
import * as utils from "./utils";

const menuList = [
  {
    id: "sortByCollectionTime",
    title: "回収時間で並び替え",
  },
  {
    id: "unAssignAll",
    title: "すべての割り当てを解除",
  },
];

function searchObjectsByKeyword(data, keyword) {
  return data.filter((obj) =>
    obj.searchKeys.some((key) => key.includes(keyword))
  );
}

const limit = 50;
const objKeys = ["name"];
/**
 * 配車計画を入力するコンテナコンポーネントです。
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {object} props その他プロパティ
 * @param {object} items 値
 * @param {func}  setItems 「項目」を設定します
 * @param {func} onSubmit
 * @param {object} course アクティブなコースを開催しております。
 * @param {func} setValue
 * @returns {JSX.Element}
 */
export const Container = ({
  render,
  submit,
  course,
  setItems,
  setValue,
  items,
  onSubmit = (data) => debugLog("vehicleAllocationForm.submit", data),
  isPointsLoading,
  setIsPointsLoading,
  ...props
}) => {
  const [wordOfStillCollects, setWordOfStillCollects] = useState("");
  const [renderDirection, setRenderDirection] = useState(false);
  const [activeCollectionPointId, setActiveCollectionPointId] = useState(null);
  const [isMapRenderAvailable, setIsMapRenderAvailable] = useState(true);
  const [isSearching, setIsSearching] = useState(false);
  const [drivers, setDrivers] = useState([]);
  const [vehicles, setVhicles] = useState([]);
  const company = useSelector(companySelector);
  const [total, setTotal] = useState(0);
  const [offset, setOffset] = useState(0);
  const [fetchedItemsCount, setFetchedItemsCount] = useState(0);
  const [temporaryState, setTemporaryState] = useState(null);
  const [movingPoint, setMovingPoint] = useState({
    id: null,
  });
  const [loadingMore, setLoadingMore] = useState(false);
  const [mapCenter, setMapCenter] = useState({
    lat: 35.681236,
    lng: 139.767125,
    zoom: 6,
  });

  const isFirst = useIsFirstRender();
  const listRef = useRef(null);
  const mapRef = useRef(null);

  useEffect(() => {
    API.graphql(graphqlOperation(searchUserAccountsByOffset))
      .then((res) => {
        setDrivers(res.data.searchUserAccountsByOffset.items);
      })
      .catch((err) => {
        debugLog("ユーザー情報の取得に失敗: ", err);
      });

    API.graphql(
      graphqlOperation(searchVehiclesByOffset, {
        ownerCompanyId: company.id,
      })
    )
      .then((res) => {
        setVhicles(res.data.searchVehiclesByOffset.items);
      })
      .catch((err) => {
        debugLog("車両情報の取得に失敗: ", err);
      });
  }, [company.id]);

  useEffect(() => {
    if (props.openedCourseId && !props.isCreateNew) {
      setIsPointsLoading(true);
      setRenderDirection(false);
      const coursePointsFetchQuery = graphqlOperation(
        getLogisticsWorkAllocationPointsByCourse,
        {
          id: props.openedCourseId,
        }
      );
      const unassignedPointsFetchQuery = graphqlOperation(
        searchWasteCollectionSchedulesByOffset,
        {
          filter: {
            and: [
              {
                assigned: { eq: false },
                or: [
                  { date: { eq: props.date?.toISODate() } },
                  { date: { eq: props.date?.plus({ day: 1 }).toISODate() } },
                ],
              },
            ],
          },
          sort: {
            field: "date",
          },
          limit,
          offset: 0,
        }
      );
      Promise.all([
        API.graphql(coursePointsFetchQuery),
        API.graphql(unassignedPointsFetchQuery),
      ])
        .then(([coursePoints, unassingedPoints]) => {
          const coursePointResponse =
            coursePoints.data.getLogisticsWorkAllocationPointsByCourse;
          const unassignedPointsResponse =
            unassingedPoints.data.searchWasteCollectionSchedulesByOffset.items;
          setOffset(0);
          setTotal(
            unassingedPoints.data.searchWasteCollectionSchedulesByOffset.total
          );
          setFetchedItemsCount(unassignedPointsResponse.length);
          setItems((prevData) => {
            return {
              ...prevData,
              still: unassignedPointsResponse,
            };
          });
          props.setFetchedCount(unassignedPointsResponse.length);
          setValue((prevProps) => {
            if (!prevProps.done) {
              return prevProps;
            }
            return {
              ...prevProps,
              done: {
                ...prevProps.done,
                courses: prevProps.done?.courses?.map((course) => {
                  if (course.id === coursePointResponse.id) {
                    return {
                      ...course,
                      points: coursePointResponse.points,
                    };
                  }
                  return course;
                }),
              },
            };
          });
        })
        .catch((err) => {
          debugLog("コースポイントの取得に失敗: ", err);
        })
        .finally(() => {
          setIsPointsLoading(false);
          setIsMapRenderAvailable(true);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.openedCourseId]);

  const fetchSearchUnassignedItems = (offSetValue) => {
    if (wordOfStillCollects) {
      if (offSetValue === 0) {
        setIsSearching(true);
        setTemporaryState({
          still: items.still,
        });
      } else {
        setLoadingMore(true);
      }
      API.graphql(
        graphqlOperation(searchWasteCollectionSchedulesByOffset, {
          filter: {
            and: [
              {
                assigned: { eq: false },
                or: [
                  { date: { eq: props.date?.toISODate() } },
                  { date: { eq: props.date?.plus({ day: 1 }).toISODate() } },
                ],
              },
            ],
          },
          sort: {
            field: "date",
          },
          limit,
          offset: offSetValue,
          searchQuery: wordOfStillCollects,
        })
      )
        .then((res) => {
          const searchedItems =
            res.data.searchWasteCollectionSchedulesByOffset.items;
          let foundItems = [];
          if (offSetValue === 0) {
            const searchedId = searchedItems.map((item) => item.id);
            const stillIds = items.still.map((item) => item.id);
            const localItemIds = difference(stillIds, searchedId);
            const localItems = items.still
              .filter((item) => localItemIds.includes(item.id))
              .map((item) => {
                return {
                  item,
                  searchKeys: searchForKeys(item, objKeys),
                };
              });

            foundItems = searchObjectsByKeyword(
              localItems,
              wordOfStillCollects
            ).map((v) => v.item);
          }

          setItems((prevData) => {
            return {
              ...prevData,
              still:
                offSetValue === 0
                  ? differenceBy(
                      uniqBy([...searchedItems, ...foundItems], "id"),
                      course.points,
                      "id"
                    )
                  : differenceBy(
                      [...prevData.still, ...searchedItems],
                      course.points,
                      "id"
                    ),
            };
          });
          setTotal(res.data.searchWasteCollectionSchedulesByOffset.total);
          setFetchedItemsCount((prevState) => prevState + searchedItems.length);
        })
        .catch((err) => {
          debugLog("車両情報の取得に失敗: ", err);
        })
        .finally(() => {
          setIsSearching(false);
          setLoadingMore(false);
        });
    } else {
      setItems((prevItems) => {
        return {
          ...prevItems,
          still: differenceBy(
            temporaryState?.still || [],
            course.points || [],
            "id"
          ),
        };
      });
      setFetchedItemsCount(0);
    }
  };

  useEffect(() => {
    if (!isFirst) {
      setOffset(0);
      !props.isCreateNew && fetchSearchUnassignedItems(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wordOfStillCollects]);

  const handleMove = (params) => {
    setRenderDirection(false);
    setIsMapRenderAvailable(true);

    if (params.destination.droppableId === "stillCollects") {
      params = {
        ...params,
        source: {
          ...params.source,
          droppableId: course.id,
        },
      };
      const currentItems = moveToUnassigned(params, items);
      setItems({
        ...currentItems,
        still: currentItems.still,
      });
    } else if (params.source.droppableId === "stillCollects") {
      params = {
        ...params,
        destination: {
          ...params.destination,
          droppableId: course.id,
        },
      };
      setItems(moveToCourse(params, items));
    }
  };

  const handleReorder = (params) => {
    setRenderDirection(false);
    if (params.source.droppableId === "stillCollects") {
      setItems((prevState) => ({
        ...prevState,
        still: reorderFromDropResult(items.still, params),
      }));
    } else {
      const newParams = {
        ...params,
        source: {
          ...params.source,
          droppableId: course.id,
        },
        destination: {
          ...params.destination,
          droppableId: course.id,
        },
      };
      setItems(reorderOfSchedule(items, newParams));
    }
  };

  const onSearchStillCollects = (word) => {
    // 未配車の回収スケジュールから検索
    setWordOfStillCollects(word);
  };

  const handleRenderDirection = () => {
    setRenderDirection(true);
    setIsMapRenderAvailable(false);
  };

  /* The `markerPoints` variable is using the `useMemo` hook to memoize the result of a function. */
  const markerPoints = useMemo(() => {
    if (!course?.points || course.points.length === 0) {
      return [];
    }

    const updatedPoints = course.points
      .map((v) => {
        const position = v.wasteCollectionWorkplace?.position;
        const lat = position ? position.lat : null;
        const lng = position ? position.lng : null;

        if (lat && lng) {
          return {
            id: v.id,
            lat,
            lng,
          };
        }
        return null;
      })
      .filter((item) => item);

    /* The code is splitting the `updatedPoints` array into smaller arrays, each containing a maximum of
     25 elements because google map only support 25 waypoints each time . */
    const splitPoints = [];
    for (let i = 0; i < updatedPoints.length; i += 24) {
      const subarray = updatedPoints.slice(i, i + 24);
      if (i + 24 < updatedPoints.length) {
        subarray.push(updatedPoints[i + 24]);
      }
      splitPoints.push(subarray);
    }
    return splitPoints;
  }, [course?.points]);

  const handleMenuItemClick = (itemId) => {
    const currentCourseIndex = items?.done?.courses.findIndex(
      (item) => item.id === course.id
    );
    switch (itemId) {
      case menuList[0].id: {
        if (currentCourseIndex !== -1) {
          setItems((prevObj) => ({
            ...prevObj,
            done: utils.ordersByTimeRange(items.done, currentCourseIndex),
          }));
        }
        break;
      }
      case menuList[1].id: {
        setItems(utils.moveToUnassign(items, currentCourseIndex));
        break;
      }
      default:
        break;
    }
  };

  const scrollToItem = (index) => {
    // Calculate the scroll position based on the index of the item
    const itemHeight = 162;
    const scrollPosition = index * itemHeight;
    listRef.current.scrollTo(scrollPosition, "start");
  };

  const onClickMarker = ({ id }) => {
    const center = mapRef?.current?.getCenter();
    setMapCenter({
      lat: center.lat(),
      lng: center.lng(),
      zoom: mapRef?.current.getZoom() || 16,
    });
    if (activeCollectionPointId === id) {
      setActiveCollectionPointId(null);
    } else {
      setActiveCollectionPointId(id);
    }
    const coursePointIndex = course?.points.findIndex((v) => v.id === id);
    scrollToItem(coursePointIndex);
  };

  const markerPositions = useMemo(() => {
    return unionBy(markerPoints.flat(), (v) => v.id).map((item) => {
      return {
        ...item,
        label: course?.points.find((v) => v.id === item.id).order,
      };
    });
  }, [markerPoints, course?.points]);

  const prevItem = usePreviousValue(markerPositions);

  useEffect(() => {
    if (prevItem && prevItem !== markerPositions) {
      const diff = findArrayDifferences(markerPositions, prevItem);
      if (diff.length === 1 && diff[0].type === "add") {
        if (diff[0].item.lat && diff[0].item.lng) {
          setMapCenter({
            lat: diff[0].item.lat,
            lng: diff[0].item.lng,
            zoom: 12,
          });
        }
        setMovingPoint({
          id: diff[0].item.id,
        });
        setIsMapRenderAvailable(true);
      }
    }
  }, [markerPositions, prevItem, course]);

  const handleChangeDrivers = (val) => {
    setItems((prevState) => {
      return {
        ...prevState,
        done: {
          ...prevState.done,
          courses: prevState?.done?.courses.map((item) => {
            if (item.id === course.id) {
              return {
                ...item,
                assignedUsers: val,
              };
            }
            return item;
          }),
        },
      };
    });
  };

  const handleChangeName = (val) => {
    setItems((prevState) => ({
      ...prevState,
      done: {
        ...prevState.done,
        courses: prevState?.done?.courses.map((item) => {
          if (item.id === course.id) {
            return {
              ...item,
              name: val,
            };
          }
          return item;
        }),
      },
    }));
  };

  const handleChangeVehicle = (val) => {
    setItems((prevState) => {
      return {
        ...prevState,
        done: {
          ...prevState.done,
          courses: prevState?.done?.courses.map((item) => {
            if (item.id === course.id) {
              return {
                ...item,
                assignedVehicle: val,
              };
            }
            return item;
          }),
        },
      };
    });
  };

  const hasNextPage = wordOfStillCollects
    ? fetchedItemsCount < total
    : props.hasNextPage;

  const handleLoadMore = (value) => {
    if (wordOfStillCollects) {
      if (value > 0) {
        const offsetValue = offset + limit;
        setOffset(offsetValue);
        fetchSearchUnassignedItems(offsetValue);
      }
    } else {
      props.handleLoadMore(value);
    }
  };

  useEffect(() => {
    if (markerPositions?.[0]) {
      setMapCenter({
        lat: markerPositions[0].lat,
        lng: markerPositions[0].lng,
        zoom: 6,
      });
    }
  }, [markerPositions]);

  return render({
    course,
    wordOfStillCollects,
    markerPoints,
    markerPositions,
    onSearchStillCollects,
    onPointsDragEnd: handleOnDragEnd(handleReorder, handleMove),
    handleRenderDirection,
    renderDirection,
    menuList,
    handleMenuItemClick,
    isMapRenderAvailable,
    onClickMarker,
    onChangeDrivers: handleChangeDrivers,
    onChangeVehicle: handleChangeVehicle,
    handleOnChangeName: handleChangeName,
    listRef,
    activeCollectionPointId,
    items,
    drivers,
    vehicles,
    mapCenter,
    movingPoint,
    isSearching,
    isPointsLoading,
    mapRef,
    ...props,
    hasNextPage,
    handleLoadMore,
    loadingUnAssigned: wordOfStillCollects
      ? loadingMore
      : props.loadingUnAssigned,
  });
};
