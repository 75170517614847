import { createWasteFlow } from "api/graphql/mutations";
import { searchWasteFlowsByOffset } from "api/graphql/queries";
import { API, graphqlOperation } from "utils/graphqlOperation";
import { add as addAlert } from "ducks/Alert";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { XGridTableGuiderSteps } from "utils/GuiderSteps";
import { debugLog } from "utils/log";
import { useXGridComponents } from "utils/useXGridComponents";
import { FILTER_OPERATORS_CONTAINS_EQUAL } from "utils/constant";

export const columns = [
  {
    field: "number",
    headerName: "管理番号",
    filterOperators: FILTER_OPERATORS_CONTAINS_EQUAL,
  },
  {
    field: "name",
    headerName: "名称",
    valueGetter: (params) => params.row?.name,
    hide: false,
    filterOperators: FILTER_OPERATORS_CONTAINS_EQUAL,
  },
  {
    field: "targetWasteLargeClassName",
    headerName: "廃棄物種類大分類",
    valueGetter: (params) => params.row.targetWasteType?.largeClass?.name,
    filterOperators: FILTER_OPERATORS_CONTAINS_EQUAL,
  },
  {
    field: "targetWasteMiddleClassName",
    headerName: "廃棄物種類中分類",
    valueGetter: (params) => params.row.targetWasteType?.middleClass?.name,
    hide: true,
    filterOperators: FILTER_OPERATORS_CONTAINS_EQUAL,
  },
  {
    field: "targetWasteSmallClassName",
    headerName: "廃棄物種類小分類",
    valueGetter: (params) => params.row.targetWasteType?.smallClass?.name,
    hide: true,
    filterOperators: FILTER_OPERATORS_CONTAINS_EQUAL,
  },
  {
    field: "transportSectionCount",
    type: "number",
    headerName: "運搬区間数",
    valueGetter: (params) => params.row.transportSectionCount ?? 0,
    width: 160,
  },

  {
    field: "disposalCompanyName",
    headerName: "処分場",
    valueGetter: (params) => params.row.disposalProcess?.disposalCompany?.name,
    width: 160,
    filterOperators: FILTER_OPERATORS_CONTAINS_EQUAL,
  },
  {
    field: "disposalMethodLargeClassName",
    headerName: "処分方法大分類",
    valueGetter: (params) =>
      params.row.disposalProcess?.disposalMethod?.largeClassName,
    width: 160,
    filterOperators: FILTER_OPERATORS_CONTAINS_EQUAL,
  },
  {
    field: "disposalMethodMiddleClassName",
    headerName: "処分方法中分類",
    valueGetter: (params) =>
      params.row.disposalProcess?.disposalMethod?.middleClassName,
    width: 160,
    hide: true,
    filterOperators: FILTER_OPERATORS_CONTAINS_EQUAL,
  },
  {
    field: "disposalMethodSmallClassName",
    headerName: "処分方法小分類",
    valueGetter: (params) =>
      params.row.disposalProcess?.disposalMethod?.smallClassName,
    width: 160,
    hide: true,
    filterOperators: FILTER_OPERATORS_CONTAINS_EQUAL,
  },
];

/**
 * 処理フローテーブルを選択するコンテナコンポーネントです
 */
export const Container = (props) => {
  const [open, setOpen] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const xGridObject = useXGridComponents(columns, searchWasteFlowsByOffset, {
    other: {
      managedCompanyId: props.managedCompanyId,
    },
    filter: {
      ...props.fixedFilter,
    },
  });

  useEffect(() => {
    xGridObject.functions.refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.fixedFilter]);

  const formRef = useRef(null);

  const dispatch = useDispatch();

  const handleChangeSelection = (data) => {
    if (props.multiple) {
      props.onSelected(
        xGridObject.params.rows.filter((row) => data.includes(row.id))
      );
    } else {
      props.onSelected(
        xGridObject.params.rows.find((row) => row.id === data[0])
      );
    }
  };

  const handleSubmit = (data) => {
    setIsSubmit(true);
    const {
      targetWaste,
      wasteKind,
      transportProcesses,
      disposalProcess,
      disposalMethod,
      disposalDetails,
      lastDisposalWorkplaces,
      version,
      ...other
    } = data;

    API.graphql(
      graphqlOperation(createWasteFlow, {
        input: {
          managedCompanyId: props.managedCompanyId,
          targetWasteTypeId: targetWaste.id,
          targetWasteKind: wasteKind,
          transportProcesses: transportProcesses.map((item) => {
            return {
              carrierCompanyId: item.trustee.companyId,
              wasteTransportMethodCode: item.method.code,
              transportDestinationWorkplaceId: item.destination.id,
            };
          }),
          disposalProcess: {
            disposalCompanyId: disposalProcess.client.belongInCompanyId,
            disposalWorkplaceId: disposalProcess.client.id,
            wasteDisposalMethodCode: disposalProcess.method,
            disposalMethodDetails: disposalProcess.details,
          },
          lastDisposalProcesses: lastDisposalWorkplaces.map((item) => ({
            disposalWorkplaceId: item.id,
          })),
          expectedVersion: version,
          ...other,
        },
      })
    )
      .then((res) => {
        dispatch(
          addAlert({
            value: "登録しました。",
            severity: "success",
          })
        );
        xGridObject.functions.refetch();
        setOpen(false);
      })
      .catch((err) => {
        debugLog(err);
        dispatch(
          addAlert({
            value: "エラーが発生したため、登録できませんでした。",
            severity: "error",
          })
        );
      })
      .finally(() => {
        setIsSubmit(false);
      });
  };

  const handleClickRegister = () => {
    formRef.current.submit();
  };

  return props.render({
    ...props,
    value: props.value,
    open: open,
    multiple: props.multiple,
    checkbox: props.checkbox,
    onCloseDialog: () => setOpen(false),
    onOpenDialog: () => setOpen(true),
    getDataSubmit: handleSubmit,
    onChangeSelection: handleChangeSelection,
    GuiderSteps: XGridTableGuiderSteps,
    xGridParams: xGridObject.params,
    isSubmit: isSubmit,
    formRef: formRef,
    onClickRegister: handleClickRegister,
    action: props.action,
  });
};
