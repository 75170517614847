import yup, { dateSchema, remarksSchema, textSchema } from "utils/yup";

export const basicSchema = yup.object().shape({
  replacementContainer: yup.object().required(),
  storageWorkplace: yup.object().required(),
  shippedOn: dateSchema
    .required()
    .test(
      "shippedOn",
      "移動開始日は交換日または移動終了前にある必要があります。",
      function (value) {
        const { storedOn, replacedOn } = this.parent;
        if (storedOn && replacedOn) {
          return value <= storedOn && value <= replacedOn;
        }

        if (storedOn) {
          return value <= storedOn;
        }

        if (replacedOn) {
          return value <= replacedOn;
        }

        return true;
      }
    ),
  replacedOn: dateSchema
    .required()
    .test(
      "replacedOn",
      "交換日は移動開始日以降で、移動終了日以前に発生する必要があります。",
      function (value) {
        const { shippedOn, storedOn } = this.parent;
        if (shippedOn && storedOn) {
          return value >= shippedOn && value <= storedOn;
        }
        if (shippedOn) {
          return value >= shippedOn;
        }
        if (storedOn) {
          return value <= storedOn;
        }
        return true;
      }
    ),
  storedOn: dateSchema
    .required()
    .test(
      "storedOn",
      "移動終了日は交換日または移動開始日以降である必要があります。",
      function (value) {
        const { shippedOn, replacedOn } = this.parent;
        if (shippedOn && replacedOn) {
          return value >= shippedOn && value >= replacedOn;
        }
        if (shippedOn) {
          return value >= shippedOn;
        }
        if (replacedOn) {
          return value >= replacedOn;
        }
        return true;
      }
    ),
  vehicle: yup.object().nullable(),
  personInChargeName: textSchema.nullable(),
  remarks: remarksSchema.nullable(),
});
