import * as yup from "yup";
import { locale } from "../locale/ja-JP/yup";
import { PhoneNumberFormatUtil } from "./format";

/*
-------------------- yupメソッドで使用するメソッドの定義 --------------------
*/

/**
 * 半角数字であるか確認します。
 * @param {string} value 文字列
 * @returns {boolean}
 */
const IsNumeric = (value = "") => {
  return value.match(/^[0-9]+$/);
};

/**
 * 指定された値が文字列型であるか確認します。
 * @param {object} value 値
 * @returns {boolean} true: 文字列型。false: 文字列型ではない。
 */
const isStringType = (value) => {
  return typeof value === "string";
};

/**
 * JWNETで使用できる文字であるかを確認します。
 * @param {string} value 文字列
 * @returns {boolean} JWNETで使用できる文字であるかどうか
 */
const enableJWNET = (value = "") => {
  if (value === null || value === "") {
    return true;
  }

  if (!isStringType(value)) {
    throw new Error("文字列を指定してください。");
  }

  for (let c of value) {
    if (
      !c.match(
        /[\s　\x20-\x24\x26\x28-\x3A\x3F-\x7E０-９Ａ-Ｚａ-ｚぁ-んァ-ヶ一-龠＼￠￡\u00A7-\u00F7Α-Ωα-ωЁА-ё\u2010―-※℃Å←-↓⇒⇔∀-∋\u2212∝∞∧∨∬∽≠≦-≫⊂-⊇⌒─-╋■-▽◆-◯★☆♀♂♪-♯、-〕～゛-ゞ・-ヾ！-／：-＠￣￥\n\r-]/g
      )
    ) {
      return false;
    }
  }

  return true;
};

/**
 * ascii文字列であるか確認します。
 * @param {string} value - 文字列
 * @returns true: ascii文字列である false: ascii文字列ではない
 */
const IsASCII = (value) => {
  return value?.match(/^[\x20-\x7e]*$/) ? true : false;
};

/**
 * 半角文字を1バイト、その他文字を2バイトでバイト数を取得します。
 * @param {string} value - 文字列
 * @returns バイト数
 */
const StringByteLength = (value = "") => {
  if (value === null) {
    return 0;
  }

  let result = 0;

  for (const character of value) {
    result += IsASCII(character) ? 1 : 2;
  }

  return result;
};

/**
 * 半角英数であるか確認します。
 * @param {string} value - 文字列
 */
const IsHalfWidthAlphanumeric = (value = "") => {
  if (value === null) {
    return true;
  }

  return !!value.match(/^[A-Za-z0-9]*$/);
};

/**
 * 文字型の数字を加算します。
 * @param  {...any} numbersString 文字数字可変引数
 * @returns {Number}
 */
const addNumericFromString = (...numbersString) => {
  let total = 0;
  for (const number of numbersString) {
    total += Number(number);
  }
  return total;
};

/**
 * 法人番号からチェックディジットを計算し、妥当であるか確認します。
 * @param {string} value 法人番号
 * @description 会社法人等番号(12桁) + チェックディジット(1桁) = 法人番号。長さが13桁ではないまたは数字文字列以外の文字が入っている場合はtrueを返します。
 * @returns {boolean}
 */
const validCorporateNumberCheckDigits = (value) => {
  if (value?.length !== 13 || !IsNumeric(value)) {
    return true;
  }

  const evenNumber = addNumericFromString(
    value.charAt(1),
    value.charAt(3),
    value.charAt(5),
    value.charAt(7),
    value.charAt(9),
    value.charAt(11)
  );

  const oddNumber = addNumericFromString(
    value.charAt(2),
    value.charAt(4),
    value.charAt(6),
    value.charAt(8),
    value.charAt(10),
    value.charAt(12)
  );
  // 法人番号チェックデジット算出方法
  //  1. [会社法人番号(12桁)の偶数桁の和]を求める...以下evenとする
  //  2. [会社法人番号(12桁)の奇数桁の和]を求める...以下oddとする
  //  3. [even * 2 + odd] を求め、それを[9で割った余り]を求める...以下preResult
  //  4. [9 - preResult]を行った結果がチェックデジットとなる
  return Number(value.charAt(0)) === 9 - ((evenNumber * 2 + oddNumber) % 9);
};

/**
 * 2つの日付を比較し、値を返します。
 * @param {Date} dateA - 日付
 * @param {Date} dateB - 日付
 * @returns -1: 比較できなかった。
 * 0: 同じ日付。
 * 1: dateAの方が新しい日付。
 * 2: dateBの方が新しい日付。
 */
const CompareDate = (dateA, dateB) => {
  if (!dateA || !dateB) {
    return -1;
  }

  if (dateA > dateB) {
    return 1;
  }

  if (dateA < dateB) {
    return 2;
  }

  return 0;
};

/**
 * 指定されたモードで検証し、結果を返します。
 * @param {date} dateA - 日付
 * @param {date} dateB - 日付
 * @param {string} mode - モード 'same(dateAとBは同じ日付)', 'future(dateAの方が新しい日付)', 'past(dateAの方が過去の日付)'
 * @param {bool} includeSame - 同じ値を含めるか
 * @returns 指定されたモードの結果になった場合 true
 */
const ValidDate = ({ dateA, dateB, mode, includeSame = true }) => {
  switch (CompareDate(dateA, dateB)) {
    case 0:
      return includeSame || mode === "same";
    case 1:
      return mode === "future";
    case 2:
      return mode === "past";
    default:
      return false;
  }
};

/**
 * 現在の日付を生成します。
 * @returns {date} - 今日の日付(時間などは0に設定されたもの)
 */
const CreateToday = () => {
  let now = new Date();
  return new Date(now.getFullYear(), now.getMonth(), now.getDate());
};

/*
-------------------- yupメソッド定義 --------------------
*/

// 汎用文字列操作用
yup.addMethod(yup.string, "replace", function (pattern, replacement) {
  return this.transform((val) => val.replace(pattern, replacement));
});

// 電話番号
yup.addMethod(yup.string, "phone", function () {
  return this.test("phone", "形式が違います。", function (value) {
    if (!value) {
      return true;
    }

    try {
      return PhoneNumberFormatUtil.isValid(value);
    } catch {
      return false;
    }
  });
});

// 郵便番号
yup.addMethod(yup.string, "postalCode", function () {
  return this.test("postalCode", "形式が違います。", function (value) {
    if (!value || value === "") {
      return true;
    }
    return value.match(/^\d{3}-\d{4}$/) !== null;
  });
});

// 最大バイト数
yup.addMethod(yup.string, "maxByteLength", function (max) {
  return this.test("maxByteLength", "文字数が多すぎます。", function (value) {
    return StringByteLength(value) <= max;
  });
});

// 半角英数
yup.addMethod(yup.string, "halfWidthAlphanumeric", function () {
  return this.test(
    "halfWidthAlphanumeric",
    "半角英数を入力してください。",
    function (value) {
      return IsHalfWidthAlphanumeric(value);
    }
  );
});

yup.addMethod(yup.string, "enableJWNET", function () {
  return this.test(
    "enableJWNET",
    "使用できない文字が含まれています。",
    function (value) {
      return enableJWNET(value);
    }
  );
});

// 対象の日付よりも未来であるか
yup.addMethod(yup.date, "future", function (target, includeSameDate) {
  return this.test(
    "future",
    "未来の日付を入力してください。",
    function (value) {
      if (!value || value === "") {
        return true;
      }
      return ValidDate({
        dateA: value,
        dateB: target ?? CreateToday(),
        mode: "future",
        includeSame: includeSameDate ?? false,
      });
    }
  );
});

// 対象の日付よりも過去であるか
yup.addMethod(yup.date, "past", function (target, includeSameDate) {
  return this.test("past", "過去の日付を入力してください。", function (value) {
    if (!value || value === "") {
      return true;
    }
    return ValidDate({
      dateA: value,
      dateB: target ?? CreateToday(),
      mode: "past",
      includeSame: includeSameDate ?? false,
    });
  });
});

// 桁数
yup.addMethod(
  yup.number,
  "numberOfDigits",
  function (integerPart = 1, decimalPart = 0) {
    return this.test(
      "numberOfDigits",
      `整数部${integerPart}桁${
        decimalPart > 0 ? `、小数部${decimalPart}` : ""
      }までで入力してください。`,
      function (value) {
        if (!value || value === "") {
          return true;
        }

        let reg = null;

        if (decimalPart <= 0) {
          reg = new RegExp(`^[+,-]?(\\d{0,${integerPart}})?$`);
        } else if (decimalPart > 0) {
          reg = new RegExp(
            `^[+,-]?(\\d{0,${integerPart}})(\\.\\d{1,${decimalPart}})?$`,
            "g"
          );
        }

        return value.toString().match(reg) !== null;
      }
    );
  }
);

// 法人番号
yup.addMethod(yup.string, "corporateNumberCheckDigits", function () {
  return this.test(
    "corporateNumberCheckDigits",
    "チェックデジットに誤りがあります。",
    function (value) {
      return validCorporateNumberCheckDigits(value);
    }
  );
});

// yup は import する度に setLocale する必要がある
yup.setLocale(locale);

/*
-------------------- yupオブジェクト定義 --------------------
*/

export const textSchema = yup
  .string()
  .nullable()
  .max(100)
  .transform((value) => (value === "" ? null : value));

export const remarksSchema = yup
  .string()
  .nullable()
  .transform((value) => (value === "" ? null : value))
  .max(10000);

export const emailSchema = yup
  .string()
  .nullable()
  .max(256)
  .email()
  .transform((value) => (value === "" ? null : value));

export const phoneSchema = yup
  .string()
  .nullable()
  .max(20)
  .transform((value) => (value === "" ? null : value))
  .phone();

export const urlSchema = yup
  .string()
  .nullable()
  .max(2048)
  .url()
  .transform((value) => (value === "" ? null : value));

export const dateSchema = yup
  .date()
  .nullable()
  .transform((value) => (value instanceof Date && !isNaN(value) ? value : null))
  .min("1900/01/01");

export default yup;
