import { useEffect, useState, useCallback, useMemo } from "react";
import { format } from "date-fns";
import { API, graphqlOperation } from "utils/graphqlOperation";
import { getAggregationWasteCollectionRecord } from "api/graphql/queries";
import { useDispatch, useSelector } from "react-redux";
import { modeList } from "utils/Context";
import { debugLog } from "utils/log";
import { add as addAlert } from "ducks/Alert";
import {
  updateConfigurations,
  selector as dashboardWidgetsSelector,
} from "ducks/Widgets";
import { getSampleDate } from "./sample";

/**
 * 日付範囲内のすべての日付を生成する関数
 *
 * @param {Date|string} startDate - 開始日
 * @param {Date|string} endDate - 終了日
 * @returns {string[]} 日付の配列（フォーマット: "yyyy-MM-dd"）
 */
const generateDates = (startDate, endDate) => {
  let dates = [];
  let currentDate = new Date(startDate);
  endDate = new Date(endDate);

  while (currentDate <= endDate) {
    let formattedDate = currentDate.toISOString().split("T")[0];
    dates.push(formattedDate);
    currentDate.setDate(currentDate.getDate() + 1);
  }

  return dates;
};

/**
 * 指定された開始日と終了日の間の週を生成します。
 *
 * @param {string|Date} startDate - 週の生成を開始する日付。
 * @param {string|Date} endDate - 週の生成を終了する日付。
 * @returns {string[]} 開始日から終了日までの各週を表す文字列の配列。形式は "yyyy.WW" です。
 */
const generateWeeks = (startDate, endDate) => {
  let dates = [];
  let currentDate = new Date(startDate);
  endDate = new Date(endDate);

  while (currentDate <= endDate) {
    const startOfYear = new Date(currentDate.getFullYear(), 0, 1);
    const weekNumber = Math.ceil(
      ((currentDate - startOfYear) / (24 * 60 * 60 * 1000) + 1) / 7
    );
    dates.push(
      `${format(currentDate, "yyyy")}.${String(weekNumber).padStart(2, "0")}`
    );
    currentDate.setDate(currentDate.getDate() + 7);
  }

  return dates;
};

/**
 * 指定された開始日から終了日までの月を生成します。
 *
 * @param {string} startDate - 開始日（YYYY-MM-DD形式）。
 * @param {string} endDate - 終了日（YYYY-MM-DD形式）。
 * @returns {string[]} 開始日から終了日までの各月を表す文字列の配列（YYYY.MM形式）。
 */
const generateMonths = (startDate, endDate) => {
  let dates = [];
  let currentDate = new Date(startDate);
  endDate = new Date(endDate);
  while (currentDate <= endDate) {
    let month = currentDate.getMonth() + 1;
    let year = currentDate.getFullYear();
    let formattedDate = `${year}.${String(month).padStart(2, "0")}`;
    dates.push(formattedDate);
    currentDate.setMonth(currentDate.getMonth() + 1);
  }
  return dates;
};

/**
 * 指定された開始日から終了日までの年を生成します。
 *
 * @param {string|Date} startDate - 開始日を表す文字列またはDateオブジェクト。
 * @param {string|Date} endDate - 終了日を表す文字列またはDateオブジェクト。
 * @returns {string[]} 開始日から終了日までの年を表す文字列の配列。
 */
const generateYears = (startDate, endDate) => {
  let dates = [];
  let currentDate = new Date(startDate);
  endDate = new Date(endDate);
  while (currentDate <= endDate) {
    let year = currentDate.getFullYear();
    dates.push(year.toString());
    currentDate.setFullYear(currentDate.getFullYear() + 1);
  }
  return dates;
};

const startDate = new Date();
startDate.setDate(startDate.getDate() - 7);

const endDate = new Date();

const chartTypes = [
  { value: "bar", label: "バー" },
  { value: "line", label: "ライン" },
];

const initialPayload = {
  dataset: "wasteCollectionRecordActualValue",
  dateType: "wasteCollectionScheduleDate",
  method: "sum",
  groupColumns: {
    logisticsCourseName: false,
    wasteGeneratorCompanyName: false,
    wasteCollectionWorkplaceCode: false,
    wasteCollectionWorkplaceName: false,
    wasteCollectionWorkplacePrefecture: false,
    wasteCollectionWorkplaceCity: false,
    wasteLargeClassName: false,
    wasteMiddleClassName: false,
    wasteSmallClassName: false,
    wasteKindName: false,
    wasteName: false,
    wasteDisposalCompanyName: false,
    wasteDisposalWorkplaceName: false,
    wasteDisposalWorkplacePrefecture: false,
    wasteDisposalWorkplaceCity: false,
    wasteDisposalMethodLargeClassName: false,
    wasteDisposalMethodMiddleClassName: false,
    wasteDisposalMethodSmallClassName: false,
    wasteDisposalMethodDetails: false,
    wasteQuantityUnitName: true,
  },
};

const fetchItems = (response, dateRange, mode) => {
  let items = response.flatMap((item) =>
    item.aggregationColumns.map((aggregationColumn) => {
      return {
        date: aggregationColumn.key,
        [item.groupColumns[0].value]: aggregationColumn.value,
      };
    })
  );

  let result = [];
  let dateRanges = [];

  switch (mode) {
    case modeList[0].id:
      dateRanges = generateDates(dateRange.start, dateRange.end);
      break;
    case modeList[1].id:
      dateRanges = generateWeeks(dateRange.start, dateRange.end);
      break;
    case modeList[2].id:
      dateRanges = generateMonths(dateRange.start, dateRange.end);
      break;
    case modeList[3].id:
      dateRanges = generateYears(dateRange.start, dateRange.end);
      break;
    case modeList[4].id:
      dateRanges = ["total"];
      break;
    default:
      break;
  }

  for (const day of dateRanges) {
    let itemsOfDate = items.filter((item) => item.date === day);

    if (itemsOfDate.length > 0) {
      let kg = itemsOfDate.find((item) => item["kg"] !== undefined);
      let t = itemsOfDate.find((item) => item["t"] !== undefined);
      let m3 = itemsOfDate.find((item) => item["m3"] !== undefined);
      let liter = itemsOfDate.find((item) => item["リットル"] !== undefined);
      let quantity = itemsOfDate.find((item) => item["個・台"] !== undefined);

      if (kg && t) {
        t = {
          ...t,
          t: t.t + kg.kg / 1000,
        };

        // tにまとめたので未定義状態にする
        kg = undefined;
      }

      result.push({
        ...kg,
        ...t,
        ...m3,
        ...liter,
        ...quantity,
      });
    } else {
      result.push({
        date: day,
        t: 0,
      });
    }
  }

  debugLog("ダッシュボードのitems: ", result);

  return result;
};

const fetchHeader = (response) => {
  let items = response.map((item) => item.groupColumns[0].value);
  return items.reduce((target, key) => {
    target[key] = true;
    return target;
  }, {});
};

const jpUnits = {
  liter: "リットル",
  quantity: "個・台",
};

/**
 * コンポーネントContainer
 *
 * @param {Object} props - コンポーネントのプロパティ
 * @param {boolean} props.editMode - 編集モードかどうかを示すフラグ
 * @param {string} props.widgetId - ウィジェットID
 * @param {Function} props.render - レンダリング関数
 * @returns {JSX.Element} レンダリングされたコンポーネント
 *
 */
export const Container = ({ render, widgetId, ...props }) => {
  const [value, setValue] = useState();
  const {
    userPreferences: { items: userPreferences },
  } = useSelector(dashboardWidgetsSelector);

  const dispatch = useDispatch();

  const { configurations } = useMemo(() => {
    return userPreferences.find((widget) => widget.id === widgetId);
  }, [userPreferences, widgetId]);

  const [dateRange, setDateRange] = useState({
    start: startDate,
    end: endDate,
  });
  const [mode, setMode] = useState(modeList[0].id);
  const [chartType, setChartType] = useState(chartTypes.at(0).value);

  const [loading, setLoading] = useState(false);
  const [syncing, setSyncing] = useState(false);

  const start =
    typeof dateRange.start === "string"
      ? dateRange.start
      : format(dateRange.start, "yyyy-MM-dd");

  const end =
    typeof dateRange.end === "string"
      ? dateRange.end
      : format(dateRange.end, "yyyy-MM-dd");

  const load = useCallback((sync, aggregateOn, mode) => {
    !sync && setLoading(true);
    return API.graphql(
      graphqlOperation(getAggregationWasteCollectionRecord, {
        ...initialPayload,
        aggregateOn,
        mode,
      })
    )
      .then((res) => {
        const response = res.data.getAggregationWasteCollectionRecord.items;
        if (!Array.isArray(response)) {
          throw new Error();
        }
        const values = {
          header: fetchHeader(response),
          items: fetchItems(response, aggregateOn, mode)?.map((item) => ({
            m3: item.m3 || 0,
            t: item.t || 0,
            [jpUnits.liter]: item[jpUnits.liter] || 0,
            [jpUnits.quantity]: item[jpUnits.quantity] || 0,
            kg: item.kg || 0,
            date: item.date === "total" ? "合計" : item.date,
          })),
        };
        setValue(values);
      })
      .catch(() => {
        addAlert({
          value:
            "申し訳ございません。問題が発生しました。後ほどもう一度お試しください。",
          severity: "error",
        });
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (configurations) {
      const { dateRange, mode, chartType } = configurations;
      if (dateRange) {
        setDateRange({
          start: new Date(dateRange.start),
          end: new Date(dateRange.end),
        });
      }
      if (mode) {
        setMode(mode);
      }
      if (chartType) {
        setChartType(chartType);
      }
    }
  }, [configurations, load]);

  useEffect(() => {
    if (props.editMode) {
      setValue(getSampleDate(start, dateRange.end, mode));
    } else {
      load(false, { start, end }, mode);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [load, props.editMode]);

  const onChangeRange = (field) => {
    return (date) => {
      if (props.editMode) {
        dispatch(
          updateConfigurations({
            widgetId,
            configurations: {
              ...configurations,
              dateRange: {
                ...configurations.dateRange,
                [field]: format(date, "yyyy-MM-dd"),
              },
            },
          })
        );
        return;
      }
      setDateRange({
        ...dateRange,
        [field]: date,
      });
    };
  };

  const onChangeMode = (event) => {
    const { value } = event.target;
    if (props.editMode) {
      dispatch(
        updateConfigurations({
          widgetId,
          configurations: {
            ...configurations,
            mode: value,
          },
        })
      );
      return;
    }
    setMode(value);
  };

  const handleSync = () => {
    if (props.editMode) {
      setValue(getSampleDate(start, end, mode));
      return;
    }
    dispatch(
      updateConfigurations({
        widgetId,
        configurations: {
          dateRange: {
            start: format(dateRange.start, "yyyy-MM-dd"),
            end: format(dateRange.end, "yyyy-MM-dd"),
          },
          mode,
          chartType,
        },
      })
    );
    setSyncing(true);
    load(
      true,
      {
        start,
        end,
      },
      mode
    ).finally(() => {
      setSyncing(false);
    });
  };

  const onChangeChartType = (event) => {
    const { value } = event.target;
    if (props.editMode) {
      dispatch(
        updateConfigurations({
          widgetId,
          configurations: {
            ...configurations,
            chartType: value,
          },
        })
      );
      return;
    }
    setChartType(value);
  };

  return render({
    value: value,
    loading: loading,
    dateRange,
    onChangeRange,
    mode,
    onChangeMode,
    syncing,
    handleSync,
    onChangeChartType,
    chartType,
    chartTypes,
    ...props,
  });
};
