import { useMemo } from "react";
import { PictureAsPdfOutlined } from "@mui/icons-material";
import { Box, CircularProgress } from "@mui/material";
import { useWindowWidth } from "@react-hook/window-size";
import clsx from "clsx";
import { Document, Page, pdfjs } from "react-pdf";
import { ModalPopup } from "views/molecules";

import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";

import { ButtonPDF } from "./styles";

pdfjs.GlobalWorkerOptions.workerSrc = `${process.env.PUBLIC_URL}/pdf.worker.min.mjs`;
/**
 * PDFファイルのレンダーの表示を行うプレゼンテーションコンポーネントです。
 * @returns JSX Element
 */
export const Presententer = (props) => {
  const {
    classes,
    files = [],
    viewFile,
    onClickFile,
    open,
    onClose,
    numPages,
    onDocumentLoadSuccess,
  } = props;
  const maxWidth = useWindowWidth();
  const options = useMemo(() => {
    return {
      cMapUrl: `${process.env.PUBLIC_URL}/cmaps/`,
      cMapPacked: true,
      standardFontDataUrl: `${process.env.PUBLIC_URL}/standard_fonts/`,
    };
  }, []);

  return (
    <div>
      <div className={classes.pdf_view_mobile}>
        <Box className={classes.files}>
          {files?.map((file, index) => (
            <ButtonPDF
              startIcon={<PictureAsPdfOutlined />}
              variant="outlined"
              key={index}
              onClick={() => onClickFile(file)}
              color="inherit"
            >
              <span className={"label"}> {file.name}</span>
            </ButtonPDF>
          ))}
        </Box>
        <ModalPopup open={open} onClose={onClose}>
          <Box className={classes.mobile_view}>
            <Document
              file={viewFile?.file}
              options={options}
              loading={
                <div
                  className={clsx(classes.loading_progress, classes.onLoading)}
                >
                  <CircularProgress thickness={4} size={60} />
                </div>
              }
              noData={
                <div className={clsx(classes.loading_progress, classes.noData)}>
                  <CircularProgress thickness={4} size={60} />
                </div>
              }
              onLoadSuccess={onDocumentLoadSuccess}
              className={classes.view_file}
            >
              {Array.from(new Array(numPages), (el, index) => (
                <Page
                  key={`page_${index + 1}`}
                  pageNumber={index + 1}
                  width={maxWidth * 0.9}
                />
              ))}
            </Document>
          </Box>
        </ModalPopup>
      </div>
    </div>
  );
};
