import { Paper, Stack } from "@mui/material";
import { searchVehicleMaintenanceRecordsByOffset } from "api/graphql/queries";
import { companySelector } from "ducks/Company";
import { useImperativeHandle } from "react";
import { useSelector } from "react-redux";
import { formatDisplayAddress, formatDisplayDate } from "utils/format";
import { withForwardRef } from "utils/HoC";
import { useXGridComponents } from "utils/useXGridComponents";
import { InformationItem } from "views/atoms";
import { FileGallery, XGridComponent } from "views/molecules";
import { FILTER_OPERATORS_CONTAINS_EQUAL } from "utils/constant";

const columns = [
  {
    field: "number",
    headerName: "管理番号",
    flex: 1,
    filterOperators: FILTER_OPERATORS_CONTAINS_EQUAL,
  },
  {
    field: "category",
    headerName: "種別",
    flex: 1,
    filterOperators: FILTER_OPERATORS_CONTAINS_EQUAL,
  },
  {
    field: "maintenanceCompanyName",
    headerName: "整備会社",
    flex: 1,
    filterOperators: FILTER_OPERATORS_CONTAINS_EQUAL,
  },
  {
    field: "maintenanceWorkplaceName",
    headerName: "整備場所",
    flex: 1,
    filterOperators: FILTER_OPERATORS_CONTAINS_EQUAL,
  },
  {
    field: "maintenancedOn",
    headerName: "整備年月日",
    flex: 1,
    renderCell: (params) => {
      return formatDisplayDate({ source: params?.value });
    },
    filterOperators: FILTER_OPERATORS_CONTAINS_EQUAL,
  },
  {
    field: "price",
    headerName: "整備代金",
    flex: 1,
    renderCell: (params) => {
      if (!!params?.value) {
        return `${params.value?.toLocaleString()}円`;
      } else {
        return ``;
      }
    },
    filterable: false,
  },
];

/**
 * 整備の詳細を表示するコンポーネントです。
 * @param {object} value 値
 * @param {string} relatedSafetyInspectionId
 * @param {object} _ref
 * @fires SafetiesDetails#onSelected 選択時
 * @returns {JSX.Element}
 */
export const SafetiesDetails = withForwardRef(
  ({ value, relatedSafetyInspectionId, onSelected, _ref }) => {
    const company = useSelector(companySelector);
    const obj = useXGridComponents(
      columns,
      searchVehicleMaintenanceRecordsByOffset,
      {
        other: {
          ownerCompanyId: company?.id,
        },
        filter: relatedSafetyInspectionId
          ? {
              and: [
                {
                  relatedSafetyInspectionId: {
                    eq: relatedSafetyInspectionId,
                  },
                },
              ],
            }
          : undefined,
      },
      {
        sort: {
          direction: "desc",
          field: "maintenancedOn",
        },
      }
    );

    useImperativeHandle(_ref, () => ({
      refresh: () => {
        obj?.functions?.refetch();
      },
    }));

    return (
      <Stack spacing={2}>
        <Paper
          sx={{
            p: 2,
          }}
        >
          <InformationItem label="受託会社">
            {value ? `${value.inspectionWorkplace?.belongInCompanyName}` : ""}
          </InformationItem>
          <InformationItem label="実施場所">
            {value ? `${formatDisplayAddress(value.inspectionWorkplace)}` : ""}
          </InformationItem>
          <InformationItem label="車輛引渡し日">
            {formatDisplayDate({ source: value?.receivedOn })}
          </InformationItem>
          <InformationItem label="車検完了日">
            {formatDisplayDate({ source: value?.completedOn })}
          </InformationItem>
          <InformationItem label="車検結果">
            {value
              ? value.result === "Pass"
                ? "合格"
                : value.result === "Fail"
                ? "不合格"
                : ""
              : ""}
          </InformationItem>
          <InformationItem label="車検満了日">
            {formatDisplayDate({ source: value?.expiredOn })}
          </InformationItem>
          <InformationItem label="車検代金">
            {value?.price ? `${value.price.toLocaleString()}円` : ""}
          </InformationItem>
          <InformationItem label="車種">
            {value?.vehicleModelName ?? ""}
          </InformationItem>
          <InformationItem label="車輛ナンバー">
            {value?.vehicleNumber ?? ""}
          </InformationItem>
          <InformationItem label="備考">{value?.remarks ?? ""}</InformationItem>
          <InformationItem label="添付ファイル">
            <FileGallery value={value?.files} disabled={true} />
          </InformationItem>
        </Paper>
        <Paper
          sx={{
            p: 2,
          }}
        >
          <XGridComponent
            onChangeSelection={onSelected}
            checkboxSelection={false}
            multipleSelection={false}
            onDownload={false}
            onFilter={false}
            onColumns={false}
            {...obj?.params}
          />
        </Paper>
      </Stack>
    );
  }
);
