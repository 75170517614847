import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { API, graphqlOperation } from "utils/graphqlOperation";
import { useNavigate } from "react-router-dom";
import { Box, Button } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";

import { companySelector } from "ducks/Company";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { DateTime } from "luxon";

import { debugLog } from "utils/log";
import { If } from "views/atoms";
import { ConfirmDialog } from "views/molecules";
import { listWasteCollectionSchedulesCount } from "api/graphql/queries";
import {
  deleteLogisticsWorkAllocation,
  createLogisticsWorkAllocationWithSettings,
} from "api/graphql/mutations";
import { DateStepper } from "views/molecules";
import { formatDisplayDate } from "utils/format";
import { useOpener } from "utils/useOpener";
import { add as addAlert } from "ducks/Alert";
import { formatTime, generateDataWithHyphens } from "./utils";

const columns = [
  {
    field: "scheduleDate",
    headerName: "回収日",
    width: 170,
    disableColumnMenu: true,
    renderCell: (params) =>
      formatDisplayDate({
        source: params.value,
        destFormat: "yyyy/MM/dd (EEE)",
      }),
  },
  {
    field: "unassignedCount",
    headerName: "未配車数",
    flex: 1,
    disableColumnMenu: true,
    renderCell: (params) => {
      if (params.value === null) {
        return "-";
      }
      return params.value;
    },
  },
  {
    field: "assignedCount",
    headerName: "配車数合計",
    flex: 1,
    disableColumnMenu: true,
    renderCell: (params) => {
      if (params.value === null) {
        return "-";
      }
      return params.value;
    },
  },
  {
    field: "lastUpdatedOn",
    headerName: "最終更新日時",
    flex: 1,
    disableColumnMenu: true,
    renderCell: (params) => {
      if (params.value === null) {
        return "-";
      }
      return DateTime.fromISO(params.value, { zone: "utc" }).toFormat(
        "yyyy/MM/dd HH:mm"
      );
    },
  },
];

/**
 * A Component to list the monthly allocation table
 * @param {string} date: The date
 * @param {func} onClose: This close the window
 * @param {func} onCloseModel: Function to close the model if it was called from the model
 * */
const MonthlyAllocationView = ({ date, onClose, onChangeDate }) => {
  const [loading, setLoading] = useState(false);
  const [allocations, setAllocations] = useState([]);
  const [monthDate, setMonthDate] = useState(date);
  const { open, toggle } = useOpener();
  const [refresh, setRefresh] = useState(false);
  const [openBackDrop, setOpenBackDrop] = useState(false);
  const [remainingTime, setRemainingTime] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const [selectionModel, setSelectionModel] = useState([]);

  const navigate = useNavigate();
  const { id: ownerCompanyId } = useSelector(companySelector);
  const dispatch = useDispatch();

  useEffect(() => {
    setSelectionModel([]);
    const controller = new AbortController();
    const { signal } = controller;
    setLoading(true);
    const listWasteCollectionPromise = API.graphql(
      graphqlOperation(listWasteCollectionSchedulesCount, {
        year: monthDate.year,
        month: monthDate.month,
      }),
      {
        signal,
      }
    );

    listWasteCollectionPromise
      .then((response) => {
        const result = response.data.listWasteCollectionSchedulesCount.items;
        const data = generateDataWithHyphens(
          result,
          monthDate.year,
          monthDate.month
        );
        setAllocations(data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        debugLog("listWasteCollectionSchedulesCount: ", err);
      });

    return () => {
      controller.abort();
      API.cancel(listWasteCollectionPromise);
    };
  }, [monthDate, refresh]);

  useEffect(() => {
    if (openBackDrop) {
      const timerInterval = 1000; //1 second
      const totalTime = selectionModel.length * 28;

      let currentIndex = 0;
      const intervalId = setInterval(() => {
        if (currentIndex === totalTime) {
          setRefresh((prev) => !prev);
          setOpenBackDrop(false);
          setShowAlert(false);
          setRemainingTime(null);
          clearInterval(intervalId);
          return;
        }
        setRemainingTime(totalTime - currentIndex);
        currentIndex += 1;
      }, timerInterval);
      return () => clearInterval(intervalId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openBackDrop]);

  const onDateChange = (date) => {
    setMonthDate(date);
  };

  const getUniquelySelectedScheduleDates = () => {
    const indexSet = new Set(selectionModel);
    const indexArray = Array.from(indexSet);
    return indexArray.map((i) => allocations[i - 1].scheduleDate);
  };

  const handleDeleteSelectedAllocation = async () => {
    return await API.graphql(
      graphqlOperation(deleteLogisticsWorkAllocation, {
        input: {
          scheduleDates: getUniquelySelectedScheduleDates(),
        },
      })
    );
  };

  const handleCreateBulk = async () => {
    try {
      setOpenBackDrop(true);

      await handleDeleteSelectedAllocation();

      await API.graphql(
        graphqlOperation(createLogisticsWorkAllocationWithSettings, {
          input: {
            scheduleDates: getUniquelySelectedScheduleDates(),
            ownerCompanyId,
          },
        })
      );
      setShowAlert(true);
    } catch (err) {
      debugLog("Error: ", err);
      setOpenBackDrop(false);
      setShowAlert(false);
    }
  };

  const handleRowSelect = (param, event) => {
    if (event.target.closest(".MuiDataGrid-checkboxInput")) return;
    if (param.field === "id") {
      event.defaultMuiPrevented = true;
      return;
    }
    const row = allocations[param.id - 1];
    const selectedDate = DateTime.fromISO(row.scheduleDate);
    onChangeDate(selectedDate);
    onClose();
  };

  const action = (
    <Stack direction="row" spacing={2}>
      <Button
        variant="outlined"
        color="primary"
        size="small"
        onClick={handleCreateBulk}
      >
        {"配車計画を反映"}
      </Button>
      <Button
        variant="outlined"
        color="error"
        size="small"
        onClick={() => {
          toggle(true);
        }}
      >
        {"一括削除"}
      </Button>
    </Stack>
  );

  return (
    <Box
      sx={{
        marginTop: "-30px",
        marginBottom: "-40px",
      }}
    >
      <DateStepper value={date} mode="month" onChange={onDateChange}>
        {() => (
          <Box
            sx={{
              height: "calc(100vh - 200px)",
            }}
          >
            <>
              <Box
                sx={{
                  height: 40,
                }}
              >
                {selectionModel.length ? (
                  <Box
                    sx={{
                      backgroundColor: "rgba(241, 243, 244, 0.87)",
                      borderRadius: 10,
                      px: 5,
                      mb: 1,
                      py: "2px",
                      color: "#6200EE",
                      fontSize: 15,
                      fontWeight: 500,
                      boxShadow: "none",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {action}
                    <Typography ml={2}>
                      <b>({selectionModel.length}件)</b>{" "}
                    </Typography>
                  </Box>
                ) : null}
              </Box>
              <DataGridPro
                columns={columns}
                rows={allocations.map((r, i) => ({
                  id: i + 1,
                  ...r,
                }))}
                checkboxSelection
                selectionModel={selectionModel}
                onSelectionModelChange={(newSelection) =>
                  setSelectionModel(newSelection)
                }
                onCellClick={handleRowSelect}
                isRowSelectable={(params) => params.row.id}
                loading={loading}
                rowHeight={38}
              />
            </>
          </Box>
        )}
      </DateStepper>

      <If condition={open}>
        <ConfirmDialog
          disabled={loading}
          open={open}
          title="削除確認"
          message={`選択された ${selectionModel.length} 個のアイテム 削除します。よろしいですか？`}
          positiveText="はい"
          negativeText="いいえ"
          onPositive={() => {
            setLoading(true);
            handleDeleteSelectedAllocation()
              .then(() => {
                setLoading(false);
                toggle(false);
                setRefresh((prev) => !prev);
              })
              .catch((err) => {
                debugLog("Error: ", err);
                dispatch(
                  addAlert({ message: "削除に失敗しました。", type: "error" })
                );
              })
              .finally(() => {
                setLoading(false);
              });
          }}
          onNegative={() => toggle(false)}
        />
      </If>

      {openBackDrop && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={openBackDrop}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              maxWidth: 600,
              borderRadius: 2,
              bgcolor: "#fff",
              padding: 5,
              textAlign: "center",
            }}
          >
            <CircularProgress color="info" />
            {showAlert && (
              <>
                <Typography
                  sx={{
                    padding: 3,
                    borderRadius: 2,
                    color: "info.main",
                  }}
                >
                  リクエストを受け付けました。 <br></br>最大で{" "}
                  {formatTime(selectionModel.length * 28)}
                  　かかる場合があります。
                  <br></br>
                  {remainingTime && formatTime(remainingTime)}
                  後にご確認ください。
                </Typography>
                <br />
                <Button
                  sx={{
                    marginTop: 5,
                  }}
                  variant="contained"
                  size="large"
                  color="primary"
                  onClick={() => navigate("/allocation")}
                >
                  はい
                </Button>
              </>
            )}
          </Box>
        </Backdrop>
      )}
    </Box>
  );
};

MonthlyAllocationView.propTypes = {
  date: PropTypes.object.isRequired,
  onClose: PropTypes.func,
};

export default MonthlyAllocationView;
