import { useMemo } from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import DescriptionIcon from "@mui/icons-material/Description";
import { IconButton } from "@mui/material";
import { Document, Page, pdfjs } from "react-pdf";

import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";

pdfjs.GlobalWorkerOptions.workerSrc = `${process.env.PUBLIC_URL}/pdf.worker.min.mjs`;

/**
 * サムネイルを表示するプレゼンテーションコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = (props) => {
  const options = useMemo(() => {
    return {
      cMapUrl: `${process.env.PUBLIC_URL}/cmaps/`,
      cMapPacked: true,
      standardFontDataUrl: `${process.env.PUBLIC_URL}/standard_fonts/`,
    };
  }, []);
  return (
    <div
      style={{
        position: "relative",
      }}
    >
      <div
        style={{
          width: props.width,
          height: props.height,
        }}
        className={props.classes.container}
        onClick={props.onClick}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            backgroundColor: "lightgrey",
          }}
        >
          {props.isPDF && (
            <Document key={1} file={props.base64} options={options}>
              <Page height={props.height} pageNumber={1} />
            </Document>
          )}
          {props.isImage && (
            <img
              src={props.base64}
              alt="画像"
              width={props.width}
              height={props.height}
            />
          )}
          {props.isOther && (
            <IconButton>
              <DescriptionIcon />
            </IconButton>
          )}
        </div>
        <span className={props.classes.title}>{props.title}</span>
      </div>
      {props.deletable === true && (
        <IconButton
          size="medium"
          onClick={props.onDelete}
          style={{
            position: "absolute",
            top: 0,
            right: 0,
            marginTop: -10,
            marginRight: -10,
          }}
        >
          <CancelIcon />
        </IconButton>
      )}
    </div>
  );
};
