import { useMemo, useEffect } from "react";
import { TabsDetails } from "views/molecules";
import { WorkplaceTabCollectionPrecaution } from "../WorkplaceTabCollectionPrecaution";
import { WorkplaceTabSimpleInformation } from "../WorkplaceTabSimpleInformation";
import { WorkplaceTabWastes } from "../WorkplaceTabWastes";

/**
 * 車輛情報タブの表示を行うプレゼンテーションコンポーネントです
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = ({ value, tab, getTab }) => {
  const tabs = useMemo(() => {
    const defaultTabs = [
      {
        name: "基本情報",
        tabContent: <WorkplaceTabSimpleInformation value={value} />,
      },
    ];
    const hasDischargeSite = value?.categories?.some(
      (category) => category.id === "dischargeSite"
    );

    if (hasDischargeSite) {
      return [
        {
          name: "廃棄物",
          tabContent: <WorkplaceTabWastes value={value} />,
        },
        {
          name: "回収注意点",
          tabContent: <WorkplaceTabCollectionPrecaution value={value} />,
        },
        ...defaultTabs,
      ];
    }

    return defaultTabs;
  }, [value]);

  useEffect(() => {
    if (tab > tabs.length) {
      getTab(0);
    }
  }, [tab, tabs.length, getTab]);

  if (tab > tabs.length) return null;

  return <TabsDetails value={tab} onChangeTab={getTab} tabs={tabs} />;
};

/* 
todo: 便利機能として設定する際に復元させる
import { WorkplaceTabRegularCollection } from "../WorkplaceTabRegularCollection";
{ name: "定期回収", tabContent: <WorkplaceTabRegularCollection /> }, 
*/
