import yup, { dateSchema, textSchema, remarksSchema } from "utils/yup";

export const basicSchema = yup.object().shape({
  container: yup.object().required(),
  installationWorkplace: yup.object().required(),
  shippedOn: dateSchema
    .required()
    .test(
      "shippedOn",
      "移動開始日は設置日より後にできません。",
      function (value) {
        const { installedOn } = this.parent;
        return !installedOn || !value || value <= installedOn;
      }
    ),
  installedOn: dateSchema
    .required()
    .test(
      "installedOn",
      "設置日は移動開始日より前にできません。",
      function (value) {
        const shippedOn = this.parent.shippedOn;
        return !shippedOn || !value || value >= shippedOn;
      }
    ),
  vehicle: yup.object().nullable(),
  personInChargeName: textSchema,
  remarks: remarksSchema.nullable(),
});
