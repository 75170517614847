import { searchWasteManagementMethodsByOffset } from "api/graphql/queries";
import { useXGridComponents } from "utils/useXGridComponents";
import { useImperativeHandle } from "react";
import { FILTER_OPERATORS_CONTAINS_EQUAL } from "utils/constant";

const columns = [
  {
    field: "wasteTypeLargeClassName",
    headerName: "廃棄物種類大分類",
    minWidth: 200,
    flex: 1,
    valueGetter: (params) => params.row.targetWasteType?.largeClass?.name,
    sortable: false,
    filterOperators: FILTER_OPERATORS_CONTAINS_EQUAL,
  },
  {
    field: "wasteTypeMiddleClassName",
    headerName: "廃棄物種類中分類",
    minWidth: 200,
    flex: 1,
    valueGetter: (params) => params.row.targetWasteType?.middleClass?.name,
    hide: true,
    sortable: false,
    filterOperators: FILTER_OPERATORS_CONTAINS_EQUAL,
  },
  {
    field: "wasteTypeSmallClassName",
    headerName: "廃棄物種類小分類",
    minWidth: 200,
    flex: 1,
    valueGetter: (params) => params.row.targetWasteType?.smallClass?.name,
    hide: true,
    sortable: false,
    filterOperators: FILTER_OPERATORS_CONTAINS_EQUAL,
  },
  {
    field: "transportSectionCount",
    headerName: "運搬区間数",
    type: "number",
    valueGetter: (params) => params.row.transportSectionCount ?? 0,
    minWidth: 160,
    flex: 1,
    sortable: false,
  },
  {
    field: "disposalCompanyName",
    headerName: "処分場",
    valueGetter: (params) => params.row.disposalProcess?.disposalCompany?.name,
    minWidth: 160,
    flex: 1,
    sortable: false,
    filterOperators: FILTER_OPERATORS_CONTAINS_EQUAL,
  },
  {
    field: "disposalMethodLargeClassName",
    headerName: "処分方法大分類",
    valueGetter: (params) =>
      params.row.disposalProcess?.disposalMethod?.largeClassName,
    minWidth: 160,
    flex: 1,
    sortable: false,
    filterOperators: FILTER_OPERATORS_CONTAINS_EQUAL,
  },
  {
    field: "disposalMethodMiddleClassName",
    headerName: "処分方法中分類",
    valueGetter: (params) =>
      params.row.disposalProcess?.disposalMethod?.middleClassName,
    minWidth: 160,
    flex: 1,
    hide: true,
    sortable: false,
    filterOperators: FILTER_OPERATORS_CONTAINS_EQUAL,
  },
  {
    field: "disposalMethodSmallClassName",
    headerName: "処分方法小分類",
    valueGetter: (params) =>
      params.row.disposalProcess?.disposalMethod?.smallClassName,
    minWidth: 160,
    flex: 1,
    hide: true,
    sortable: false,
    filterOperators: FILTER_OPERATORS_CONTAINS_EQUAL,
  },
  {
    field: "reportingMedia",
    headerName: "報告方法",
    minWidth: 160,
    flex: 1,
    valueGetter: (params) => `${params.row.reportingMedia.join()}`,
    sortable: false,
    filterOperators: FILTER_OPERATORS_CONTAINS_EQUAL,
  },
  {
    field: "state",
    headerName: "ステータス",
    type: "singleSelect",
    minWidth: 160,
    flex: 1,
    renderCell: (params) => {
      return params.value === "active" ? "有効" : "利用停止中";
    },
    valueOptions: [
      {
        label: "有効",
        value: "active",
      },
      {
        label: "利用停止中",
        value: "inactive",
      },
    ],
    sortable: false,
  },
];

/**
 * 処理フローを表示するコンテナコンポーネントです
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {object} value 値
 * @param {object} props その他プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({
  render,
  value,
  managedCompanyId,
  managedWasteId,
  onSelected,
  initialState,
  ...props
}) => {
  const xGridObject = useXGridComponents(
    columns,
    searchWasteManagementMethodsByOffset,
    {
      other: {
        managedWasteId: managedWasteId,
      },
    },
    initialState
  );

  const handleSelected = (value) => {
    onSelected({
      value: xGridObject.params.rows.find((row) => row.id === value[0]),
      info: {
        search: xGridObject.search,
      },
    });
  };

  useImperativeHandle(props._ref, () => ({
    refetch: () => {
      xGridObject.functions.refetch();
    },
  }));

  return render({
    ...props,
    columns: columns,
    value: value,
    onSelected: handleSelected,
    xGridParams: xGridObject.params,
  });
};
