import {
  createLogisticsWorkAllocation,
  updateLogisticsWorkAllocation,
  deleteLogisticsWorkAllocation,
} from "api/graphql/mutations";
import {
  searchWasteCollectionSchedulesByOffset,
  getLogisticsWorkAllocationCourses,
} from "api/graphql/queries";
import { API, graphqlOperation } from "utils/graphqlOperation";
import { companySelector } from "ducks/Company";
import { toggle } from "ducks/Loading";
import { DateTime } from "luxon";
import queryString from "query-string";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useMutation } from "utils/useMutation";
import { useOpener } from "utils/useOpener";
import { debugLog } from "utils/log";
import { add as addAlert } from "ducks/Alert";

const useCancellableQuery = ({
  query,
  variables = null,
  onPreLoad = () => {},
  onCompleted = (response) => {},
  onError = (error) => {},
}) => {
  const [loading, setLoading] = useState(false);
  const { load, cancel } = useMemo(() => {
    const promise = API.graphql(graphqlOperation(query, variables));
    return {
      load: () => {
        onPreLoad();
        setLoading(true);
        promise
          ?.then((response) => {
            onCompleted(response);
          })
          ?.catch((error) => {
            onError(error);
          })
          ?.finally(() => {
            setLoading(false);
          });
      },
      cancel: () => {
        API.cancel(promise, "API呼び出しはキャンセルされました。");
      },
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(query), JSON.stringify(variables)]);

  useEffect(() => {
    load?.();
  }, [load]);

  const refetch = () => {
    onPreLoad();
    setLoading(true);
    API.graphql(graphqlOperation(query, variables))
      .then((response) => onCompleted(response))
      .catch((error) => onError(error))
      .finally(() => {
        setLoading(false);
      });
  };

  return {
    load: load,
    refetch: refetch,
    cancel: cancel,
    loading: loading,
  };
};

/**
 * 配車表を表示するコンテナコンポーネントです。
 * @param {object} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {object} props その他プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({ render, ...props }) => {
  const location = useLocation();
  const qs = queryString.parse(location.search);
  const [value, setValue] = useState(null);
  const [total, setTotal] = useState(0);
  const [offset, setOffset] = useState(0);
  const [date, setDate] = useState(
    qs.date ? DateTime.fromSQL(qs.date) : DateTime.now()
  );
  const [isRefresh, setIsRefresh] = useState(false);
  const dispatch = useDispatch();
  const company = useSelector(companySelector);
  const removeDialog = useOpener();
  const [removeMutation, removeMutationStatus] = useMutation(
    deleteLogisticsWorkAllocation,
    {
      onCompleted: () => {
        setValue(null);
        removeDialog.toggle(false);
        searchWasteCollectionSchedules.refetch();
      },
      onError: () => {
        removeDialog.toggle(false);
        dispatch(
          addAlert({
            value: "エラーが発生したため、削除できませんでした。",
            severity: "error",
          })
        );
      },
      succeedMessage: "削除しました。",
      errorMessage: "エラーが発生したため、削除できませんでした。",
    }
  );
  const [isCreateNew, setIsCreateNew] = useState(false);

  const logisticsWorkAllocation = useCancellableQuery({
    query: getLogisticsWorkAllocationCourses,
    variables: {
      date: date.toISODate(),
    },
    onCompleted: (params) => {
      const logisticCourse = params.data.getLogisticsWorkAllocationCourses;
      setValue((prevState) => ({
        ...prevState,
        done: {
          ...logisticCourse,
          courses:
            logisticCourse?.courses.map((course) => {
              return {
                ...course,
                points: [],
              };
            }) || [],
        },
      }));
    },
  });

  const searchWasteCollectionSchedules = useCancellableQuery({
    query: searchWasteCollectionSchedulesByOffset,
    variables: {
      filter: {
        and: [
          {
            assigned: { eq: false },
            or: [
              { date: { eq: date.toISODate() } },
              { date: { eq: date.plus({ day: 1 }).toISODate() } },
            ],
          },
        ],
      },
      sort: {
        field: "date",
      },
      offset: 0,
    },
    onCompleted: (params) => {
      const fetchedUnassignedItems =
        params.data.searchWasteCollectionSchedulesByOffset.items;
      setValue((prevState) => ({
        ...prevState,
        still: fetchedUnassignedItems || [],
      }));
      setTotal(params.data.searchWasteCollectionSchedulesByOffset.total);
    },
  });

  useEffect(() => {
    if (!!qs.date) {
      logisticsWorkAllocation.refetch();
      searchWasteCollectionSchedules.refetch();
    }
    return () => {
      dispatch(toggle(false));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(qs.date)]);

  useEffect(() => {
    if (isRefresh) {
      logisticsWorkAllocation.refetch();
      searchWasteCollectionSchedules.refetch();
      setIsRefresh(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRefresh]);

  const onChangeDate = (date) => {
    logisticsWorkAllocation.cancel();
    searchWasteCollectionSchedules.cancel();
    setValue(null);
    setDate(date);
  };

  const create = (date) => {
    return API.graphql(
      graphqlOperation(createLogisticsWorkAllocation, {
        input: {
          ownerCompanyId: company.id,
          date: date.toISODate(),
        },
      })
    );
  };

  const remove = (id) => {
    removeMutation({
      input: {
        id: id,
      },
    });
  };

  dispatch(
    toggle(
      logisticsWorkAllocation.loading ||
        searchWasteCollectionSchedules.loading ||
        removeMutationStatus.loading
    )
  );

  const update = (data) => {
    return API.graphql(
      graphqlOperation(updateLogisticsWorkAllocation, {
        input: {
          id: data.done.id,
          courses: data.done.courses.map((course) => ({
            id: course.version !== undefined ? course.id : undefined,
            name: course.name,
            departureTime: course.departureTime,
            assignedVehicleId: course.assignedVehicle?.id,
            assignedUserIds: course.assignedUsers.map((item) => item.id),
            points: course.points.map((point) => ({
              id: point.version !== undefined ? point.id : undefined,
              workplaceId: point.workplace.id,
              assignedWasteCollectionScheduleIds:
                point.assignedWasteCollectionSchedules.map(
                  (schedule) => schedule.id
                ),
              version: point.version,
            })),
            version: course.version,
          })),
          version: data.done.version,
        },
      })
    );
  };

  const handleUpdated = (res) => {
    debugLog("スポット回収.更新後", res);
    setValue(null);
    logisticsWorkAllocation.refetch();
    searchWasteCollectionSchedules.refetch();
  };

  const handleCreated = (res) => {
    debugLog("スポット回収.表作成", res);
    setValue((prevState) => {
      return {
        ...prevState,
        done: res.data.createLogisticsWorkAllocation,
      };
    });
    setIsCreateNew(true);
  };

  const refresh = () => {
    setIsRefresh((prevState) => !prevState);
  };

  return render({
    value: value,
    date: date,
    setValue: setValue,
    onChangeDate: onChangeDate,
    update: update,
    create: create,
    remove: remove,
    onCreated: handleCreated,
    onUpdated: handleUpdated,
    total,
    setTotal,
    offset,
    setOffset,
    searchWasteCollectionSchedules,
    logisticsWorkAllocation,
    isLoading:
      logisticsWorkAllocation.loading ||
      searchWasteCollectionSchedules.loading ||
      removeMutationStatus.loading,
    isCreateNew,
    setIsCreateNew,
    refresh,
    removeDialog,
    ...props,
  });
};
