import { DateTime } from "luxon";
import { useSelector } from "react-redux";
import { get } from "lodash";
import { createChecksheetTemplate } from "api/graphql/mutations";
import { searchChecksheetTemplatesByOffset } from "api/graphql/queries";
import { companySelector } from "ducks/Company";
import { useNavigate, useLocation } from "react-router-dom";
import { useMutation } from "utils/useMutation";
import { useOpener } from "utils/useOpener";
import { useXGridComponents } from "utils/useXGridComponents";
import { toQueryString, fromQueryString } from "utils/useXGridComponents";
import { FILTER_OPERATORS_CONTAINS_EQUAL } from "utils/constant";

const columns = [
  {
    field: "categoryName",
    headerName: "分類",
    flex: 1,
    filterOperators: FILTER_OPERATORS_CONTAINS_EQUAL,
  },
  {
    field: "name",
    headerName: "名称",
    flex: 1,
    filterOperators: FILTER_OPERATORS_CONTAINS_EQUAL,
  },
  {
    field: "updatedAt",
    headerName: "最終更新日",
    minWidth: 160,
    flex: 1,
    valueFormatter: (params) => {
      return params?.value
        ? DateTime.fromISO(params.value, { setZone: "Asia/Tokyo" }).toFormat(
            "yyyy/MM/dd"
          )
        : "";
    },
    filterable: false,
  },
  {
    field: "totalOfItems",
    headerName: "項目数",
    flex: 1,
    filterable: false,
  },
];

/**
 * チェックシート一覧を表示するコンテナコンポーネントです。
 */
export const Container = ({ render, ...props }) => {
  const formOpener = useOpener();
  const company = useSelector(companySelector);
  const navigate = useNavigate();
  const location = useLocation();
  const xGridObject = useXGridComponents(
    columns,
    searchChecksheetTemplatesByOffset,
    {
      other: {
        ownerCompanyId: company.id,
      },
    },
    {
      sort: {
        direction: "desc",
        field: "updatedAt",
      },
      ...fromQueryString(location.search),
      filter: {
        defaultColumnField: get(columns, "[1].field"),
      },
    }
  );

  const onSelected = (params) => {
    navigate(
      {
        pathname: location.pathname,
        search: toQueryString(xGridObject.search),
      },
      {
        replace: true,
      }
    );

    navigate(`${location.pathname}/${params[0].id}`);
  };

  const [createMutation, createMutationStatus] = useMutation(
    createChecksheetTemplate,
    {
      onCompleted: (data) => {
        formOpener.toggle(false);
        xGridObject.functions.refetch();
      },
      succeedMessage: "登録しました。",
      errorMessage: "エラーが発生したため、登録できませんでした。",
    }
  );

  const handleRegister = async (data) => {
    const { category, name, description, remarks, sections } = data;

    createMutation({
      input: {
        ownerCompanyId: company.id,
        categoryId: category?.id,
        name: name,
        description: description,
        remarks: remarks,
        sections: sections.map((section) => ({
          ...section,
          items:
            section.items?.map((item) => ({
              id: item?.id,
              name: item?.name,
            })) ?? [],
        })),
      },
    });
  };

  return render({
    ...props,
    formOpener: formOpener,
    onSubmit: handleRegister,
    xGridParams: xGridObject?.params,
    isSubmit: createMutationStatus.loading,
    onSelected: onSelected,
  });
};
