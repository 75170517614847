import { useMemo } from "react";
import { CircularProgress } from "@mui/material";
import clsx from "clsx";
import { Document, Page, pdfjs } from "react-pdf";

import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";

pdfjs.GlobalWorkerOptions.workerSrc = `${process.env.PUBLIC_URL}/pdf.worker.min.mjs`;
/**
 * PDFビューワー表示するプレゼンテーションコンポーネントです
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = (props) => {
  const options = useMemo(() => {
    return {
      cMapUrl: `${process.env.PUBLIC_URL}/cmaps/`,
      cMapPacked: true,
      standardFontDataUrl: `${process.env.PUBLIC_URL}/standard_fonts/`,
    };
  }, []);

  return (
    <div
      className={props.classes.container}
      onScroll={props.onScroll}
      style={{
        height: props.height,
      }}
    >
      <Document
        file={props.files}
        loading={
          <div className={clsx(props.classes.progress)}>
            <CircularProgress thickness={4} size={60} />
          </div>
        }
        onLoadSuccess={props.onLoadSuccess}
        options={options}
      >
        {Array.from(new Array(props.numPages), (el, index) => (
          <Page
            key={`page_${index + 1}`}
            pageNumber={index + 1}
            width={props?.width ?? 900}
          />
        ))}
      </Document>
    </div>
  );
};
