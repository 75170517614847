import { formatDisplayDate } from "utils/format";
import { useOpener } from "utils/useOpener";
import { formatJSDateToISODate } from "utils/format";
import { toApiValue } from "utils/ImageFunctions";
import { useNavigate, useParams } from "react-router-dom";
import { createVehiclePeriodicInspection } from "api/graphql/mutations";
import { searchVehiclePeriodicInspectionsByOffset } from "api/graphql/queries";
import { companySelector } from "ducks/Company";
import { useSelector } from "react-redux";
import { useXGridComponents } from "utils/useXGridComponents";
import {
  updateVehicleInstrument,
  updateVehicleCertificate,
} from "api/graphql/mutations";
import { getManagementVehicle } from "api/graphql/queries";
import { useQuery } from "utils/useQuery";
import { useMutation } from "utils/useMutation";
import { FILTER_OPERATORS_CONTAINS_EQUAL } from "utils/constant";

/**
 * モバイルの車輛点検を表示するコンテナコンポーネントです。
 */
export const Container = ({ render, ...props }) => {
  const company = useSelector(companySelector);
  const { id } = useParams();
  const inspectionExpirationDateForm = useOpener();
  const odoMeterForm = useOpener();
  const checkSheetSelector = useOpener();
  const vehicleInspectionForm = useOpener();
  const navigate = useNavigate();

  const handleSelected = (params) => {
    navigate(`/vehicle-inspection-list/${id}/inspections/${params?.[0]}`);
  };

  const columns = [
    {
      field: "checkedOn",
      headerName: "点検日",
      flex: 1,
      renderCell: (params) => formatDisplayDate({ source: params.value }),
      filterOperators: FILTER_OPERATORS_CONTAINS_EQUAL,
    },
    {
      field: "personInChargeName",
      headerName: "点検者",
      flex: 1,
      filterOperators: FILTER_OPERATORS_CONTAINS_EQUAL,
    },
    {
      field: "checksheetName",
      headerName: "点検の名称",
      flex: 1,
      filterOperators: FILTER_OPERATORS_CONTAINS_EQUAL,
    },
    {
      field: "numberOfChecks",
      headerName: "チェック数",
      flex: 1,
      renderCell: (params) => {
        const { numberOfChecks, totalOfItems } = params.row;
        return `${numberOfChecks} / ${totalOfItems}`;
      },
      filterable: false,
    },
  ];

  const xGridObject = useXGridComponents(
    columns,
    searchVehiclePeriodicInspectionsByOffset,
    {
      filter: {
        and: [
          {
            vehicleId: {
              eq: id,
            },
          },
        ],
      },
      other: {
        ownerCompanyId: company?.id,
      },
    },
    {
      sort: {
        direction: "desc",
        field: "checkedOn",
      },
    }
  );

  const [createInspection, createInspectionStatus] = useMutation(
    createVehiclePeriodicInspection,
    {
      onCompleted: (data) => {
        vehicleInspectionForm.toggle(false);
        xGridObject.functions.refetch();
      },
      succeedMessage: "登録しました。",
      errorMessage: "エラーが発生したため、登録に失敗しました。",
    }
  );

  const onCreateInspection = async (value) => {
    const {
      templateId,
      personInChargeName,
      checkedOn,
      files,
      remarks,
      sections,
      expectedTemplateVersion,
    } = value ?? {};

    createInspection({
      input: {
        expectedChecksheetTemplateVersion: expectedTemplateVersion,
        vehicleId: id,
        checksheetTemplateId: templateId,
        personInChargeName: personInChargeName,
        checkedOn: formatJSDateToISODate(checkedOn),
        remarks: remarks,
        files: await toApiValue({ files: files }),
        sections: sections?.map((section, sectionIndex) => ({
          ...section,
          name: undefined,
          items: section?.items?.map((item) => ({
            ...item,
            name: undefined,
          })),
        })),
      },
    });
  };

  const managementVehicle = useQuery({
    query: getManagementVehicle,
    variables: {
      id: id,
    },
  });

  const [
    updateVehicleInstrumentMutation,
    updateVehicleInstrumentMutationStatus,
  ] = useMutation(updateVehicleInstrument, {
    onCompleted: (params) => {
      managementVehicle.refetch({
        id: id,
      });
      odoMeterForm.toggle(false);
    },
    succeedMessage: "更新しました。",
    errorMessage: "エラーが発生したため、更新できませんでした。",
  });

  const [
    updateVehicleCertificateMutation,
    updateVehicleCertificateMutationStatus,
  ] = useMutation(updateVehicleCertificate, {
    onCompleted: (params) => {
      managementVehicle.refetch({
        id: id,
      });
      inspectionExpirationDateForm.toggle(false);
    },
    succeedMessage: "更新しました。",
    errorMessage: "エラーが発生したため、更新できませんでした。",
  });

  const handleUpdateVehicleInstrument = (data) => {
    const { odometer } = data;

    updateVehicleInstrumentMutation({
      input: {
        vehicleId: id,
        odometer: odometer,
      },
    });
  };

  const handleUpdateVehicleCertificate = (data) => {
    const { certificateExpiredOn } = data;

    updateVehicleCertificateMutation({
      input: {
        vehicleId: id,
        expiredOn: formatJSDateToISODate(certificateExpiredOn),
      },
    });
  };

  return render({
    ...props,
    onSelected: handleSelected,
    inspectionExpirationDateForm: inspectionExpirationDateForm,
    odoMeterForm: odoMeterForm,
    checkSheetSelector: checkSheetSelector,
    vehicleInspectionForm: vehicleInspectionForm,
    xGridParams: xGridObject.params,
    loading:
      managementVehicle.loading ||
      createInspectionStatus.loading ||
      updateVehicleInstrumentMutationStatus.loading ||
      updateVehicleCertificateMutationStatus.loading,
    value: managementVehicle?.data?.getManagementVehicle,
    onCreateInspection: onCreateInspection,
    onUpdateVehicleCertificate: handleUpdateVehicleCertificate,
    onUpdateVehicleInstrument: handleUpdateVehicleInstrument,
  });
};
