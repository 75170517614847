import { Document, Page, pdfjs } from "react-pdf";
import { useMemo } from "react";
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";

pdfjs.GlobalWorkerOptions.workerSrc = `${process.env.PUBLIC_URL}/pdf.worker.min.mjs`;

/**
 * PDFの最初のページを表示するコンポーネントです。
 * @param {object} props プロパティ
 * @param {URL | File | ParameterObject} props.src ファイルソース
 * @param {number} props.height 高さ
 * @param {number} props.width 幅
 * @returns {JSX.Element}
 */
export const SinglePDFPage = ({ src, height, width }) => {
  const options = useMemo(() => {
    return {
      cMapUrl: `${process.env.PUBLIC_URL}/cmaps/`,
      cMapPacked: true,
      standardFontDataUrl: `${process.env.PUBLIC_URL}/standard_fonts/`,
    };
  }, []);

  return (
    <Document file={src} options={options}>
      <Page
        height={height}
        width={width}
        pageNumber={1}
        renderAnnotationLayer={false}
      />
    </Document>
  );
};
