import { Box, Container, Grid, Paper } from "@mui/material";
import { Controller } from "react-hook-form";
import { DatePicker, TextField } from "views/atoms";
import {
  ClientWorkplaceSelector,
  ContainerSelector,
  InputFieldPopppSelect,
  ModalPopup,
  VehicleSelector,
} from "views/molecules";

/**
 * コンテナ設置フォームを表示するプレゼンテーションコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = (props) => {
  return (
    <Container maxWidth="md">
      <Paper elevation={3} sx={{ p: 4 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Controller
              name={`container`}
              control={props.control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => {
                return (
                  <>
                    <InputFieldPopppSelect
                      label={"コンテナ"}
                      value={{
                        name: !!value
                          ? `${value?.productManufacturerName} ${value?.productName}`
                          : "",
                      }}
                      required={true}
                      placeholder={"コンテナを選択します。"}
                      error={error}
                      onOpenModal={() => {
                        props.setValue("container", null);
                        props.containerSelectorForm.toggle(true);
                      }}
                      textConfirm="選択"
                      disableButton={props.disabled}
                    />
                    <ModalPopup
                      open={props.containerSelectorForm.open}
                      onClose={() => props.containerSelectorForm.toggle(false)}
                    >
                      <Box pt={3} pb={3} sx={{ height: "85vh" }}>
                        <ContainerSelector
                          value={value}
                          multiple={false}
                          onSelected={(item) => {
                            onChange(item);
                            props.containerSelectorForm.toggle(false);
                          }}
                          fixedOptions={{
                            filter: {
                              and: [
                                {
                                  installationLocationCategory: {
                                    eq: "Storage",
                                  },
                                },
                                {
                                  state: {
                                    eq: "active",
                                  },
                                },
                              ],
                            },
                          }}
                        />
                      </Box>
                    </ModalPopup>
                  </>
                );
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name={`installationWorkplace`}
              control={props.control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => {
                return (
                  <>
                    <InputFieldPopppSelect
                      label={"設置場所"}
                      value={{
                        name: value
                          ? `${value?.belongInCompanyName} ${value?.name}`
                          : "",
                      }}
                      required={true}
                      placeholder={"設置場所を選択します。"}
                      error={error}
                      onOpenModal={() => {
                        props.setValue("installationWorkplace", null);
                        props.installationWorkplaceSelectorForm.toggle(true);
                      }}
                      textConfirm="選択"
                      disabledButtom={props.disabled}
                    />
                    <ModalPopup
                      open={props.installationWorkplaceSelectorForm.open}
                      onClose={() =>
                        props.installationWorkplaceSelectorForm.toggle(false)
                      }
                    >
                      <Box pt={3} pb={3} sx={{ height: "85vh" }}>
                        <ClientWorkplaceSelector
                          value={value}
                          onSelected={(items) => {
                            onChange(items?.[0]);
                            props.installationWorkplaceSelectorForm.toggle(
                              false
                            );
                          }}
                          fixedOptions={{
                            filter: {
                              and: [
                                {
                                  categoryId: {
                                    eq: "containerInstallationLocation",
                                  },
                                },
                              ],
                            },
                          }}
                        />
                      </Box>
                    </ModalPopup>
                  </>
                );
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name={`shippedOn`}
              control={props.control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => (
                <DatePicker
                  label="移動開始日"
                  required={true}
                  value={value}
                  onChange={(event) => {
                    onChange(event);
                    props.trigger("installedOn");
                  }}
                  maxDate={new Date()}
                  error={!!error}
                  helperText={error?.message}
                  disabled={props.disabled}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name={`installedOn`}
              control={props.control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => (
                <DatePicker
                  label="設置日"
                  required={true}
                  value={value}
                  onChange={(event) => {
                    onChange(event);
                    props.trigger("shippedOn");
                  }}
                  maxDate={new Date()}
                  error={!!error}
                  helperText={error?.message}
                  disabled={props.disabled}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name={`vehicle`}
              control={props.control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => {
                return (
                  <>
                    <InputFieldPopppSelect
                      label={"車輛"}
                      value={{ name: value?.number }}
                      onChange={onChange}
                      required={false}
                      placeholder={"車輛を選択します。"}
                      error={error}
                      onOpenModal={() => {
                        props.setValue("vehicle", null);
                        props.vehicleSelectorForm.toggle(true);
                      }}
                      textConfirm="選択"
                      disabledButtom={props.disabled}
                    />
                    <ModalPopup
                      open={props.vehicleSelectorForm.open}
                      onClose={() => props.vehicleSelectorForm.toggle(false)}
                    >
                      <Box pt={3} pb={3} sx={{ height: "85vh" }}>
                        <VehicleSelector
                          value={value}
                          companyId={props?.company?.id}
                          onSelected={(e) => {
                            onChange(e?.value?.[0]);
                            props.vehicleSelectorForm.toggle(false);
                          }}
                          action={false}
                        />
                      </Box>
                    </ModalPopup>
                  </>
                );
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name={`personInChargeName`}
              control={props.control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => (
                <TextField
                  label="実施担当者"
                  required={false}
                  placeholder="実施担当者を入力します。"
                  value={value}
                  onChange={onChange}
                  error={error}
                  disabled={props.disabled}
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name={`remarks`}
              control={props.control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => (
                <TextField
                  label="備考"
                  required={false}
                  placeholder="特記事項があれば入力します。"
                  value={value}
                  onChange={onChange}
                  error={error}
                  row={3}
                  multiline={true}
                  disabled={props.disabled}
                  fullWidth
                  rows={3}
                />
              )}
            />
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
};
