import { add as addAlert } from "ducks/Alert";
import { selector as GlobalLoadingSelector } from "ducks/Loading";
import { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { debugLog } from "utils/log";
import useClasses from "utils/useClasses";
import { styles } from "./styles";
import { useOpener } from "utils/useOpener";

/*
  name
  driverName
  vehicle
    number[番号]
    icon[アイコン]
    type[車種]
  collects:[]
    one object shape
      order[回収順番]: number
      times[時刻]: object
        start[開始]: Date
        end[終了]: Date
      name[回収場所]: string
      wasteName[廃棄物名]: string
      quantity[数量]: number
      unit[単位]: string ???
*/

/**
 * 配車表を表示するコンテナコンポーネントです
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {object} props その他プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({
  render,
  value,
  date,
  create,
  update,
  remove,
  setValue,
  onCreated,
  onUpdated,
  onChangeDate,
  ...props
}) => {
  const [isSubmit, setIsSubmit] = useState(false);
  const [isMonthlyViewOpen, setIsMonthlyViewOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [reFetch, setReFetch] = useState(false);

  const classes = useClasses(styles);
  const formRef = useRef(null);
  const dispatch = useDispatch();
  const globalLoading = useSelector(GlobalLoadingSelector).status;
  const removeDialog = useOpener();

  const handleConfirm = () => {
    formRef.current.submit();
  };

  useEffect(() => {
    if (!value) {
      removeDialog.toggle(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const handleSubmit = (data) => {
    setIsSubmit(true);
    update(data)
      .then((res) => {
        dispatch(
          addAlert({
            value: "保存しました。",
            severity: "success",
          })
        );
        onUpdated(res);
      })
      .catch((err) => {
        debugLog("Allocation.Schedule.update.error:", err);
        dispatch(
          addAlert({
            value: "エラーが発生したため、保存できませんでした。",
            severity: "error",
          })
        );
      })
      .finally(() => {
        setIsSubmit(false);
      });
  };

  const handleCreateAndOpen = () => {
    setLoading(true);
    create(date)
      .then((res) => {
        onCreated(res);
      })
      .catch((err) => {
        debugLog("Allocation.Schedule.create.error:", err);
        dispatch(
          addAlert({
            value: "エラーが発生したため、保存できませんでした。",
            severity: "error",
          })
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const toggleMonthlyModal = () => {
    setIsMonthlyViewOpen((prevState) => !prevState);
  };

  const handleMonthlyModelClose = () => {
    setIsMonthlyViewOpen(false);
    props.refresh();
  };

  return render({
    value: value,
    classes: classes,
    setValue,
    onCreateAndOpen: handleCreateAndOpen,
    onConfirm: handleConfirm,
    date: date,
    nextDay: date?.plus({ days: 1 }),
    onChangeDate: onChangeDate,
    formRef: formRef,
    onSubmit: handleSubmit,
    isSubmit: isSubmit,
    globalLoading: globalLoading,
    remove: remove,
    removeDialog: removeDialog,
    toggleMonthlyModal: toggleMonthlyModal,
    isMonthlyViewOpen: isMonthlyViewOpen,
    handleMonthlyModelClose,
    loading: loading,
    reFetch: reFetch,
    setReFetch: setReFetch,
    ...props,
  });
};
