import { createWorkplace } from "api/graphql/mutations";
import { searchWorkplaceIncludingClientsByOffset } from "api/graphql/queries";
import { API, graphqlOperation } from "utils/graphqlOperation";
import { add as addAlert } from "ducks/Alert";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation, createSearchParams } from "react-router-dom";
import useClasses from "utils/useClasses";
import { useXGridComponents } from "utils/useXGridComponents";
import { useStyles } from "./styles";
import { EditableWorkplaceStatus } from "utils/Context";
import { PhoneNumberFormatUtil } from "utils/format";
import { debugLog } from "utils/log";
import { toQueryString, fromQueryString } from "utils/useXGridComponents";
import { FILTER_OPERATORS_CONTAINS_EQUAL } from "utils/constant";

const columns = [
  {
    field: "name",
    headerName: "名称",
    minWidth: 200,
    flex: 1,
    filterOperators: FILTER_OPERATORS_CONTAINS_EQUAL,
  },
  {
    field: "postalCode",
    headerName: "郵便番号",
    minWidth: 135,
    flex: 1,
    filterOperators: FILTER_OPERATORS_CONTAINS_EQUAL,
  },
  {
    field: "prefecturesName",
    headerName: "都道府県",
    valueGetter: (params) => params.row.prefectures?.name,
    minWidth: 135,
    flex: 1,
    filterOperators: FILTER_OPERATORS_CONTAINS_EQUAL,
  },
  {
    field: "city",
    headerName: "市区町村",
    minWidth: 135,
    flex: 1,
    filterOperators: FILTER_OPERATORS_CONTAINS_EQUAL,
  },
  {
    field: "streetAddress",
    headerName: "町名",
    minWidth: 130,
    flex: 1,
    filterOperators: FILTER_OPERATORS_CONTAINS_EQUAL,
  },
  {
    field: "otherAddress",
    headerName: "町名以降",
    minWidth: 200,
    flex: 1,
    filterOperators: FILTER_OPERATORS_CONTAINS_EQUAL,
  },
  {
    field: "phone",
    headerName: "本社電話番号",
    minWidth: 170,
    flex: 1,
    valueFormatter: (params) =>
      PhoneNumberFormatUtil.formatNational(params.value),
    filterOperators: FILTER_OPERATORS_CONTAINS_EQUAL,
  },
];

/**
 * 事業場タブの表示を行うコンテナコンポーネントです
 * @param {object} value 事業場の情報
 * @callback render
 */
export const Container = ({ render, value, ...props }) => {
  const dispatch = useDispatch();
  const classes = useClasses(useStyles);
  const navigate = useNavigate();
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const xGridObject = useXGridComponents(
    columns,
    searchWorkplaceIncludingClientsByOffset,
    {
      other: {
        belongInCompanyId: value?.companyId,
      },
    },
    {
      sort: {
        direction: "desc",
        field: "createdAt",
      },
      ...fromQueryString(location.search),
    }
  );

  const create = (data) => {
    setIsSubmit(true);
    const { categories, industrySector, phone, fax, prefectures, ...other } =
      data;
    API.graphql(
      graphqlOperation(createWorkplace, {
        input: {
          categoryIds: categories?.map((category) => category.id),
          state: EditableWorkplaceStatus.find((item) => item.name === "開店")
            .code,
          phone: phone
            ? PhoneNumberFormatUtil.formatInternational(phone)
            : null,
          fax: fax ? PhoneNumberFormatUtil.formatInternational(fax) : null,
          belongInCompanyId: value.companyId,
          industrySectorId: industrySector?.id,
          prefecturesCode: prefectures.code,
          ...other,
        },
      })
    )
      .then((res) => {
        dispatch(
          addAlert({
            value: "登録しました。",
            severity: "success",
          })
        );
        navigate({
          pathname: `${location.pathname}/workplace/${res.data.createWorkplace.id}`,
        });
        xGridObject.functions.refetch();
        setOpen(false);
      })
      .catch((err) => {
        debugLog("事業場.生成失敗: ", err);
        dispatch(
          addAlert({
            value: "エラーが発生したため、登録できませんでした。",
            severity: "error",
          })
        );
      })
      .finally(() => {
        setIsSubmit(false);
      });
  };

  const handleSelectionClick = (params) => {
    const selected = xGridObject.params.rows.find(
      (row) => row.id === params[0]
    );

    navigate({
      pathname: location.pathname,
      search: `?${createSearchParams({
        ...Object.fromEntries(new URLSearchParams(location.search)),
        ...Object.fromEntries(
          new URLSearchParams(toQueryString(xGridObject.search))
        ),
      }).toString()}`,
    });

    navigate(`${location.pathname}/workplace/${selected.id}`);
  };

  return render({
    ...props,
    classes: classes,
    value: value,
    onChangeSelection: handleSelectionClick,
    open: open,
    onOpenDialog: () => setOpen(true),
    onCloseDialog: () => setOpen(false),
    xGridParams: xGridObject.params,
    submit: create,
    isSubmit: isSubmit,
  });
};
