import React from "react";
import { Box, Button, Skeleton, Stack, Link } from "@mui/material";
import { isEmpty } from "lodash";
import { DateStepper } from "views/molecules";
import { Schedule } from "views/organisms/Allocation";
import { ConfirmDialog, FullScreenDialog } from "views/molecules";
import { If } from "views/atoms";
import { MonthlyAllocationView } from "views/templates/Allocation";
import { NotVehicleDispatchCollectSchedules } from "views/organisms/Allocation/NotVehicleDispatchCollectSchedules";
/**
 * 配車表を表示するプレゼンテーションコンポーネントです
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = (props) => {
  return (
    <Box
      sx={{ backgroundColor: "#fff", padding: (theme) => theme.spacing(2, 4) }}
    >
      <DateStepper
        onChange={props.onChangeDate}
        value={props.date}
        actions={
          <>
            <If condition={props.globalLoading}>
              <Skeleton variant="rectangular" width={180} height={40} />
            </If>
            <If condition={!props.globalLoading}>
              <Stack display="flex" flexDirection="row" gap={5}>
                <Link
                  component="button"
                  variant="body2"
                  onClick={props.toggleMonthlyModal}
                >
                  月別一覧表示
                </Link>
                <If
                  condition={!isEmpty(props.value?.done?.id)}
                  elseComponent={
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={props.loading}
                      onClick={props.onCreateAndOpen}
                    >
                      {"配車表を作成する"}
                    </Button>
                  }
                >
                  <Stack spacing={2} direction="row">
                    <Button
                      variant="contained"
                      color="error"
                      disabled={props.loading}
                      onClick={() => props.removeDialog.toggle(true)}
                    >
                      {"配車表を削除する"}
                    </Button>
                  </Stack>
                </If>
              </Stack>
            </If>
          </>
        }
      >
        {() => {
          if (props.isLoading) return null;

          return (
            <>
              <NotVehicleDispatchCollectSchedules
                rows={props.value?.still ?? []}
                date={props.date}
                refresh={props.reFetch}
                setRefresh={props.setReFetch}
              />

              {props.value?.done && (
                <Schedule
                  isLoading={props.isLoading}
                  setValue={props.setValue}
                  value={props.value}
                  date={props.date}
                  total={props.total}
                  setTotal={props.setTotal}
                  hasNextPage={props.hasNextPage}
                  handleLoadMore={props.handleLoadMore}
                  loadingUnAssigned={props.loadingUnAssigned}
                  searchWasteCollectionSchedules={
                    props.searchWasteCollectionSchedules
                  }
                  logisticsWorkAllocation={props.logisticsWorkAllocation}
                  isCreateNew={props.isCreateNew}
                  setIsCreateNew={props.setIsCreateNew}
                  setRefresh={props.setReFetch}
                />
              )}
            </>
          );
        }}
      </DateStepper>
      <If condition={props.value}>
        <ConfirmDialog
          disabled={props.globalLoading}
          open={props.removeDialog.open}
          title="削除確認"
          message="削除します。よろしいですか？"
          positiveText="はい"
          negativeText="いいえ"
          onPositive={() => {
            props.remove(props.value?.done?.id);
          }}
          onNegative={() => props.removeDialog.toggle(false)}
        />
      </If>
      <FullScreenDialog
        title="月別配車表一覧"
        textConfirm=""
        open={props.isMonthlyViewOpen}
        onClose={props.handleMonthlyModelClose}
        visibilityConfirm={false}
      >
        <MonthlyAllocationView
          date={props.date}
          onClose={props.handleMonthlyModelClose}
          onChangeDate={props.onChangeDate}
        />
      </FullScreenDialog>
    </Box>
  );
};
