import { yupResolver } from "@hookform/resolvers/yup";
import { companySelector } from "ducks/Company";
import { useEffect, useImperativeHandle } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useOpener } from "utils/useOpener";
import { basicSchema } from "./Validations";

const defaultValues = {
  storageWorkplace: null,
  removedOn: null,
  storedOn: null,
  vehicle: null,
  personInChargeName: "",
  remarks: "",
};

/**
 * コンテナ撤去入力フォームを表示するコンテナコンポーネントです。
 */
export const Container = ({
  render,
  onSubmit = () => {},
  onError = () => {},
  disabled = false,
  info,
  ...props
}) => {
  const safekeeping = useOpener();
  const establishment = useOpener();
  const clientWorkplaceSelectorForm = useOpener();
  const vehicleSelectorForm = useOpener();
  const company = useSelector(companySelector);

  const {
    trigger,
    getValues,
    control,
    formState,
    watch,
    setValue,
    register,
    reset,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(basicSchema),
    defaultValues: {
      ...defaultValues,
    },
  });

  useEffect(() => {
    reset({
      ...defaultValues,
    });
  }, [reset]);

  const [removedOn, storedOn] = watch(["removedOn", "storedOn"]);

  useEffect(() => {
    if (!!removedOn && !watch("storedOn")) {
      setValue("storedOn", removedOn, {
        shouldValidate: true,
        shouldDirty: true,
      });
    }
  }, [watch, setValue, removedOn]);

  useEffect(() => {
    if (!!storedOn && !watch("removedOn")) {
      setValue("removedOn", storedOn, {
        shouldValidate: true,
        shouldDirty: true,
      });
    }
  }, [watch, setValue, storedOn]);

  useImperativeHandle(props._ref, () => ({
    submit: () => {
      trigger().then((res) => {
        if (res) {
          onSubmit(getValues());
        } else {
          onError(formState.errors);
        }
      });
    },
    get: () => getValues(),
  }));

  const handleClick = (row) => (event) => {
    if (!row?.instratedAt) {
      establishment.toggle(true);
      establishment.changeOptions({
        anchorEl: event.currentTarget,
        value: row,
      });
    } else {
      safekeeping.toggle(true);
      safekeeping.changeOptions({
        anchorEl: event.currentTarget,
        value: row,
      });
    }
  };

  const handleClose = () => {
    safekeeping.toggle(false);
    establishment.toggle(false);
  };

  return render({
    ...props,
    onClick: handleClick,
    onClose: handleClose,
    safekeeping: safekeeping,
    establishment: establishment,
    control: control,
    disabled: disabled,
    clientWorkplaceSelectorForm: clientWorkplaceSelectorForm,
    vehicleSelectorForm: vehicleSelectorForm,
    company: company,
    info: info,
    watch: watch,
    setValue: setValue,
    register: register,
    getValues: getValues,
    trigger: trigger,
  });
};
