import { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { get } from "lodash";
import { XGridTableGuiderSteps } from "utils/GuiderSteps";
import { debugLog } from "utils/log";
import { searchWasteFlowsByOffset } from "api/graphql/queries";
import { useXGridComponents } from "utils/useXGridComponents";
import { createWasteFlow } from "api/graphql/mutations";
import { API, graphqlOperation } from "utils/graphqlOperation";
import { add as addAlert } from "ducks/Alert";
import { companySelector } from "ducks/Company";
import { FILTER_OPERATORS_CONTAINS_EQUAL } from "utils/constant";

export const columns = [
  {
    field: "number",
    headerName: "管理番号",
    minWidth: 140,
    flex: 1,
    filterOperators: FILTER_OPERATORS_CONTAINS_EQUAL,
  },
  {
    field: "name",
    headerName: "フローの名称",
    minWidth: 140,
    flex: 1,
    filterOperators: FILTER_OPERATORS_CONTAINS_EQUAL,
  },
  {
    field: "targetWasteTypeOrder",
    headerName: "廃棄物種類コード",
    flex: 1,
    minWidth: 200,
    valueGetter: (params) =>
      `${params.row.targetWasteType?.largeClass?.code ?? ""}${
        params.row.targetWasteType?.middleClass?.code ?? ""
      }${params.row.targetWasteType?.smallClass?.code ?? ""}`,
    filterable: false,
  },
  {
    field: "targetWasteLargeClassName",
    headerName: "廃棄物種類大分類",
    flex: 1,
    minWidth: 200,
    valueGetter: (params) => params.row.targetWasteType?.largeClass?.name,
    filterOperators: FILTER_OPERATORS_CONTAINS_EQUAL,
  },
  {
    field: "targetWasteMiddleClassName",
    headerName: "廃棄物種類中分類",
    flex: 1,
    minWidth: 200,
    valueGetter: (params) => params.row.targetWasteType?.middleClass?.name,
    hide: true,
    filterOperators: FILTER_OPERATORS_CONTAINS_EQUAL,
  },
  {
    field: "targetWasteSmallClassName",
    headerName: "廃棄物種類小分類",
    flex: 1,
    minWidth: 200,
    valueGetter: (params) => params.row.targetWasteType?.smallClass?.name,
    hide: true,
    filterOperators: FILTER_OPERATORS_CONTAINS_EQUAL,
  },
  {
    field: "transportSectionCount",
    headerName: "運搬区間数",
    type: "number",
    flex: 1,
    minWidth: 160,
    valueGetter: (params) => params.row.transportSectionCount ?? 0,
    hide: true,
  },
  {
    field: "disposalCompanyName",
    headerName: "処分受託者",
    flex: 1,
    minWidth: 160,
    valueGetter: (params) => params.row.disposalProcess?.disposalCompany?.name,
    filterOperators: FILTER_OPERATORS_CONTAINS_EQUAL,
  },
  {
    field: "disposalWorkplaceName",
    headerName: "処分事業場",
    valueGetter: (params) =>
      params.row.disposalProcess?.disposalWorkplace?.name,
    flex: 1,
    minWidth: 160,
    filterOperators: FILTER_OPERATORS_CONTAINS_EQUAL,
  },
  {
    field: "disposalMethodLargeClassName",
    headerName: "処分方法大分類",
    valueGetter: (params) =>
      params.row.disposalProcess?.disposalMethod?.largeClassName,
    flex: 1,
    minWidth: 180,
    filterOperators: FILTER_OPERATORS_CONTAINS_EQUAL,
  },
  {
    field: "disposalMethodMiddleClassName",
    headerName: "処分方法中分類",
    valueGetter: (params) =>
      params.row.disposalProcess?.disposalMethod?.middleClassName,
    flex: 1,
    minWidth: 180,
    hide: true,
    filterOperators: FILTER_OPERATORS_CONTAINS_EQUAL,
  },
  {
    field: "disposalMethodSmallClassName",
    headerName: "処分方法小分類",
    valueGetter: (params) =>
      params.row.disposalProcess?.disposalMethod?.smallClassName,
    flex: 1,
    minWidth: 180,
    hide: true,
    filterOperators: FILTER_OPERATORS_CONTAINS_EQUAL,
  },
];

/**
 * 処理フローテーブルを表示するコンポーネントです
 * @param {array} dataTable テーブルのデータ
 * @param {string} dialogId 間違えないように、固有番号をつけてください。
 * @param {boolean} checkboxSelection 行頭にチェックボックスを表示する
 * @param {object} props プロパティ
 * @callback render
 * @returns
 */
export const Container = ({
  render,
  onSelected = (data) => debugLog(data),
  initialState,
  ...props
}) => {
  const company = useSelector(companySelector);
  const [open, setOpen] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const xGridObject = useXGridComponents(
    columns,
    searchWasteFlowsByOffset,
    {
      other: {
        managedCompanyId: company.id,
      },
    },
    {
      ...initialState,
      filter: {
        ...get(initialState, "filter", {}),
        defaultColumnField: get(columns, "[1].field"),
      },
    }
  );
  const formRef = useRef(null);

  const dispatch = useDispatch();

  const handleChangeSelection = (value) => {
    onSelected({
      value: xGridObject.params.rows.find((row) => row.id === value[0]),
      info: {
        search: xGridObject.search,
      },
    });
  };

  const handleSubmit = (data) => {
    setIsSubmit(true);
    const {
      targetWaste,
      wasteKind,
      transportProcesses,
      disposalProcess,
      disposalMethod,
      disposalDetails,
      lastDisposalWorkplaces,
      version,
      ...other
    } = data;

    API.graphql(
      graphqlOperation(createWasteFlow, {
        input: {
          managedCompanyId: company.id,
          targetWasteTypeId: targetWaste.id,
          targetWasteKind: wasteKind,
          transportProcesses: transportProcesses.map((item) => {
            return {
              /* UNDONE: 複数に戻したときに item.trustee.companyIdに戻すこと */
              carrierCompanyId: item.trustee.id,
              wasteTransportMethodCode: item.method.code,
              transportDestinationWorkplaceId: item.destination.id,
            };
          }),
          disposalProcess: {
            disposalCompanyId: disposalProcess.client.belongInCompanyId,
            disposalWorkplaceId: disposalProcess.client.id,
            wasteDisposalMethodCode: disposalProcess.method,
            disposalMethodDetails: disposalProcess.details,
          },
          lastDisposalProcesses:
            lastDisposalWorkplaces?.map((item) => ({
              disposalWorkplaceId: item.id,
            })) ?? [],
          expectedVersion: version,
          ...other,
        },
      })
    )
      .then((res) => {
        xGridObject.functions.refetch();
        dispatch(
          addAlert({
            value: "登録しました。",
            severity: "success",
          })
        );
        setOpen(false);
      })
      .catch((err) => {
        debugLog("処理フロー登録失敗: ", err);
        dispatch(
          addAlert({
            value: "エラーが発生したため、登録できませんでした。",
            severity: "error",
          })
        );
      })
      .finally(() => {
        setIsSubmit(false);
      });
  };

  const handleClickRegister = () => {
    formRef.current.submit();
  };

  return render({
    ...props,
    open: open,
    onCloseDialog: () => setOpen(false),
    onOpenDialog: () => setOpen(true),
    getDataSubmit: handleSubmit,
    onChangeSelection: handleChangeSelection,
    GuiderSteps: XGridTableGuiderSteps,
    xGridParams: xGridObject.params,
    isSubmit: isSubmit,
    formRef: formRef,
    onClickRegister: handleClickRegister,
  });
};
