import React, { useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { API, graphqlOperation } from "utils/graphqlOperation";
import { add as addAlert } from "ducks/Alert";
import { EventAvailableOutlined } from "@mui/icons-material";
import { useJsApiLoader } from "@react-google-maps/api";
import { Grid, Box } from "@mui/material";
import { v4 as uuid } from "uuid";

import DomainAddOutlinedIcon from "@mui/icons-material/DomainAddOutlined";

import {
  parseClientsExcel,
  parseBulkWorkplaceCreateExcel,
  createWorkplaces,
  createClients,
} from "api/graphql/mutations";
import { companySelector } from "ducks/Company";
import { RenderMenuSelectItem } from "views/atoms";
import { FullScreenDialog } from "views/molecules";
import { useOpener } from "utils/useOpener";
import { ExcelUpload } from "views/organisms/ExcelUpload";
import { debugLog } from "utils/log";
import { fetchLatLng, getLatLng } from "views/templates/Mobile/Collect/Utils";
import { PhoneNumberFormatUtil } from "utils/format";
import BulkCreateTable from "./BulkCreateTable";
import { DateTime } from "luxon";

function groupByDuplicateKeys(data, key) {
  const groupedData = {};

  data.forEach((item) => {
    const keyValue = item[key];

    if (!groupedData[keyValue]) {
      groupedData[keyValue] = [];
    }

    groupedData[keyValue].push(item);
  });

  return Object.values(groupedData);
}

const parsedDataErrors = (data) => {
  const groupedData = groupByDuplicateKeys(data, "row");
  const parsedData = [];
  groupedData.forEach((item) => {
    let currentRowData = {
      errors: [],
    };
    item.forEach((rowData) => {
      const { row, field, error, data, duplicate } = rowData;
      currentRowData = {
        ...currentRowData,
        row,
        errors: error
          ? [...currentRowData.errors, { field, error, duplicate }]
          : currentRowData.errors,
        ...data,
      };
    });
    parsedData.push(currentRowData);
  });
  return parsedData;
};

const parseTheExcelValidationErrors = (errors) => {
  const data = [];
  errors.forEach((item) => {
    let field = "";
    let duplicate = false;

    if (item.column?.includes("duplicate")) {
      field = item.column.split(".")[0];
      duplicate = true;
    } else {
      field = item.column;
    }
    data.push({
      ...item,
      data: {
        ...item.data,
        id: item.data.id ?? uuid(),
        industrySectorId: item.data.industry ?? item.data.industrySectorId,
      },
      row: item.row + 1,
      error: item.error,
      field: field,
      duplicate: duplicate,
    });
  });
  return data;
};

const acceptedFileTypes = [
  ".xls",
  ".xlsx",
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
];

const bulkImportType = [
  {
    name: "取引先",
    value: "business-partner",
    templateDownloadTitle: "取引先一括登録のテンプレートをダウンロード",
    templatePath: "取引先一括登録テンプレート.xlsx",
    pageTitle: "取引先の一括登録",
    formId: "partner-form-bulk",
  },
  {
    name: "事業場",
    value: "business-workplaces",
    templateDownloadTitle: "事業場一括登録のテンプレートをダウンロード",
    templatePath: "事業場一括登録テンプレート.xlsx",
    pageTitle: "事業場の一括登録",
    formId: "business-workplaces",
  },
];

export const PartnerBulkCreate = () => {
  useJsApiLoader({
    id: "google-map",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_LICENSE_KEY,
    region: "JP",
    language: "ja",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errors, setErrors] = useState([]);
  const [rows, setRows] = useState([]);
  const [bulkCreateType, setBulkCreateType] = useState(
    bulkImportType.at(0).value
  );
  const [isUploading, setIsUploading] = useState(false);
  const { open, toggle } = useOpener();
  const alert = useOpener(false);
  const company = useSelector(companySelector);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const updateRows = useCallback((data) => {
    setRows(data);
  }, []);

  const updateErrors = useCallback((data) => {
    setErrors(data);
  }, []);

  const downloadTemplate = () => {
    const link = document.createElement("a");
    link.style.display = "none";
    document.body.appendChild(link);
    const bulkCreateData = bulkImportType.find(
      (item) => item.value === bulkCreateType
    );
    if (!bulkCreateData) {
      return null;
    }
    link.download = bulkCreateData.templatePath;
    link.href = `/${bulkCreateData.templatePath}`;
    link.click();
    document.body.removeChild(link);
    alert.toggle(false);
  };

  const onFullScreenClose = () => {
    setErrors([]);
    setRows([]);
    toggle(false);
  };

  const currentImportType = (() => {
    switch (bulkCreateType) {
      case bulkImportType.at(0).value:
        return bulkImportType.at(0);
      case bulkImportType.at(1).value:
        return bulkImportType.at(1);
      default:
        return null;
    }
  })();

  const onParseBusinessPartners = (file) => {
    const { name, context } = file;
    API.graphql(
      graphqlOperation(parseClientsExcel, {
        input: {
          file: {
            name,
            base64: context,
          },
        },
      })
    )
      .then((response) => {
        const data = response.data.parseClientsExcel;
        setRows(data);
      })
      .catch((err) => {
        debugLog(err);
        const errorType = err.errorType;
        const errors = err?.errors?.[0]?.errorInfo;
        if (errors && Array.isArray(errors)) {
          const parsedData = parsedDataErrors(errors);
          setErrors(parsedData);
        } else {
          switch (errorType) {
            case "FileProcessingError":
              dispatch(
                addAlert({
                  value:
                    "ファイルの処理中にエラーが発生しました。ファイルの内容を確認して、再度お試しください。",
                  severity: "error",
                })
              );
              break;
            case "dataError":
              dispatch(
                addAlert({
                  value:
                    "データは空でした。ファイルの内容を確認して、再試行してください。",
                  severity: "error",
                })
              );
              break;

            default:
              dispatch(
                addAlert({
                  value: "エラーが発生しました。もう一度お試しください。",
                  severity: "error",
                })
              );
              break;
          }
        }
      })
      .finally(() => {
        setIsSubmitting(false);
        setIsUploading(false);
      });
  };

  const onParseBusinessWorkplaces = (file) => {
    const { name, context } = file;
    API.graphql(
      graphqlOperation(parseBulkWorkplaceCreateExcel, {
        input: {
          file: {
            name,
            base64: context,
          },
        },
      })
    )
      .then(async (response) => {
        const workPlaces = response.data.parseBulkWorkplaceCreateExcel;
        const errors = [];
        workPlaces.forEach((item, index) => {
          const phoneValid = item.phone
            ? PhoneNumberFormatUtil.isValid(item.phone)
            : true;
          const faxValid = item.fax
            ? PhoneNumberFormatUtil.isValid(item.fax)
            : true;

          if (!phoneValid) {
            errors.push({
              row: index + 1,
              column: "phone",
              error: "電話番号が無効です。",
              data: item,
            });
          }
          if (!faxValid) {
            errors.push({
              row: index + 1,
              column: "fax",
              error: "FAX番号が無効です。",
              data: item,
            });
          }
        });
        if (errors.length) {
          const data = parseTheExcelValidationErrors(errors);

          const parsedData = parsedDataErrors(data);
          setErrors(parsedData);
        } else {
          setRows(workPlaces);
        }
      })
      .catch((err) => {
        debugLog(err);
        const errors = err.errors[0];
        if (errors) {
          const errorInfo = errors.errorInfo;
          const errorType = errors.errorType;
          switch (errorType) {
            case "validation":
              dispatch(
                addAlert({
                  value: "エラーが発生しました。もう一度お試しください。",
                  severity: "error",
                })
              );
              break;
            case "FileProcessingError":
              dispatch(
                addAlert({
                  value:
                    "ファイルの処理中にエラーが発生しました。ファイルの内容を確認して、再度お試しください。",
                  severity: "error",
                })
              );
              break;
            case "dataError":
              dispatch(
                addAlert({
                  value:
                    "ファイルが空か、何か問題が発生した可能性があります。ファイルの内容を確認して、再試行してください。",
                  severity: "error",
                })
              );
              break;
            case "sub":
              dispatch(
                addAlert({
                  value: "この操作を実行する権限がありません。",
                  severity: "error",
                })
              );
              navigate("/master/partner");
              break;
            case "headerError":
              dispatch(
                addAlert({
                  value:
                    "ヘッダーが無効です。指定されたヘッダーを変更しないでください。",
                  severity: "error",
                })
              );
              break;
            case "excelValidationError":
              const data = parseTheExcelValidationErrors(errorInfo);

              const parsedData = parsedDataErrors(data);
              setErrors(parsedData);
              break;
            default:
              dispatch(
                addAlert({
                  value: "エラーが発生しました。もう一度お試しください。",
                  severity: "error",
                })
              );
              break;
          }
        }
      })
      .finally(() => {
        setIsSubmitting(false);
        setIsUploading(false);
      });
  };

  const onFileDrop = (file) => {
    setIsSubmitting(true);
    if (currentImportType.value === bulkImportType.at(0).value) {
      onParseBusinessPartners(file);
    }
    if (currentImportType.value === bulkImportType.at(1).value) {
      onParseBusinessWorkplaces(file);
    }
  };

  const onCreateBusinessPartners = () => {
    const data = rows.map((item) => ({
      name: item.name,
      phoneticGuidesOfName: "",
      categoryIds: item.categories.map((item) => item.id),
      corporateNumber: item.corporateNumber,
      representativeName: item.representativeName,
      establishmentedOn: item.establishmentedOn
        ? DateTime.fromISO(item.establishmentedOn).toFormat("yyyy-MM-dd")
        : null,
      url: item.url?.length ? item.url : null,
      remarks: item.remarks,
      supplierCompanyId: company.id,
    }));

    API.graphql(
      graphqlOperation(createClients, {
        input: { clients: data },
      })
    )
      .then(() => {
        setErrors([]);
        setRows([]);
        toggle(false);
        dispatch(
          addAlert({
            value: "登録しました。",
            severity: "success",
          })
        );
        navigate("/master/partner");
      })
      .catch((err) => {
        debugLog(err);

        dispatch(
          addAlert({
            value: "エラーが発生しました。もう一度お試しください。",
            severity: "error",
          })
        );
      })
      .finally(() => {
        setIsSubmitting(false);
        setIsUploading(false);
      });
  };

  const getDataWithCordinate = async () => {
    const data = [];
    for (let row of rows) {
      const fullAddress = `${row.prefectures?.name}${row.city}${row.streetAddress}${row.otherAddress}`;
      const latLng = await getLatLng(fullAddress);
      const position = fetchLatLng(latLng);
      data.push({
        categoryIds: row?.categories.map((item) => item.id),
        phoneticGuidesOfName: "",
        name: row.name,
        code: row.code ?? "",
        postalCode: row.postalCode,
        prefecturesCode: row.prefectures.code,
        industrySectorId: row.industrySectorId,
        city: row.city,
        streetAddress: row.streetAddress,
        otherAddress: row.otherAddress,
        position: position,
        phone: row.phone
          ? PhoneNumberFormatUtil.formatInternational(row.phone)
          : null,
        fax: row.fax
          ? PhoneNumberFormatUtil.formatInternational(row.fax)
          : null,
        email: row.email,
        isMapInput: false,
        state: "active",
        belongInCompanyId: row.belongInCompanyId,
        remarks: row.remarks,
      });
    }
    return data;
  };

  const onCreateBusinessWorkplaces = async () => {
    const data = await getDataWithCordinate();
    API.graphql(
      graphqlOperation(createWorkplaces, {
        input: data,
      })
    )
      .then(() => {
        setErrors([]);
        setRows([]);
        toggle(false);
        dispatch(
          addAlert({
            value: "登録しました。",
            severity: "success",
          })
        );
        navigate("/master/partner/create-bulk");
      })
      .catch((err) => {
        debugLog(err);
        const errors = err.errors[0];
        let errorExists = true;
        if (errors) {
          const errorType = errors.errorType;

          if (errorType === "excelValidationError") {
            const errorInfo = errors.errorInfo.map((item) => {
              const row = rows[item.row - 1];
              return {
                ...item,
                data: {
                  ...item.data,
                  companyName: row.companyName,
                  prefectures: row.prefectures,
                  categories: row.categories,
                },
              };
            });
            const data = parseTheExcelValidationErrors(errorInfo);

            const parsedData = parsedDataErrors(data);
            setErrors(parsedData);
            errorExists = false;
          }
        }
        if (errorExists) {
          dispatch(
            addAlert({
              value: "エラーが発生しました。もう一度お試しください。",
              severity: "error",
            })
          );
        }
      })
      .finally(() => {
        setIsSubmitting(false);
        setIsUploading(false);
      });
  };

  const handleMenuClick = (type) => {
    setBulkCreateType(type);
    toggle(true);
  };

  const handleSubmit = () => {
    setIsSubmitting(true);
    if (currentImportType.value === bulkImportType.at(0).value) {
      onCreateBusinessPartners();
    }
    if (currentImportType.value === bulkImportType.at(1).value) {
      onCreateBusinessWorkplaces();
    }
  };

  return (
    <Box p={1} mt={4}>
      <Grid container spacing={2} gap={2}>
        <RenderMenuSelectItem
          icon={EventAvailableOutlined}
          label="取引先"
          className="allocation_schedule"
          onClick={() => handleMenuClick(bulkImportType.at(0).value)}
        />
        <RenderMenuSelectItem
          icon={DomainAddOutlinedIcon}
          label="事業場"
          className="allocation_schedule"
          onClick={() => handleMenuClick(bulkImportType.at(1).value)}
        />
      </Grid>
      {open && (
        <FullScreenDialog
          open={open}
          onClose={onFullScreenClose}
          title={currentImportType.pageTitle}
          isSubmit={isSubmitting}
          container={false}
          visibilityConfirm={!!rows.length}
          textConfirm="登録"
          onClickSubmit={handleSubmit}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              marginTop: "-40px",
              height: "calc(100vh - 50px)",
              marginBottom: "-40px",
              paddingBottom: 2,
              paddingTop: 2,
            }}
          >
            {!errors.length && !rows.length ? (
              <ExcelUpload
                acceptedFileTypes={acceptedFileTypes}
                onFileDrop={onFileDrop}
                onTemplateDownload={downloadTemplate}
                templateLinkTitle={currentImportType.templateDownloadTitle}
                isUploading={isUploading}
                setIsUploading={setIsUploading}
              />
            ) : null}
            {errors.length || rows.length ? (
              <BulkCreateTable
                importType={currentImportType}
                rows={
                  errors?.length
                    ? errors
                    : rows.map((item, index) => {
                        return {
                          ...item,
                          row: index + 1,
                        };
                      })
                }
                isErrorTable={errors.length}
                setRows={updateRows}
                errors={errors}
                setErrors={updateErrors}
              />
            ) : null}
          </Box>
        </FullScreenDialog>
      )}
    </Box>
  );
};
