import { useNavigate } from "react-router-dom";
import { searchWorkplaceIncludingClientsByOffset } from "api/graphql/queries";
import { PhoneNumberFormatUtil } from "utils/format";
import { FILTER_OPERATORS_CONTAINS_EQUAL } from "utils/constant";
import { useXGridComponents } from "utils/useXGridComponents";

const columns = [
  {
    field: "belongInCompanyName",
    headerName: "事業者",
    minWidth: 200,
    flex: 1,
    filterOperators: FILTER_OPERATORS_CONTAINS_EQUAL,
  },
  {
    field: "name",
    headerName: "名称",
    minWidth: 200,
    flex: 1,
    filterOperators: FILTER_OPERATORS_CONTAINS_EQUAL,
  },
  {
    field: "postalCode",
    headerName: "郵便番号",
    maxWidth: 135,
    minWidth: 135,
    flex: 1,
    filterOperators: FILTER_OPERATORS_CONTAINS_EQUAL,
  },
  {
    field: "prefecturesName",
    headerName: "都道府県",
    valueGetter: (params) => params.row.prefectures?.name,
    minWidth: 135,
    flex: 1,
    filterOperators: FILTER_OPERATORS_CONTAINS_EQUAL,
  },
  {
    field: "city",
    headerName: "市区町村",
    minWidth: 135,
    flex: 1,
    filterOperators: FILTER_OPERATORS_CONTAINS_EQUAL,
  },
  {
    field: "streetAddress",
    headerName: "町名",
    minWidth: 120,
    flex: 1,
    filterOperators: FILTER_OPERATORS_CONTAINS_EQUAL,
  },
  {
    field: "otherAddress",
    headerName: "町名以降",
    minWidth: 135,
    flex: 1,
    filterOperators: FILTER_OPERATORS_CONTAINS_EQUAL,
  },
  {
    field: "email",
    headerName: "メールアドレス",
    minWidth: 180,
    flex: 1,
    hide: true,
    filterOperators: FILTER_OPERATORS_CONTAINS_EQUAL,
  },
  {
    field: "phone",
    headerName: "電話番号",
    minWidth: 135,
    flex: 1,
    valueFormatter: (params) =>
      PhoneNumberFormatUtil.formatNational(params.value),
    filterOperators: FILTER_OPERATORS_CONTAINS_EQUAL,
  },
  {
    field: "fax",
    headerName: "FAX番号",
    minWidth: 135,
    flex: 1,
    hide: true,
    valueFormatter: (params) =>
      PhoneNumberFormatUtil.formatNational(params.value),
    filterOperators: FILTER_OPERATORS_CONTAINS_EQUAL,
  },
];

/**
 *
 * @param {Object} props - コンポーネントに渡されるプロパティ
 * @param {Function} props.render - レンダリング関数
 * @param {...Object} props - その他のプロパティ
 *
 * @returns {JSX.Element}
 *
 */
export const Container = ({ render, ...props }) => {
  const navigate = useNavigate();
  const obj = useXGridComponents(
    columns,
    searchWorkplaceIncludingClientsByOffset,
    {
      other: {
        filter: {
          location: {
            eq: true,
          },
        },
      },
    }
  );

  const handleSelected = (params) => {
    const row = obj.params.rows
      .map((d, i) => ({ id: i, ...d }))
      .find((i) => i.id === params[0]);
    navigate(`/master/workplace/${row.id}`);
  };

  return render({
    xGridParams: obj.params,
    onSelected: handleSelected,
    ...props,
  });
};
