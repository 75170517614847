import { Box, Button, Paper, Stack, Typography } from "@mui/material";
import { getWorkplace } from "api/graphql/queries";
import { formatDisplayAddress, formatDisplayDate } from "utils/format";
import { useOpener } from "utils/useOpener";
import { useQuery } from "utils/useQuery";
import { InformationItem } from "views/atoms";
import { MovementHistory } from "views/organisms/Masters/ContainerDetails";
import { FileGallery } from "views/molecules";

/**
 * コンテナ詳細を表示するコンポーネントです。
 * @param {object} props プロパティ
 * @param {object} props.value 値
 * @fires Details#onClick クリック時
 * @returns {JSX.Element}
 */
export const Details = ({ value, basic, disabled = false }) => {
  const historyDialog = useOpener();
  const workplace = useQuery({
    query: getWorkplace,
    variables: {
      id: value?.installationWorkplaceId,
    },
  });

  if (workplace?.loading) {
    return <></>;
  }

  if (workplace?.error) {
    return <></>;
  }

  return (
    <>
      <Stack spacing={2}>
        <Paper sx={{ p: 2 }}>
          <Stack spacing={2}>
            <Stack direction="row" justifyContent="space-between">
              <Box>
                <Typography
                  sx={{
                    textDecoration: "underline",
                    fontSize: "17px",
                  }}
                >
                  {"現在の設置・保管場所"}
                </Typography>
              </Box>
              <Box>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => historyDialog?.toggle?.(true)}
                  disabled={disabled}
                >
                  {"移動履歴を表示する"}
                </Button>
              </Box>
            </Stack>
            <Box>
              <InformationItem label="最終確認日">
                {formatDisplayDate({ source: value?.latestConfirmedOn })}
              </InformationItem>
            </Box>
            <Box>
              <InformationItem label="最終確認者">
                {value?.latestConfirmerName ?? ""}
              </InformationItem>
            </Box>
            <Box>
              <InformationItem label="設置・保管日">
                {formatDisplayDate({
                  source: value?.installedOn,
                })}
              </InformationItem>
            </Box>
            <Box>
              <InformationItem label="事業者">
                {value?.installationCompanyName ?? ""}
              </InformationItem>
            </Box>
            <Box>
              <InformationItem label="事業場">
                {value?.installationWorkplaceName ?? ""}
              </InformationItem>
            </Box>
            <Box>
              <InformationItem label="住所">
                {formatDisplayAddress(workplace?.data?.getWorkplace)}
              </InformationItem>
            </Box>
          </Stack>
        </Paper>
        <Paper sx={{ p: 2 }}>
          <Stack spacing={2}>
            <Box>
              <Typography
                sx={{
                  textDecoration: "underline",
                  fontSize: "17px",
                }}
              >
                {"基本情報"}
              </Typography>
            </Box>
            <Box>
              <InformationItem label={"管理番号"}>
                {basic?.number ?? ""}
              </InformationItem>
            </Box>
            <Box>
              <InformationItem label={"管理事業場"}>
                {`${basic?.manageWorkplace?.belongInCompanyName ?? ""} ${
                  basic?.manageWorkplace?.name ?? ""
                }`}
              </InformationItem>
            </Box>
            <Box>
              <InformationItem label={"追加日"}>
                {`${formatDisplayDate({ source: basic?.addedOn })} (${
                  basic?.additionalType?.name ?? ""
                })`}
              </InformationItem>
            </Box>
            <Box>
              <InformationItem label={"備考"}>
                {basic?.remarks ?? ""}
              </InformationItem>
            </Box>
            <Box>
              <InformationItem label={"添付ファイル"}>
                <FileGallery value={basic?.files} disabled={true} />
              </InformationItem>
            </Box>
          </Stack>
        </Paper>
      </Stack>
      {value?.id && (
        <MovementHistory
          containerId={value?.id}
          open={historyDialog.open}
          onClose={() => historyDialog.toggle(false)}
        />
      )}
    </>
  );
};
