import { MoreVert } from "@mui/icons-material";
import { Link, IconButton } from "@mui/material";
import {
  createWasteReportingSetting,
  deleteWasteReportingSetting,
  resumeWasteManagementMethod,
  suspendWasteManagementMethod,
  updateWasteReportingSetting,
} from "api/graphql/mutations";
import { getWorkplace, listWasteReportingSettings } from "api/graphql/queries";
import { API, graphqlOperation } from "utils/graphqlOperation";
import { add as addAlert } from "ducks/Alert";
import { DateTime } from "luxon";
import { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { debugLog } from "utils/log";
import { useXGridComponents } from "utils/useXGridComponents";
import { wasteReportingMediaSelector } from "ducks/WasteReportingMedia";
import { useQuery } from "utils/useQuery";
import { FILTER_OPERATORS_CONTAINS_EQUAL } from "utils/constant";

export const Container = ({
  render,
  value,
  flow,
  onNotificationChanged = () => {},
  client,
  ...props
}) => {
  const formRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const { workplaceId, flowId } = useParams();
  const [menuAnchor, setMenuAnchor] = useState(null);
  const [selectedValue, setSelectedValue] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [error, setError] = useState(null);
  const [endDate, setEndDate] = useState(new Date());
  const wasteReportingMedias = useSelector(wasteReportingMediaSelector);
  const dispatch = useDispatch();
  const workplace = useQuery({
    query: getWorkplace,
    variables: {
      id: workplaceId,
    },
  });

  const handleMenuClick = (data) => (event) => {
    setSelectedValue(data);
    setMenuAnchor(event.currentTarget);
  };

  const handleUpdateEnd = (data) => {
    setSelectedValue(data);
    setModalOpen(true);
    setError(null);
  };

  const columns = [
    {
      field: "validityStartOn",
      headerName: "開始日",
      minWidth: 130,
      flex: 1,
      renderCell: (params) => {
        return DateTime.fromISO(params.row.validityStartOn).toFormat(
          "yyyy/MM/dd"
        );
      },
      filterOperators: FILTER_OPERATORS_CONTAINS_EQUAL,
    },
    {
      field: "validityEndOn",
      headerName: "終了日",
      minWidth: 160,
      flex: 1,
      renderCell: (params) => {
        if (params.row.validityEndOn) {
          return DateTime.fromISO(params.row.validityEndOn).toFormat(
            "yyyy/MM/dd"
          );
        } else {
          return (
            <Link
              variant="button"
              component="button"
              onClick={() => handleUpdateEnd(params.row)}
            >
              終了日を指定する
            </Link>
          );
        }
      },
      filterOperators: FILTER_OPERATORS_CONTAINS_EQUAL,
    },
    {
      field: "reportingMediaPaper",
      headerName: "紙マニフェスト",
      minWidth: 180,
      flex: 1,
      renderCell: (params) => {
        const { row } = params;
        return `${row.reportingMedia.includes("紙マニフェスト") ? "○" : "-"}`;
      },
      filterOperators: FILTER_OPERATORS_CONTAINS_EQUAL,
    },
    {
      field: "reportingMediaElectronic",
      headerName: "電子マニフェスト",
      minWidth: 180,
      flex: 1,
      renderCell: (params) => {
        const { row } = params;
        return `${row.reportingMedia.includes("電子マニフェスト") ? "○" : "-"}`;
      },
      filterOperators: FILTER_OPERATORS_CONTAINS_EQUAL,
    },
    {
      field: "reportingMediaOriginal",
      headerName: "オリジナル伝票",
      minWidth: 180,
      flex: 1,
      renderCell: (params) => {
        const { row } = params;
        return `${row.reportingMedia.includes("オリジナル伝票") ? "○" : "-"}`;
      },
      filterOperators: FILTER_OPERATORS_CONTAINS_EQUAL,
    },
    {
      field: "menu",
      headerName: " ",
      sortable: false,
      disableColumnMenu: true,
      minWidth: 60,
      flex: 1,
      renderCell: (params) => {
        return (
          <IconButton onClick={handleMenuClick(params)}>
            <MoreVert />
          </IconButton>
        );
      },
    },
  ];

  const xGridObject = useXGridComponents(columns, listWasteReportingSettings, {
    other: {
      wasteManagementMethodId: flowId,
    },
  });

  const handleStopUseFlow = () => {
    setIsSubmit(true);
    API.graphql(
      graphqlOperation(suspendWasteManagementMethod, {
        input: {
          id: flowId,
        },
      })
    )
      .then((res) => {
        dispatch(
          addAlert({
            value: "利用停止しました。",
            severity: "success",
          })
        );
        onNotificationChanged();
      })
      .catch((err) => {
        debugLog("処理フロー利用停止失敗: ", err);
        dispatch(
          addAlert({
            value: "エラーが発生したため、利用停止できませんでした。",
            severity: "error",
          })
        );
      })
      .finally(() => {
        setIsSubmit(false);
      });
  };

  const handleSubmit = () => {
    formRef.current.submit();
  };

  const handleRegister = (data) => {
    setIsSubmit(true);
    API.graphql(
      graphqlOperation(createWasteReportingSetting, {
        input: {
          wasteManagementMethodId: flowId,
          validityStartOn: DateTime.fromFormat(
            data.validityStartOn,
            "yyyy/MM/dd"
          ).toISODate(),
          validityEndOn: null,
          wasteReportingMediumIds: data.reportingMedia,
        },
      })
    )
      .then((res) => {
        dispatch(
          addAlert({
            value: "登録しました。",
            severity: "success",
          })
        );
        xGridObject.functions.refetch();
        setOpen(false);
      })
      .catch((err) => {
        debugLog("報告方法追加失敗: ", err);
        dispatch(
          addAlert({
            value: "エラーが発生したため、登録できませんでした。",
            severity: "error",
          })
        );
      })
      .finally(() => {
        setIsSubmit(false);
      });
  };

  const handleUpdateReportingSetting = () => {
    setError(null);
    if (!endDate) {
      setError("終了日を入力してください。");
      return;
    }
    setIsSubmit(true);
    API.graphql(
      graphqlOperation(updateWasteReportingSetting, {
        input: {
          id: selectedValue.id,
          validityStartOn: DateTime.fromISO(
            selectedValue.validityStartOn
          ).toISODate(),
          validityEndOn: DateTime.fromJSDate(endDate).toISODate(),
          wasteReportingMediumIds: selectedValue.reportingMedia.map(
            (item) => wasteReportingMedias.find((w) => w.name === item).id
          ),
          expectedVersion: selectedValue.version,
        },
      })
    )
      .then((res) => {
        dispatch(
          addAlert({
            value: "更新しました。",
            severity: "success",
          })
        );
        xGridObject.functions.refetch();
        setSelectedValue(null);
        setEndDate(null);
        setModalOpen(false);
      })
      .catch((err) => {
        debugLog("報告方法更新失敗: ", err);
        dispatch(
          addAlert({
            value: "エラーが発生したため、更新できませんでした。",
            severity: "error",
          })
        );
      })
      .finally(() => {
        setIsSubmit(false);
      });
  };

  const handleDeleteReportingSetting = () => {
    setIsSubmit(true);
    API.graphql(
      graphqlOperation(deleteWasteReportingSetting, {
        input: {
          id: selectedValue.id,
        },
      })
    )
      .then((res) => {
        dispatch(
          addAlert({
            value: "削除しました。",
            severity: "success",
          })
        );
        xGridObject.functions.refetch();
        setSelectedValue(null);
        onNotificationChanged();
      })
      .catch((err) => {
        debugLog("報告方法削除失敗: ", err);
        dispatch(
          addAlert({
            value: "エラーが発生したため、削除できませんでした。",
            severity: "error",
          })
        );
      })
      .finally(() => {
        setIsSubmit(false);
      });
  };

  const handleResumeUseFlow = () => {
    setIsSubmit(true);
    API.graphql(
      graphqlOperation(resumeWasteManagementMethod, {
        input: {
          id: flowId,
        },
      })
    )
      .then((res) => {
        dispatch(
          addAlert({
            value: "利用再開しました。",
            severity: "success",
          })
        );
        onNotificationChanged();
      })
      .catch((err) => {
        debugLog("処理フロー利用再開失敗: ", err);
        dispatch(
          addAlert({
            value: "エラーが発生したため、利用再開できませんでした。",
            severity: "error",
          })
        );
      })
      .finally(() => {
        setIsSubmit(false);
      });
  };

  return render({
    ...props,
    client: client,
    error: error,
    endDate: endDate,
    modalOpen: modalOpen,
    menuAnchor: menuAnchor,
    selectedValue: selectedValue,
    value: value,
    flow: flow,
    open: open,
    onStopUseFlow: handleStopUseFlow,
    onOpen: () => setOpen(true),
    onClose: () => setOpen(false),
    formRef: formRef,
    xGridParams: xGridObject.params,
    isSubmit: isSubmit,
    onSubmit: handleSubmit,
    onRegister: handleRegister,
    onResumeUseFlow: handleResumeUseFlow,
    onDeleteReportingSetting: handleDeleteReportingSetting,
    onUpdateReportingSetting: handleUpdateReportingSetting,
    onCloseMenu: () => setMenuAnchor(null),
    onCloseModal: () => setModalOpen(false),
    onChangeEndDate: (date) => setEndDate(date),
    workplace: workplace?.data?.getWorkplace,
  });
};
