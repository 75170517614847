import { yupResolver } from "@hookform/resolvers/yup";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Container,
  Fade,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useOpener } from "utils/useOpener";
import { DatePicker, TextField } from "views/atoms";
import {
  ClientSelector,
  ClientWorkplaceSelector,
  FullScreenDialog,
  FileGallery,
  InputFieldPopppSelect,
  ModalPopup,
  Uploader,
  XGridComponent,
} from "views/molecules";
import { MaintenanceForm } from "../MaintenanceForm";
import { basicSchema } from "./Validation";

const defaultValues = {
  number: null,
  category: null,
  maintenanceCompany: null,
  maintenanceWorkplace: null,
  maintenancedOn: null,
  price: null,
  remarks: null,
  files: [],
  details: [],
};

/**
 * 整備を入力するコンポーネントです。
 * @param {object} props プロパティ
 * @param {string} title タイトル
 * @param {boolean} open 開閉状態
 * @param {boolean} disabled 無効状態
 * @param {object} value 値
 * @fires MaintenanceRecordForm#onClose 閉じる時
 * @fires MaintenanceRecordForm#onSubmit 送信時
 * @fires MaintenanceRecordForm#onError エラー時
 * @returns {JSX.Element}
 */
export const MaintenanceRecordForm = ({
  title = "新しい整備記録の登録",
  open = false,
  onClose = () => {},
  disabled = false,
  value,
  onSubmit = () => {},
  onError = () => {},
}) => {
  const maintenanceCompaniesSelector = useOpener();
  const maintenanceLocationsSelector = useOpener();
  const maintenanceForm = useOpener();
  const menu = useOpener();
  const handleClickMenu = (row) => (event) => {
    if (!row?.elapsedDays) {
      menu.toggle(true);
      menu.changeOptions({
        anchorEl: event.currentTarget,
        value: row,
      });
    }
  };
  const { trigger, reset, watch, formState, control, setValue } = useForm({
    mode: "onBlur",
    resolver: yupResolver(basicSchema),
    defaultValues: {
      ...defaultValues,
      ...value,
    },
  });

  useEffect(() => {
    if (open) {
      reset({
        ...defaultValues,
        ...value,
      });
    }
  }, [reset, open, value]);

  const handleClickRegister = async () => {
    const result = await trigger();
    if (result) {
      onSubmit && onSubmit(watch());
      onClose();
    } else {
      onError && onError(formState.errors);
    }
  };

  const columns = [
    {
      field: "method",
      headerName: "整備方法",
      flex: 1,
    },
    {
      field: "part",
      headerName: "整備箇所",
      flex: 1,
    },
    {
      field: "content",
      headerName: "作業内容",
      flex: 1,
    },
    {
      field: "quantityValue",
      headerName: "個数",
      flex: 1,
    },
    {
      field: "",
      headerName: "",
      width: 70,
      renderCell: (params) => {
        return (
          <IconButton onClick={handleClickMenu(params?.row)}>
            <MoreVertIcon />
          </IconButton>
        );
      },
    },
  ];

  return (
    <FullScreenDialog
      title={title}
      textConfirm="保存"
      open={open}
      onClose={onClose}
      onClickSubmit={handleClickRegister}
      disabled={disabled}
    >
      <Container>
        <Stack spacing={3}>
          <Paper
            sx={{
              p: 3,
            }}
          >
            <Stack spacing={2}>
              <Box display="flex" flexDirection={"row"} alignItems="center">
                <Avatar
                  sx={{
                    width: 32,
                    height: 32,
                    backgroundColor: "black",
                    mr: 2,
                  }}
                >
                  1
                </Avatar>
                <Typography
                  sx={{
                    fontSize: "18px",
                  }}
                >
                  {"基本項目"}
                </Typography>
              </Box>
              <Box>
                <Controller
                  name={`number`}
                  control={control}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => (
                    <TextField
                      label="管理番号"
                      placeholder="管理番号を入力します。"
                      value={value}
                      onChange={onChange}
                      error={!!error}
                      helperText={error?.message}
                      disabled={disabled}
                    />
                  )}
                />
              </Box>
              <Box>
                <Controller
                  name={`category`}
                  control={control}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => {
                    return (
                      <Autocomplete
                        inputValue={value ?? ""}
                        onInputChange={(event, newValue) => {
                          onChange(newValue);
                        }}
                        freeSolo
                        disableClearable
                        options={[
                          "灯火類",
                          "ブレーキ類の制御装置",
                          "動力伝達装置",
                          "排ガス装置",
                          "その他",
                        ]}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="種別"
                            required={true}
                            error={!!error}
                            helperText={error?.message}
                            InputProps={{
                              ...params.InputProps,
                              type: "search",
                            }}
                          />
                        )}
                      />
                    );
                  }}
                />
              </Box>
              <Box>
                <Controller
                  name={`maintenanceCompany`}
                  control={control}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => {
                    return (
                      <>
                        <InputFieldPopppSelect
                          label={"整備会社"}
                          value={value}
                          required={true}
                          placeholder={"整備会社を選択します。"}
                          error={error}
                          onOpenModal={() =>
                            maintenanceCompaniesSelector.toggle(true)
                          }
                          textConfirm="選択"
                          disableButton={disabled}
                        />
                        <ModalPopup
                          open={maintenanceCompaniesSelector.open}
                          onClose={() =>
                            maintenanceCompaniesSelector.toggle(false)
                          }
                        >
                          <Box pt={3} pb={3} sx={{ height: "85vh" }}>
                            <ClientSelector
                              autoHeight={false}
                              value={value}
                              onSelected={(data) => {
                                onChange(data?.[0]);
                                maintenanceCompaniesSelector.toggle(false);
                                setValue("maintenanceWorkplace", null);
                              }}
                              add={false}
                              selectableOwn={true}
                            />
                          </Box>
                        </ModalPopup>
                      </>
                    );
                  }}
                />
              </Box>
              <Box>
                <Controller
                  name={`maintenanceWorkplace`}
                  control={control}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => {
                    return (
                      <>
                        <InputFieldPopppSelect
                          label={"整備場所"}
                          value={value}
                          required={true}
                          placeholder={"整備場所を選択します。"}
                          error={error}
                          onOpenModal={() =>
                            maintenanceLocationsSelector.toggle(true)
                          }
                          textConfirm="選択"
                          disabledButtom={
                            disabled || !watch("maintenanceCompany")
                          }
                        />
                        <ModalPopup
                          open={maintenanceLocationsSelector.open}
                          onClose={() =>
                            maintenanceLocationsSelector.toggle(false)
                          }
                        >
                          <Box pt={3} pb={3} sx={{ height: "85vh" }}>
                            <ClientWorkplaceSelector
                              visibleCompanyName={false}
                              value={value}
                              onSelected={(items) => {
                                onChange(items?.[0]);
                                maintenanceLocationsSelector.toggle(false);
                              }}
                              fixedOptions={{
                                filter: {
                                  and: [
                                    {
                                      belongInCompanyId: {
                                        eq:
                                          watch("maintenanceCompany")
                                            ?.companyId ||
                                          watch("maintenanceCompany")?.id,
                                      },
                                    },
                                  ],
                                },
                              }}
                            />
                          </Box>
                        </ModalPopup>
                      </>
                    );
                  }}
                />
              </Box>
              <Box>
                <Controller
                  name={`maintenancedOn`}
                  control={control}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => (
                    <DatePicker
                      label="整備年月日"
                      required={true}
                      value={value}
                      onChange={onChange}
                      error={!!error}
                      helperText={error?.message}
                      disabled={disabled}
                    />
                  )}
                />
              </Box>
              <Box>
                <Controller
                  name={`price`}
                  control={control}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => (
                    <TextField
                      label="整備代金"
                      required={true}
                      placeholder="例) 11900"
                      value={value}
                      onChange={onChange}
                      error={!!error}
                      helperText={error?.message}
                      disabled={disabled}
                      InputProps={{
                        endAdornment: "円",
                      }}
                    />
                  )}
                />
              </Box>
              <Box>
                <Controller
                  name={`remarks`}
                  control={control}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => (
                    <TextField
                      label="備考"
                      required={false}
                      placeholder="特記事項があれば入力します。"
                      value={value}
                      onChange={onChange}
                      error={error}
                      rows={3}
                      multiline={true}
                      disabled={disabled}
                      fullWidth
                    />
                  )}
                />
              </Box>
              <Box>
                <Controller
                  name="files"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <>
                      <Uploader
                        disabled={disabled}
                        fullWidth={true}
                        maxSize={26214400}
                        onChange={(files) => {
                          onChange([...(value ?? []), ...files]);
                        }}
                        dropzoneText="最大ファイルサイズ:25MB、ファイル形式:JPEG、PDF"
                        accept={[".pdf", ".jpg", ".jpeg"]}
                      />
                      <FileGallery
                        disabled={disabled}
                        value={value}
                        onChange={onChange}
                      />
                    </>
                  )}
                />
              </Box>
            </Stack>
          </Paper>
          <Paper
            sx={{
              p: 3,
            }}
          >
            <Stack spacing={2}>
              <Box display="flex" flexDirection={"row"} alignItems="center">
                <Avatar
                  sx={{
                    width: 32,
                    height: 32,
                    backgroundColor: "black",
                    mr: 2,
                  }}
                >
                  2
                </Avatar>
                <Typography
                  sx={{
                    fontSize: "18px",
                  }}
                >
                  {"整備内容"}
                </Typography>
              </Box>
              <Box
                display={"flex"}
                flexDirection="row"
                justifyContent={"right"}
              >
                <Button
                  variant="contained"
                  onClick={() => {
                    menu?.changeOptions(null);
                    maintenanceForm?.toggle(true);
                  }}
                >
                  {"整備内容を追加"}
                </Button>
              </Box>
              <Box>
                <Controller
                  name="details"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <>
                      <XGridComponent
                        onChangeSelection={() => {}}
                        checkboxSelection={false}
                        multipleSelection={false}
                        onDownload={false}
                        onFilter={false}
                        columns={columns}
                        onColumns={false}
                        rows={value}
                      />
                      <Menu
                        id="fade-menu"
                        MenuListProps={{
                          "aria-labelledby": "fade-button",
                        }}
                        anchorEl={menu?.options?.anchorEl}
                        open={menu.open}
                        onClose={() => menu?.changeOptions(null)}
                        TransitionComponent={Fade}
                      >
                        <MenuItem
                          disabled={disabled}
                          onClick={() => {
                            menu?.toggle(false);
                            maintenanceForm.toggle(true);
                          }}
                        >
                          {"編集"}
                        </MenuItem>
                        <MenuItem
                          disabled={disabled}
                          onClick={() => {
                            onChange(
                              value.filter(
                                (i) => i.id !== menu?.options?.value?.id
                              )
                            );
                            menu?.toggle(false);
                          }}
                        >
                          {"削除"}
                        </MenuItem>
                      </Menu>
                      <MaintenanceForm
                        open={maintenanceForm.open}
                        onClose={() => maintenanceForm.toggle(false)}
                        onSubmit={(data) => {
                          const result = [...(value ?? [])];
                          if (!!menu?.options?.value) {
                            const index = result.findIndex(
                              (i) => i.id === menu?.options?.value?.id
                            );
                            result.splice(index, 1, data);
                          } else {
                            result.push(data);
                          }
                          onChange(result);
                          maintenanceForm.toggle(false);
                        }}
                        value={menu?.options?.value}
                      />
                    </>
                  )}
                />
              </Box>
            </Stack>
          </Paper>
        </Stack>
      </Container>
    </FullScreenDialog>
  );
};
