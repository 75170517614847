import { useState } from "react";
import { useDispatch } from "react-redux";
import { get } from "lodash";
import { createWorkplace } from "api/graphql/mutations";
import { searchWorkplacesByOffset } from "api/graphql/queries";
import { API, graphqlOperation } from "utils/graphqlOperation";
import { add as addAlert } from "ducks/Alert";
import { EditableWorkplaceStatus } from "utils/Context";
import { PhoneNumberFormatUtil } from "utils/format";
import { useXGridComponents } from "utils/useXGridComponents";
import { FILTER_OPERATORS_CONTAINS_EQUAL } from "utils/constant";

const columns = [
  {
    field: "belongInCompanyName",
    headerName: "事業者",
    minWidth: 200,
    flex: 1,
    filterOperators: FILTER_OPERATORS_CONTAINS_EQUAL,
  },
  {
    field: "name",
    headerName: "名称",
    minWidth: 200,
    flex: 1,
    filterOperators: FILTER_OPERATORS_CONTAINS_EQUAL,
  },
  {
    field: "postalCode",
    headerName: "郵便番号",
    maxWidth: 135,
    minWidth: 135,
    flex: 1,
    filterOperators: FILTER_OPERATORS_CONTAINS_EQUAL,
  },
  {
    field: "prefecturesName",
    headerName: "都道府県",
    valueGetter: (params) => params.row.prefectures?.name,
    minWidth: 135,
    flex: 1,
    filterOperators: FILTER_OPERATORS_CONTAINS_EQUAL,
  },
  {
    field: "city",
    headerName: "市区町村",
    minWidth: 135,
    flex: 1,
    filterOperators: FILTER_OPERATORS_CONTAINS_EQUAL,
  },
  {
    field: "streetAddress",
    headerName: "町名",
    minWidth: 120,
    flex: 1,
    filterOperators: FILTER_OPERATORS_CONTAINS_EQUAL,
  },
  {
    field: "otherAddress",
    headerName: "町名以降",
    minWidth: 135,
    flex: 1,
    filterOperators: FILTER_OPERATORS_CONTAINS_EQUAL,
  },
  {
    field: "email",
    headerName: "メールアドレス",
    minWidth: 180,
    flex: 1,
    hide: true,
    filterOperators: FILTER_OPERATORS_CONTAINS_EQUAL,
  },
  {
    field: "phone",
    headerName: "電話番号",
    minWidth: 135,
    flex: 1,
    valueFormatter: (params) =>
      PhoneNumberFormatUtil.formatNational(params.value),
    filterOperators: FILTER_OPERATORS_CONTAINS_EQUAL,
  },
  {
    field: "fax",
    headerName: "FAX番号",
    minWidth: 135,
    flex: 1,
    hide: true,
    valueFormatter: (params) =>
      PhoneNumberFormatUtil.formatNational(params.value),
    filterOperators: FILTER_OPERATORS_CONTAINS_EQUAL,
  },
];

/**
 * 事業場を選択するコンテナコンポーネントです。
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {object} value 選択値
 * @param {string} title タイトル
 * @fires Container#onChange 変更時
 * @param {object} props その他プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({
  render,
  companyId,
  value,
  onChange = () => {},
  title = "",
  visibleCompanyName = true,
  multiple = false,
  fixedOptions = null,
  initialState = null,
  autoHeight = false,
  ...props
}) => {
  const dispatch = useDispatch();
  const [isSubmit, setIsSubmit] = useState(false);
  const [open, setOpen] = useState(false);
  const xGridObject = useXGridComponents(
    visibleCompanyName === true
      ? columns
      : columns.filter((column) => column.field !== "belongInCompanyName"),
    searchWorkplacesByOffset,
    {
      ...fixedOptions,
      other: {
        belongInCompanyId: companyId,
      },
    },
    {
      ...initialState,
      filter: {
        defaultColumnField: get(columns, "[1].field"),
      },
    }
  );

  const handleCloseDialog = () => {
    setOpen(false);
  };

  const handleOpenDialog = () => {
    setOpen(true);
  };

  const handleChange = (items) => {
    onChange({
      value: xGridObject.params.rows.filter((row) => items.includes(row.id)),
      info: {
        search: xGridObject.search,
      },
    });
  };

  const handleSubmit = (data) => {
    const { categories, industrySector, phone, fax, prefectures, ...other } =
      data;
    setIsSubmit(true);
    API.graphql(
      graphqlOperation(createWorkplace, {
        input: {
          ...other,
          categoryIds: categories?.map((category) => category.id),
          state: EditableWorkplaceStatus.find((item) => item.name === "開店")
            .code,
          phone: phone
            ? PhoneNumberFormatUtil.formatInternational(phone)
            : null,
          fax: fax ? PhoneNumberFormatUtil.formatInternational(fax) : null,
          belongInCompanyId: companyId,
          industrySectorId: industrySector?.id,
          prefecturesCode: prefectures.code,
        },
      })
    )
      .then((res) => {
        dispatch(
          addAlert({
            value: "登録しました。",
            severity: "success",
          })
        );
        xGridObject.functions.refetch();
        handleCloseDialog();
      })
      .catch((err) => {
        dispatch(
          addAlert({
            value: "エラーが発生したため、登録できませんでした。",
            severity: "error",
          })
        );
      })
      .finally(() => {
        setIsSubmit(false);
      });
  };

  return render({
    open: open,
    value: value,
    title: title,
    multiple: multiple,
    isSubmit: isSubmit,
    xGridParams: xGridObject.params,
    onCloseDialog: handleCloseDialog,
    onOpenDialog: handleOpenDialog,
    onChangeSelection: handleChange,
    onSubmit: handleSubmit,
    belongInCompanyId: companyId,
    autoHeight: autoHeight,
    ...props,
  });
};
