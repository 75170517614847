import { searchVehicleMaintenanceRecordsByOffset } from "api/graphql/queries";
import { companySelector } from "ducks/Company";
import { DateTime } from "luxon";
import { get } from "lodash";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  fromQueryString,
  toQueryString,
  useXGridComponents,
} from "utils/useXGridComponents";
import { FILTER_OPERATORS_CONTAINS_EQUAL } from "utils/constant";

const columns = [
  {
    field: "vehicleModelName",
    headerName: "車種",
    minWidth: 170,
    filterOperators: FILTER_OPERATORS_CONTAINS_EQUAL,
    flex: 1,
  },
  {
    field: "vehicleNumber",
    headerName: "車輛ナンバー",
    minWidth: 170,
    flex: 1,
    filterOperators: FILTER_OPERATORS_CONTAINS_EQUAL,
  },
  {
    field: "maintenancedOn",
    headerName: "整備年月日",
    minWidth: 170,
    flex: 1,
    valueFormatter: (params) => {
      return params?.value
        ? DateTime.fromISO(params.value).toFormat("yyyy/MM/dd")
        : "";
    },
    filterOperators: FILTER_OPERATORS_CONTAINS_EQUAL,
  },
  {
    field: "category",
    headerName: "整備種別",
    minWidth: 170,
    flex: 1,
    filterOperators: FILTER_OPERATORS_CONTAINS_EQUAL,
  },
  {
    field: "maintenanceCompanyName",
    headerName: "整備会社",
    minWidth: 170,
    flex: 1,
    filterOperators: FILTER_OPERATORS_CONTAINS_EQUAL,
  },
  {
    field: "price",
    headerName: "整備代金",
    minWidth: 170,
    flex: 1,
    renderCell: (params) =>
      params.value ? `${params.value.toLocaleString()}円` : ``,
    filterable: false,
  },
];

/**
 * 整備記録簿を表示するコンテナコンポーネントです。
 */
export const Container = ({ render, ...props }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const company = useSelector(companySelector);
  const obj = useXGridComponents(
    columns,
    searchVehicleMaintenanceRecordsByOffset,
    {
      other: {
        ownerCompanyId: company?.id,
      },
    },
    {
      sort: {
        direction: "desc",
        field: "maintenancedOn",
      },
      ...fromQueryString(location.search),
      filter: {
        defaultColumnField: get(columns, "[1].field"),
      },
    }
  );

  const handleSelected = (params) => {
    navigate(
      {
        pathname: location.pathname,
        search: toQueryString(obj.search),
      },
      {
        replace: true,
      }
    );

    navigate(`${location.pathname}/${params?.[0]}`);
  };

  return render({
    ...props,
    onSelected: handleSelected,
    obj: obj,
  });
};
