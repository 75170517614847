import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { DateTime } from "luxon";
import {
  searchContractsByOffset,
  getContract,
  searchClientsByOffset,
} from "api/graphql/queries";
import { createContract } from "api/graphql/mutations";
import { add as addAlert } from "ducks/Alert";
import { API, graphqlOperation } from "utils/graphqlOperation";
import { useXGridComponents } from "utils/useXGridComponents";
import { companySelector } from "ducks/Company";
import { useOpener } from "utils/useOpener";
import { debugLog } from "utils/log";
import { FILTER_OPERATORS_CONTAINS_EQUAL } from "utils/constant";

const columns = [
  {
    field: "number",
    headerName: "管理番号",
    width: 150,
    filterOperators: FILTER_OPERATORS_CONTAINS_EQUAL,
  },
  {
    field: "name",
    headerName: "契約書名",
    width: 250,
    filterOperators: FILTER_OPERATORS_CONTAINS_EQUAL,
  },
  {
    field: "partiesA",
    headerName: "契約者（甲）",
    width: 250,
    valueGetter: (params) => params.row.partyCompanies?.[0].name ?? "",
    filterOperators: FILTER_OPERATORS_CONTAINS_EQUAL,
  },
  {
    field: "partiesB",
    headerName: "契約者（乙）",
    width: 250,
    valueGetter: (params) => params.row.partyCompanies?.[1].name ?? "",
    filterOperators: FILTER_OPERATORS_CONTAINS_EQUAL,
  },
  {
    field: "executedOn",
    headerName: "契約日",
    width: 150,
    valueFormatter: (params) =>
      params.value ? DateTime.fromISO(params.value).toFormat("yyyy/MM/dd") : "",
    filterOperators: FILTER_OPERATORS_CONTAINS_EQUAL,
  },
  {
    field: "termOfAgreementStartedOn",
    headerName: "契約期間開始日",
    width: 180,
    valueFormatter: (params) =>
      params.value ? DateTime.fromISO(params.value).toFormat("yyyy/MM/dd") : "",
    filterOperators: FILTER_OPERATORS_CONTAINS_EQUAL,
  },
  {
    field: "termOfAgreementExpirationOn",
    headerName: "契約期間終了日",
    width: 180,
    valueFormatter: (params) =>
      params.value ? DateTime.fromISO(params.value).toFormat("yyyy/MM/dd") : "",
    filterOperators: FILTER_OPERATORS_CONTAINS_EQUAL,
  },
  {
    field: "lastUpdatedAt",
    headerName: "最終更新日時",
    width: 200,
    valueFormatter: (params) =>
      params.value
        ? DateTime.fromISO(params.value, { setZone: "Asia/Tokyo" }).toFormat(
            "yyyy/MM/dd HH:mm:ss"
          )
        : "",
    filterOperators: FILTER_OPERATORS_CONTAINS_EQUAL,
  },
];

const transiteContractPage = (supplierCompanyId, contractId, navigate) => {
  if (!contractId) {
    return;
  }

  API.graphql(
    graphqlOperation(getContract, {
      id: contractId,
    })
  ).then((contract) => {
    if (contract.data.getContract.type.name === "二者契約") {
      const targetCompany = contract.data.getContract.partyCompanies.find(
        (c) => c.id !== contract.data.getContract.ownerCompanyId
      );

      API.graphql(
        graphqlOperation(searchClientsByOffset, {
          supplierCompanyId: supplierCompanyId,
        })
      ).then((clients) => {
        navigate({
          pathname: `/master/partner/${
            clients.data.searchClientsByOffset.items.find(
              (item) => item.companyId === targetCompany.id
            ).id
          }/contract/${contract.data.getContract.id}`,
          search: "?list",
        });
      });
    }
  });
};

/**
 * 契約表示するコンテナコンポーネントです
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({
  render,
  onSelected = (data) => console.log(data),
  onSubmitted = (data) => console.log(data),
  initialState,
  ...props
}) => {
  const xGridObject = useXGridComponents(
    columns,
    searchContractsByOffset,
    {
      other: {},
    },
    initialState
  );
  const [redirecting, setRedirecting] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const company = useSelector(companySelector);
  const navigate = useNavigate();
  const { open, toggle } = useOpener();
  const dispatch = useDispatch();

  const handleOnChangeSeletion = (params) => {
    setRedirecting(true);
    transiteContractPage(company.id, params[0], navigate);
  };

  const onCloseDialog = () => {
    toggle(false);
  };

  const handleGetValueFormContract = (params) => {
    setIsSubmitting(true);
    const {
      number,
      name,
      contractType,
      parties,
      executedOn,
      contractOn,
      isAutomaticallyRenewed,
      files,
      remarks,
    } = params;

    const input = {
      ownerCompanyId: company.id,
      number: number ?? "",
      name: name,
      contractTypeId: contractType.id,
      partyCompanyIds: parties.map((party) => party.companyId),
      executedOn: DateTime.fromJSDate(new Date(executedOn)).toFormat(
        "yyyy-MM-dd"
      ),
      termOfAgreementStartedOn: DateTime.fromJSDate(
        new Date(contractOn.start)
      ).toFormat("yyyy-MM-dd"),
      termOfAgreementExpirationOn: DateTime.fromJSDate(
        new Date(contractOn.end)
      ).toFormat("yyyy-MM-dd"),
      isAutomaticallyRenewed: isAutomaticallyRenewed,
      remarks: remarks ?? "",
      files:
        files?.map((file, index) => ({
          name: file.name,
          base64: file.context,
          order: index,
          label: file.name,
        })) ?? null,
    };

    API.graphql(
      graphqlOperation(createContract, {
        input,
      })
    )
      .then((res) => {
        dispatch(
          addAlert({
            value: "登録しました。",
            severity: "success",
          })
        );
        xGridObject.functions.refetch();
        toggle(false);
      })
      .catch((err) => {
        debugLog(err);
        dispatch(
          addAlert({
            value: "エラーが発生したため、登録できませんでした。",
            severity: "error",
          })
        );
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  return render({
    ...props,
    open: open,
    openAddNewContractDialog: () => toggle(true),
    onCloseDialog: onCloseDialog,
    onChangeSelection: handleOnChangeSeletion,
    xGridParams: xGridObject.params,
    loading: xGridObject.params.loading || redirecting,
    onSubmit: handleGetValueFormContract,
    isSubmitting: isSubmitting,
    formValue: {
      ownerCompanyId: company?.id,
      partnerCompanyId: null,
    },
  });
};
