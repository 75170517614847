import {
  Box,
  ClickAwayListener,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { TruncatedText } from "views/atoms";

/**
 * 車輛を表示・選択するプレゼンテーションコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = (props) => {
  if (props.edit) {
    return (
      <ClickAwayListener onClickAway={props.handleClickAway}>
        <TextField
          select
          onChange={props.onChange}
          fullWidth
          size={props.size || "medium"}
          SelectProps={{
            MenuProps: { disablePortal: true },
            multiple: false,
            value: props.value,
            displayEmpty: true,
          }}
        >
          <MenuItem value="">
            <em>未選択</em>
          </MenuItem>
          {props.vehicles.map((option) => (
            <MenuItem
              key={option.id}
              value={option}
              disabled={props.disabledVehicles
                ?.map((disabled) => disabled.id)
                .includes(option.id)}
            >
              {option.number}
            </MenuItem>
          ))}
        </TextField>
      </ClickAwayListener>
    );
  } else {
    return (
      <Box
        onClick={props.onEdit}
        sx={{
          marginBottom: "8px",
        }}
      >
        {props.value?.number ? (
          <TruncatedText text={props.value.number} maxWidth="100%" />
        ) : (
          <Typography style={{ color: "red" }}>{"車輛未設定"}</Typography>
        )}
      </Box>
    );
  }
};
