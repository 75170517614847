import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { GRID_CHECKBOX_SELECTION_COL_DEF } from "@mui/x-data-grid";
import { API, graphqlOperation } from "utils/graphqlOperation";
import { searchIndividualityWasteCollectionOrdersByOffset } from "api/graphql/queries";
import { deleteIndividualityWasteCollectionOrders } from "api/graphql/mutations";
import { add as addAlert } from "ducks/Alert";
import { useDispatch, useSelector } from "react-redux";
import { debugLog } from "utils/log";
import { useXGridComponents } from "utils/useXGridComponents";
import { companySelector } from "ducks/Company";
import { DateTime } from "luxon";
import { getGridNumericOperators } from "@mui/x-data-grid-pro";
import { FILTER_OPERATORS_CONTAINS_EQUAL } from "utils/constant";
import { useOpener } from "utils/useOpener";

const columns = [
  {
    field: "wasteGeneratorCompanyName",
    headerName: "排出者",
    minWidth: 200,
    flex: 1,
    filterOperators: FILTER_OPERATORS_CONTAINS_EQUAL,
  },
  {
    field: "wasteCollectionWorkplaceName",
    headerName: "排出事業場",
    minWidth: 200,
    flex: 1,
    filterOperators: FILTER_OPERATORS_CONTAINS_EQUAL,
  },
  {
    field: "scheduleDate",
    headerName: "回収日",
    minWidth: 120,
    flex: 1,
    valueFormatter: (params) => {
      return params.value
        ? DateTime.fromISO(params.value).toFormat("yyyy/MM/dd")
        : "";
    },
    filterOperators: FILTER_OPERATORS_CONTAINS_EQUAL,
  },
  {
    field: "scheduleTimeRangeStart",
    headerName: "回収開始時間",
    minWidth: 165,
    flex: 1,
    valueFormatter: (params) => {
      return params.value ? params.value.substring(0, 5) : "";
    },
    hide: true,
    filterOperators: FILTER_OPERATORS_CONTAINS_EQUAL,
  },
  {
    field: "scheduleTimeRangeEnd",
    headerName: "回収終了時間",
    minWidth: 165,
    flex: 1,
    valueFormatter: (params) => {
      return params.value ? params.value.substring(0, 5) : "";
    },
    hide: true,
    filterOperators: FILTER_OPERATORS_CONTAINS_EQUAL,
  },
  {
    field: "wasteLargeClassName",
    headerName: "廃棄物種類大分類",
    minWidth: 200,
    flex: 1,
    hide: false,
    filterOperators: FILTER_OPERATORS_CONTAINS_EQUAL,
  },
  {
    field: "wasteMiddleClassName",
    headerName: "廃棄物種類中分類",
    minWidth: 200,
    flex: 1,
    hide: true,
    filterOperators: FILTER_OPERATORS_CONTAINS_EQUAL,
  },
  {
    field: "wasteSmallClassName",
    headerName: "廃棄物種類小分類",
    minWidth: 200,
    flex: 1,
    hide: true,
    filterOperators: FILTER_OPERATORS_CONTAINS_EQUAL,
  },
  {
    field: "wasteName",
    headerName: "廃棄物の名称",
    minWidth: 180,
    flex: 1,
    filterOperators: FILTER_OPERATORS_CONTAINS_EQUAL,
  },
  {
    field: "wasteQuantityValue",
    type: "number",
    headerName: "数量",
    minWidth: 135,
    flex: 1,
    hide: true,
    filterOperators: getGridNumericOperators().filter(
      (operator) =>
        operator.value === ">" ||
        operator.value === ">=" ||
        operator.value === "<" ||
        operator.value === "<=" ||
        operator.value === "=" ||
        operator.value === "!="
    ),
  },
  {
    field: "wasteQuantityUnitName",
    headerName: "単位",
    minWidth: 120,
    flex: 1,
    hide: true,
    filterOperators: FILTER_OPERATORS_CONTAINS_EQUAL,
  },
  {
    field: "remarks",
    headerName: "備考",
    minWidth: 180,
    flex: 1,
    hide: true,
    filterOperators: FILTER_OPERATORS_CONTAINS_EQUAL,
  },
  {
    field: "createdAt",
    headerName: "登録日時",
    minWidth: 140,
    flex: 1,
    valueFormatter: (params) => {
      return params.value
        ? DateTime.fromISO(params.value, { setZone: "Asia/Tokyo" }).toFormat(
            "yyyy/MM/dd HH:mm:ss"
          )
        : "";
    },
    hide: true,
    filterOperators: FILTER_OPERATORS_CONTAINS_EQUAL,
  },
  {
    field: "updatedAt",
    headerName: "最終更新日時",
    minWidth: 170,
    flex: 1,
    valueFormatter: (params) => {
      return params.value
        ? DateTime.fromISO(params.value, { setZone: "Asia/Tokyo" }).toFormat(
            "yyyy/MM/dd HH:mm:ss"
          )
        : "";
    },
    hide: true,
    filterOperators: FILTER_OPERATORS_CONTAINS_EQUAL,
  },
];

/**
 * スポット回収一覧を表示するコンテナコンポーネントです
 * @param {func} render
 * @returns {JSX.Element}
 */
export const Container = ({
  render,
  value,
  onSelected = (data) => debugLog(data),
  submit = (data) => Promise.resolve(data),
  initialState,
  ...props
}) => {
  const formRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [selectedRows, setSelctedRows] = useState([]);
  const deleteConfirmation = useOpener();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const company = useSelector(companySelector);
  const [anchorEl, setAnchorEl] = useState(null);

  const cols = [
    {
      ...GRID_CHECKBOX_SELECTION_COL_DEF,
      width: 60,
    },
    ...columns,
  ];

  const xGridObject = useXGridComponents(
    cols,
    searchIndividualityWasteCollectionOrdersByOffset,
    null,
    initialState
  );

  const handeChangeSelection = (rows) => {
    setSelctedRows(rows);
  };

  const handleSubmit = (data) => {
    setIsSubmit(true);
    submit(data)
      .then((result) => {
        dispatch(
          addAlert({
            value: "登録しました。",
            severity: "success",
          })
        );
        xGridObject.functions.refetch();
        setOpen(false);
      })
      .catch((err) => {
        debugLog("Order.Spot.submit.error: ", err);
        dispatch(
          addAlert({
            value: "エラーが発生したため、登録できませんでした。",
            severity: "error",
          })
        );
      })
      .finally(() => {
        setIsSubmit(false);
      });
  };

  const handleRegister = () => {
    formRef.current.submit();
  };

  const handleActionClick = (action) => {
    if (action === "delete") {
      deleteConfirmation.toggle(true);
    }
  };

  const deleteRecords = async (ids) => {
    return await API.graphql(
      graphqlOperation(deleteIndividualityWasteCollectionOrders, {
        input: { individualcollectionIds: ids, ownerCompanyId: company.id },
      })
    );
  };

  const onDelete = () => {
    setIsSubmit(true);
    deleteRecords(selectedRows)
      .then((response) => {
        const collectionWasteId =
          response.data.deleteIndividualityWasteCollectionOrders;
        dispatch(
          addAlert({
            value: "削除しました。",
            severity: "success",
          })
        );
        const rows = xGridObject.params.rows.filter(
          (item) => !collectionWasteId.includes(item.collectionWasteId)
        );
        xGridObject.functions.setRows({
          rows,
        });
        deleteConfirmation.toggle(false);
      })
      .catch(() => {
        dispatch(
          addAlert({
            value: "エラーが発生したため、削除できませんでした。",
            severity: "error",
          })
        );
      })
      .finally(() => {
        setIsSubmit(false);
      });
  };

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  return render({
    open: open,
    onCloseDialog: () => setOpen(false),
    onOpenDialog: () => setOpen(true),
    onSubmit: handleSubmit,
    columns: columns,
    value: value,
    onChangeSelection: handeChangeSelection,
    formRef: formRef,
    onRegister: handleRegister,
    isSubmit: isSubmit,
    xGridParams: xGridObject.params,
    navigate: navigate,
    selectedRows: selectedRows,
    handleActionClick: handleActionClick,
    deleteConfirmation: deleteConfirmation,
    onDelete: onDelete,
    handlePopoverOpen: handlePopoverOpen,
    handlePopoverClose: handlePopoverClose,
    anchorEl: anchorEl,
    ...props,
  });
};
