import React from "react";
import { Box, Typography, Grid, Stack } from "@mui/material";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { WarningOutlined } from "@mui/icons-material";
import Pagination from "@mui/material/Pagination";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import LoadingButton from "@mui/lab/LoadingButton";
import { ConfirmDialog } from "views/molecules";

import { FullScreenDialog } from "views/molecules";
import {
  CarryWorkJournalListItem,
  DispatchRoute,
} from "views/organisms/Allocation";

/**
 * 配車表の表示を行うプレゼンテーションコンポーネントです
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = (props) => {
  return (
    <Box>
      {props.unsetMessage && (
        <Typography
          color="secondary"
          sx={{ display: "flex", alignItems: "center", mb: 2 }}
        >
          <WarningOutlined fontSize="large" color="secondary" sx={{ mr: 1 }} />
          ドライバーまたは車輛が未設定のコースがあります。
        </Typography>
      )}

      {!props.openItemId && props.items?.done?.courses?.length ? (
        <Box boxShadow={3} p={3} borderRadius={1} marginBottom={2}>
          <DragDropContext
            onDragEnd={props.onDragEnd}
            onBeforeDragStart={props.onBeforeDragStart}
          >
            <Grid container justifyContent="end" spacing={2}>
              <Grid
                item
                xs={3}
                sx={{
                  marginRight: 2,
                }}
                textAlign="center"
              >
                <Typography fontWeight="bold">配⾞数</Typography>
              </Grid>
            </Grid>
            <Box
              flexGrow={1}
              ref={props.droppableContainer}
              height={props.draggingStartHeight || "auto"}
            >
              <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={{
                      ...props.getCoursesListStyle(snapshot.isDraggingOver),
                      height: props.draggingStartHeight || "auto",
                    }}
                  >
                    {props.items?.done.courses?.map((course, i) => (
                      <Draggable
                        key={course.id}
                        draggableId={course.id}
                        index={i}
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={props.getCourseItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style
                            )}
                            onClick={() => props.handleSelectCourse(course.id)}
                            key={i}
                          >
                            <Grid
                              container
                              spacing={2}
                              justifyContent="flex-start"
                            >
                              <Grid item flex="1 0 83%">
                                <CarryWorkJournalListItem
                                  name={course.name}
                                  assignedUsers={course.assignedUsers}
                                  assignedVehicle={course.assignedVehicle}
                                  clickDisabled
                                ></CarryWorkJournalListItem>
                              </Grid>
                              <Grid
                                item
                                flex="0 0 10%"
                                justifyContent="center"
                                display="flex"
                                alignItems="center"
                              >
                                <Typography fontSize={15} fontWeight={600}>
                                  {course?.points?.length ||
                                    course.pointTotal ||
                                    0}
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                flex="0 0 7%"
                                justifyContent="center"
                                display="flex"
                                alignItems="center"
                                sx={{
                                  cursor: "pointer",
                                }}
                              >
                                <IconButton
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    props.setDeletingCourseId(course.id);
                                    props.deleteConfirmation.toggle(true);
                                  }}
                                  aria-label="delete"
                                  disabled={
                                    props.isLoadingUnAssigned ||
                                    props.isUpdatingCourses
                                  }
                                >
                                  <DeleteIcon color="error" />
                                </IconButton>
                              </Grid>
                            </Grid>
                          </div>
                        )}
                      </Draggable>
                    ))}
                  </div>
                )}
              </Droppable>
            </Box>
            <Grid
              item
              width="90%"
              mt={2}
              display="flex"
              justifyContent="space-between"
            >
              <Stack display="flex" flexDirection="row">
                <LoadingButton
                  sx={{
                    marginLeft: 3,
                  }}
                  loading={props.isUpdatingCourses}
                  variant="contained"
                  onClick={props.handleUpdateCoursesOrder}
                  disabled={!props.isSequenceChanged || props.isUpdatingCourses}
                >
                  更新(並び順)
                </LoadingButton>
                <LoadingButton
                  sx={{
                    marginLeft: 3,
                  }}
                  loading={props.isUpdatingCourses}
                  variant="outlined"
                  color="error"
                  onClick={props.handleSequenceChangeCancel}
                  disabled={!props.isSequenceChanged || props.isUpdatingCourses}
                >
                  取消
                </LoadingButton>
              </Stack>
              <LoadingButton
                onClick={props.handleAddNewCourse}
                variant="outlined"
                disabled={props.isUpdatingCourses || props.isSequenceChanged}
              >
                ＋ 新しい配車リストを追加する
              </LoadingButton>
            </Grid>
            <Box ref={props.errorRef}>
              {props.error && (
                <Typography mt={1} ml={3} color="error" fontSize={12}>
                  {props.error}
                </Typography>
              )}
            </Box>
          </DragDropContext>
        </Box>
      ) : null}

      {!props.openItemId &&
        props.items?.done?.id &&
        !props.items?.done?.courses?.length && (
          <Grid
            item
            width="100%"
            mt={2}
            display="flex"
            justifyContent="flex-end"
          >
            <LoadingButton
              onClick={props.handleAddNewCourse}
              variant="outlined"
              disabled={props.isUpdatingCourses}
            >
              ＋ 新しい配車リストを追加する
            </LoadingButton>
          </Grid>
        )}

      <FullScreenDialog
        open={props.openedCourseId}
        textConfirm="保存"
        action={
          <Pagination
            sx={{
              width: "50%",
              "& .MuiPaginationItem-root": {
                color: "#fff",
                border: "1px solid #5F76B9",
              },
            }}
            page={props.page}
            onChange={props.handlePageChange}
            count={props.items?.done?.courses?.length}
            variant="outlined"
            color="white"
            disabled={props.isSubmitting || props.isPointsLoading}
          />
        }
        title={props.openedDialogueTitle}
        onClickSubmit={props.handleUpdateCoursesFromDialogue}
        onClose={props.handleCloseDialog}
        container={false}
        disabled={props.isSubmitting || props.isPointsLoading}
        closeDisabled={props.isSubmitting || props.isPointsLoading}
        isSubmit={props.isSubmitting}
      >
        {props?.openedCourseId && <DispatchRoute {...props} />}
      </FullScreenDialog>
      <ConfirmDialog
        title="削除確認"
        message="このコースを削除すると、それに関連付けられたすべてのポイントと収集サイクルが未割り当てのセクションに移動されます。この操作は元に戻せません。続行してもよろしいですか?"
        open={props.deleteConfirmation.open}
        positiveText="はい"
        negativeText="いいえ"
        onPositive={() => props.handleDeleteCourse(props.deletingCourseId)}
        disabled={props.loadingUnAssigned}
        onNegative={() => {
          props.setDeletingCourseId(null);
          props.deleteConfirmation.toggle(false);
        }}
        showProgress={props.loadingUnAssigned}
      ></ConfirmDialog>
    </Box>
  );
};
