import { yupResolver } from "@hookform/resolvers/yup";
import { useImperativeHandle, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useOpener } from "utils/useOpener";
import { basicSchema } from "./Validations";
import { companySelector } from "ducks/Company";
import { useSelector } from "react-redux";

/**
 * コンテナ再設置フォームを表示するコンテナコンポーネントです。
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {object} props その他プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({
  render,
  onSubmit = () => {},
  onError = () => {},
  disabled = false,
  info,
  ...props
}) => {
  const containerSelectorForm = useOpener();
  const installationWorkplaceSelector = useOpener();
  const vehicleSelectorForm = useOpener();
  const company = useSelector(companySelector);

  const { trigger, getValues, control, formState, watch, setValue } = useForm({
    mode: "onChange",
    resolver: yupResolver(basicSchema),
    defaultValues: {
      installationWorkplace: null,
      shippedOn: null,
      installedOn: null,
      vehicle: null,
      personInChargeNameharge: "",
      remarks: "",
    },
  });

  const [shippedOn, installedOn] = watch(["shippedOn", "installedOn"]);

  useEffect(() => {
    if (!!shippedOn && !watch("installedOn")) {
      setValue("installedOn", shippedOn, {
        shouldValidate: true,
        shouldDirty: true,
      });
    }
  }, [watch, setValue, shippedOn]);

  useEffect(() => {
    if (!!installedOn && !watch("shippedOn")) {
      setValue("shippedOn", installedOn, {
        shouldValidate: true,
        shouldDirty: true,
      });
    }
  }, [watch, setValue, installedOn]);

  useImperativeHandle(props._ref, () => ({
    submit: () => {
      trigger().then((res) => {
        if (res) {
          onSubmit(getValues());
        } else {
          onError(formState.errors);
        }
      });
    },
    get: () => getValues(),
  }));

  return render({
    ...props,
    control: control,
    disabled: disabled,
    containerSelectorForm: containerSelectorForm,
    installationWorkplaceSelector: installationWorkplaceSelector,
    vehicleSelectorForm: vehicleSelectorForm,
    company: company,
    info: info,
    watch: watch,
    setValue: setValue,
    trigger: trigger,
  });
};
