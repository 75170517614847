import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Container, Grid, Paper } from "@mui/material";
import { companySelector } from "ducks/Company";
import { useImperativeHandle, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { TextField } from "views/atoms";
import {
  DatePicker,
  FileGallery,
  InputField,
  InputFieldPopppSelect,
  ModalPopup,
  Uploader,
  WorkplaceSelector,
} from "views/molecules";
import { basicSchema } from "./Validations";
import { toFormValue } from "utils/ImageFunctions";
import { useEffect } from "react";
import { DateTime } from "luxon";
import { Selector as ContainerAdditionalTypesSelector } from "ducks/ContainerAdditionalTypes/Components";

export const EditForm = ({
  value,
  onSubmit = () => {},
  onError = () => {},
  disabled = false,
  _ref,
}) => {
  const company = useSelector(companySelector);
  const [open, setOpen] = useState(false);
  const { control, trigger, formState, getValues, reset } = useForm({
    mode: "onBlur",
    resolver: yupResolver(basicSchema),
  });

  useEffect(() => {
    toFormValue({ files: value?.files }).then((res) => {
      reset({
        remarks: value?.remarks,
        number: value?.number,
        addedOn: DateTime.fromISO(value?.addedOn).toJSDate(),
        additionalTypes: value?.additionalType,
        manageWorkplace: value?.manageWorkplace,
        files: res,
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useImperativeHandle(_ref, () => ({
    submit: () => {
      trigger().then((res) => {
        if (res) {
          onSubmit({
            ...getValues(),
            version: value.version,
          });
        } else {
          onError(formState.errors);
        }
      });
    },
  }));

  return (
    <Container maxWidth="md">
      <Paper
        sx={{
          p: 4,
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Controller
              name="manageWorkplace"
              control={control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => (
                <>
                  <InputFieldPopppSelect
                    label={"管理事業場"}
                    value={value}
                    onChange={onChange}
                    required={true}
                    placeholder={"管理事業場を選択します。"}
                    error={error}
                    onOpenModal={() => setOpen(true)}
                    textConfirm="選択"
                    disabledButtom={disabled}
                  />
                  <ModalPopup open={open} onClose={() => setOpen(false)}>
                    <Box pt={3} pb={3} sx={{ height: "85vh" }}>
                      <WorkplaceSelector
                        value={value}
                        companyId={company.id}
                        onChange={(e) => {
                          onChange(e.value?.[0]);
                          setOpen(false);
                        }}
                      />
                    </Box>
                  </ModalPopup>
                </>
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="additionalTypes"
              control={control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => (
                <ContainerAdditionalTypesSelector
                  label="追加種別"
                  required={true}
                  value={value}
                  onChange={onChange}
                  error={error}
                  disabled={disabled}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              control={control}
              name="addedOn"
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => (
                <DatePicker
                  label="追加日"
                  required={true}
                  value={value}
                  onChange={onChange}
                  error={error}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              control={control}
              name="number"
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => (
                <TextField
                  label="管理番号"
                  required={true}
                  placeholder="管理番号を入力します。"
                  value={value}
                  onChange={onChange}
                  error={!!error?.number}
                  helperText={error?.number?.message}
                  fullWidth
                  disabled={disabled}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="remarks"
              control={control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => (
                <InputField
                  label="備考"
                  value={value}
                  onChange={onChange}
                  error={error}
                  multiline={true}
                  rows={3}
                  placeholder="特記事項があれば記載します。"
                  disabled={disabled}
                />
              )}
            />
          </Grid>
          <Grid item sx={12}>
            <Controller
              name="files"
              control={control}
              render={({ field: { value, onChange } }) => (
                <>
                  <Uploader
                    disabled={disabled}
                    fullWidth={true}
                    maxSize={26214400}
                    onChange={(files) => {
                      onChange([...(value ?? []), ...files]);
                    }}
                    dropzoneText="最大ファイルサイズ:25MB、ファイル形式:JPEG、PDF"
                    accept={[".pdf", ".jpg", ".jpeg"]}
                  />
                  <FileGallery
                    disabled={disabled}
                    value={value}
                    onChange={onChange}
                  />
                </>
              )}
            />
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
};
