import { Box, Container, Grid, Paper, Stack } from "@mui/material";
import { Controller } from "react-hook-form";
import { DatePicker, TextField } from "views/atoms";
import {
  ClientWorkplaceSelector,
  InputFieldPopppSelect,
  ModalPopup,
  VehicleSelector,
} from "views/molecules";
import { Workplaces } from "../Workplaces";

/**
 * コンテナ撤去入力フォームを表示するプレゼンテーションコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = (props) => {
  return (
    <Container maxWidth="md">
      <Stack spacing={4}>
        <Paper sx={{ p: 3 }}>
          <Workplaces value={props.info} />
        </Paper>
        <Paper sx={{ p: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Controller
                name={`storageWorkplace`}
                control={props.control}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => {
                  return (
                    <>
                      <InputFieldPopppSelect
                        label={"撤去後の保管場所"}
                        value={value}
                        required={true}
                        placeholder={"保管場所を選択します。"}
                        error={error}
                        onOpenModal={() => {
                          props.setValue("storageWorkplace", null);
                          props.clientWorkplaceSelectorForm.toggle(true);
                        }}
                        textConfirm="選択"
                        disabledButtom={props.disabled}
                      />
                      <ModalPopup
                        open={props.clientWorkplaceSelectorForm.open}
                        onClose={() =>
                          props.clientWorkplaceSelectorForm.toggle(false)
                        }
                      >
                        <Box pt={3} pb={3} sx={{ height: "85vh" }}>
                          <ClientWorkplaceSelector
                            companyId={props.company.id}
                            value={value}
                            onSelected={(items) => {
                              onChange(items?.[0]);
                              props.clientWorkplaceSelectorForm.toggle(false);
                            }}
                            fixedOptions={{
                              filter: {
                                and: [
                                  {
                                    categoryId: {
                                      eq: "containerStorage",
                                    },
                                  },
                                ],
                              },
                            }}
                          />
                        </Box>
                      </ModalPopup>
                    </>
                  );
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name={`removedOn`}
                control={props.control}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => (
                  <DatePicker
                    label="撤去日"
                    required={true}
                    value={value}
                    onChange={(event) => {
                      onChange(event);
                      props.trigger("storedOn");
                    }}
                    minDate={props?.info?.installedOn || null}
                    maxDate={new Date()}
                    error={!!error}
                    helperText={error?.message}
                    disabled={props.disabled}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name={`storedOn`}
                control={props.control}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => {
                  return (
                    <DatePicker
                      label="移動終了日"
                      required={true}
                      value={value}
                      onChange={(event) => {
                        onChange(event);
                        props.trigger("removedOn");
                      }}
                      minDate={props?.info?.installedOn || null}
                      maxDate={new Date()}
                      error={!!error}
                      helperText={error?.message}
                      disabled={props.disabled}
                    />
                  );
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name={`vehicle`}
                control={props.control}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => {
                  return (
                    <>
                      <InputFieldPopppSelect
                        label={"車輛"}
                        value={{ name: value?.number }}
                        onChange={onChange}
                        required={false}
                        placeholder={"車輛を選択します。"}
                        error={error}
                        onOpenModal={() =>
                          props.vehicleSelectorForm.toggle(true)
                        }
                        textConfirm="選択"
                        disabledButtom={props.disabled}
                      />
                      <ModalPopup
                        open={props.vehicleSelectorForm.open}
                        onClose={() => props.vehicleSelectorForm.toggle(false)}
                      >
                        <Box pt={3} pb={3} sx={{ height: "85vh" }}>
                          <VehicleSelector
                            value={value}
                            companyId={props?.company?.id}
                            onSelected={(e) => {
                              onChange(e?.value?.[0]);
                              props.vehicleSelectorForm.toggle(false);
                            }}
                          />
                        </Box>
                      </ModalPopup>
                    </>
                  );
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name={`personInChargeName`}
                control={props.control}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => (
                  <TextField
                    label="実施担当者"
                    required={false}
                    placeholder="実施担当者を入力します。"
                    value={value}
                    onChange={onChange}
                    error={error}
                    disabled={props.disabled}
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name={`remarks`}
                control={props.control}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => (
                  <TextField
                    label="備考"
                    required={false}
                    placeholder="特記事項があれば入力します。"
                    value={value}
                    onChange={onChange}
                    error={error}
                    rows={3}
                    multiline={true}
                    disabled={props.disabled}
                    fullWidth
                  />
                )}
              />
            </Grid>
          </Grid>
        </Paper>
      </Stack>
    </Container>
  );
};
