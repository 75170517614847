import { DateTime } from "luxon";
import { useState } from "react";
import { get } from "lodash";
import { createContract } from "api/graphql/mutations";
import { searchContractsByOffset } from "api/graphql/queries";
import { API, graphqlOperation } from "utils/graphqlOperation";
import { add as addAlert } from "ducks/Alert";
import { companySelector } from "ducks/Company";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, createSearchParams } from "react-router-dom";
import { debugLog } from "utils/log";
import useClasses from "utils/useClasses";
import { useXGridComponents } from "utils/useXGridComponents";
import { useStyles } from "./styles";
import { toQueryString, fromQueryString } from "utils/useXGridComponents";
import { FILTER_OPERATORS_CONTAINS_EQUAL } from "utils/constant";

const columns = [
  {
    field: "number",
    headerName: "管理番号",
    width: 150,
    filterOperators: FILTER_OPERATORS_CONTAINS_EQUAL,
  },
  {
    field: "name",
    headerName: "名称",
    width: 250,
    filterOperators: FILTER_OPERATORS_CONTAINS_EQUAL,
  },

  {
    field: "executedOn",
    headerName: "契約日",
    width: 150,
    valueFormatter: (params) =>
      params.value ? DateTime.fromISO(params.value).toFormat("yyyy/MM/dd") : "",
    filterOperators: FILTER_OPERATORS_CONTAINS_EQUAL,
  },
  {
    field: "termOfAgreementStartedOn",
    headerName: "契約期間開始日",
    width: 180,
    valueFormatter: (params) =>
      params.value ? DateTime.fromISO(params.value).toFormat("yyyy/MM/dd") : "",
    filterOperators: FILTER_OPERATORS_CONTAINS_EQUAL,
  },
  {
    field: "termOfAgreementExpirationOn",
    headerName: "契約期間終了日",
    width: 180,
    valueFormatter: (params) =>
      params.value ? DateTime.fromISO(params.value).toFormat("yyyy/MM/dd") : "",
    filterOperators: FILTER_OPERATORS_CONTAINS_EQUAL,
  },
  {
    field: "isAutomaticallyRenewed",
    headerName: "自動更新",
    valueGetter: (params) =>
      params.row.isAutomaticallyRenewed ? "あり" : "なし",
    width: 150,
    filterable: false,
  },
  {
    field: "lastUpdatedAt",
    headerName: "最終更新日時",
    width: 200,
    valueFormatter: (params) =>
      params.value
        ? DateTime.fromISO(params.value, { setZone: "Asia/Tokyo" }).toFormat(
            "yyyy/MM/dd HH:mm:ss"
          )
        : "",
    filterable: false,
  },
];

/**
 * 事業場の契約タブの表示を行うコンテナコンポーネントです
 * @param {object} value 事業場の情報
 * @returns {JSX.Element}
 */
export const Container = ({ render, value, ...props }) => {
  const classes = useClasses(useStyles);
  const navigate = useNavigate();
  const location = useLocation();
  const company = useSelector(companySelector);
  const [open, setOpen] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);

  const xGridObject = useXGridComponents(
    columns,
    searchContractsByOffset,
    {
      filter: {
        and: [
          {
            partyCompanyId: {
              eq: value?.companyId,
            },
          },
        ],
      },
      other: {
        ownerCompanyId: company?.id,
      },
    },
    {
      sort: {
        direction: "asc",
        field: "termOfAgreementExpirationOn",
      },
      ...fromQueryString(location.search),
      filter: {
        defaultColumnField: get(columns, "[1].field"),
      },
    }
  );

  const dispatch = useDispatch();

  const handleSelectionClick = (params) => {
    navigate({
      pathname: location.pathname,
      search: `?${createSearchParams({
        ...Object.fromEntries(new URLSearchParams(location.search)),
        ...Object.fromEntries(
          new URLSearchParams(toQueryString(xGridObject.search))
        ),
      }).toString()}`,
    });

    navigate(`${location.pathname}/contract/${params[0]}`);
  };

  const handleGetValueFormContract = (params) => {
    setIsSubmit(true);
    const {
      number,
      name,
      contractType,
      parties,
      executedOn,
      contractOn,
      isAutomaticallyRenewed,
      files,
      remarks,
    } = params;
    API.graphql(
      graphqlOperation(createContract, {
        input: {
          ownerCompanyId: company.id,
          number: number ?? "",
          name: name,
          contractTypeId: contractType.id,
          partyCompanyIds: parties.map((party) => party.companyId),
          executedOn: DateTime.fromJSDate(new Date(executedOn)).toFormat(
            "yyyy-MM-dd"
          ),
          termOfAgreementStartedOn: DateTime.fromJSDate(
            new Date(contractOn.start)
          ).toFormat("yyyy-MM-dd"),
          termOfAgreementExpirationOn: DateTime.fromJSDate(
            new Date(contractOn.end)
          ).toFormat("yyyy-MM-dd"),
          isAutomaticallyRenewed: isAutomaticallyRenewed,
          remarks: remarks ?? "",
          files:
            files?.map((file, index) => ({
              name: file.name,
              base64: file.context,
              order: index,
              label: file.name,
            })) ?? null,
        },
      })
    )
      .then((res) => {
        dispatch(
          addAlert({
            value: "登録しました。",
            severity: "success",
          })
        );
        xGridObject.functions.refetch();
        setOpen(false);
      })
      .catch((err) => {
        debugLog(err);
        dispatch(
          addAlert({
            value: "エラーが発生したため、登録できませんでした。",
            severity: "error",
          })
        );
      })
      .finally(() => {
        setIsSubmit(false);
      });
  };

  return render({
    ...props,
    xGridParams: xGridObject.params,
    classes: classes,
    formValue: {
      ownerCompanyId: company?.id,
      partnerCompanyId: value?.companyId,
    },
    onChangeSelection: handleSelectionClick,
    onSubmit: handleGetValueFormContract,
    open: open,
    onOpenDialog: () => setOpen(true),
    onCloseDialog: () => setOpen(false),
    isSubmit: isSubmit,
  });
};
