import { add as addAlert } from "ducks/Alert";
import { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { debugLog } from "utils/log";
import { useOpener } from "utils/useOpener";
import useClasses from "utils/useClasses";
import { useStyles } from "./styles";

/**
 * スポット回収詳細情報を表示するコンテナコンポーネントです。
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {object} value 値
 * @param {Promise} submit 送信処理
 * @param {object} props その他プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({
  render,
  value,
  submit = () => Promise.resolve(),
  exeDelete = () => Promise.resolve(),
  ...props
}) => {
  const navigate = useNavigate();
  const classes = useClasses(useStyles);
  const [open, setOpen] = useState(false);
  const formRef = useRef(null);
  const [isSubmit, setIsSubmit] = useState(false);
  const dispatch = useDispatch();
  const dialog = useOpener();

  const handleDelete = (params) => {
    setIsSubmit(true);
    exeDelete(value)
      .then((result) => {
        dispatch(
          addAlert({
            value: "削除しました。",
            severity: "success",
          })
        );
        navigate(`/order/spot`);
      })
      .catch((err) => {
        debugLog("Order.SpotDetails.error", err);
        dispatch(
          addAlert({
            value: "エラーが発生したため、削除できませんでした。",
            severity: "error",
          })
        );
      })
      .finally(() => {
        setIsSubmit(false);
      });
  };

  const handleSubmit = (params) => {
    setIsSubmit(true);
    submit(params)
      .then((result) => {
        dispatch(
          addAlert({
            value: "登録しました。",
            severity: "success",
          })
        );
        setOpen(false);
      })
      .catch((err) => {
        debugLog("Order.SpotDetails.error", err);
        dispatch(
          addAlert({
            value: "エラーが発生したため、登録できませんでした。",
            severity: "error",
          })
        );
      })
      .finally(() => {
        setIsSubmit(false);
      });
  };

  const handleRegister = () => {
    formRef.current.submit();
  };

  return render({
    classes: classes,
    open: open,
    onCloseDialog: () => setOpen(false),
    onOpenDialog: () => setOpen(true),
    value: value,
    onSubmit: handleSubmit,
    onRegister: handleRegister,
    formRef: formRef,
    isSubmit: isSubmit,
    onDelete: handleDelete,
    dialog: dialog,
    ...props,
  });
};
