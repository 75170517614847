import React, { useState, useEffect, useMemo } from "react";
import { DateTime } from "luxon";
import { useDispatch, useSelector } from "react-redux";
import { DataGridPro } from "@mui/x-data-grid-pro";
import {
  Box,
  Typography,
  Stack,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { WarningOutlined } from "@mui/icons-material";

import {
  automationsSliceSelector,
  fetchUnassignedOrders,
  fetchTrashedOrders,
} from "ducks/Automation";
import { If } from "views/atoms";

const size = 10;

const columns = [
  {
    field: "no",
    headerName: "No",
    width: 100,
    renderCell: (params) => {
      const apiRef = params.api;
      const index = apiRef.getRowIndex(params.row.id);
      return (
        <div
          style={{
            textAlign: "center",
            width: "100%",
          }}
        >
          <span>{index + 1}</span>
        </div>
      );
    },
  },
  {
    field: "cycleStartDate",
    headerName: "回収開始日",
    width: 160,
    valueFormatter: (params) => {
      return DateTime.fromFormat(params.value, "yyyy-MM-dd").toFormat(
        "yyyy/MM/dd"
      );
    },
  },
  {
    field: "times",
    headerName: "回収時間",
    width: 150,
    valueGetter: (params) => {
      return `${
        params.row?.scheduleTimeRangeStart
          ? params.row?.scheduleTimeRangeStart.substring(0, 5)
          : ""
      } ～ ${
        params.row?.scheduleTimeRangeEnd
          ? params.row?.scheduleTimeRangeEnd.substring(0, 5)
          : ""
      }`;
    },
  },
  {
    field: "destinationWorkplace",
    headerName: "排出事業場",
    minWidth: 150,
    flex: 1,
    valueGetter: (params) => {
      return `${params.row?.wasteGeneratorCompany?.name} ${params.row?.wasteCollectionWorkplace?.name}`;
    },
  },
  {
    field: "wasteType",
    headerName: "廃棄物の種類",
    minWidth: 150,
    flex: 1,
    valueGetter: (params) => {
      const { row } = params;
      const { waste } = row;
      return `${
        waste?.type?.smallClass?.name ||
        waste?.type?.middleClass?.name ||
        waste?.type?.largeClass?.name ||
        ""
      }`;
    },
  },
  {
    field: "wasteName",
    headerName: "廃棄物の名称",
    flex: 1,
    minWidth: 150,
    valueGetter: (params) => {
      const { row } = params;
      const { waste } = row;
      return `${waste?.name}`;
    },
  },
  {
    field: "quantity",
    headerName: "排出量",
    flex: 1,
    valueGetter: (params) => {
      const { row } = params;
      const { waste } = row;
      return (
        row?.wasteQuantity &&
        `${row?.wasteQuantity} ${waste?.quantityUnit?.name}`
      );
    },
  },
];

/**
 * 未配車の回収スケジュールを表示するコンテナコンポーネントです。
 * @returns {JSX.Element}
 */
const NotDispatchVehicles = () => {
  const [page, setPage] = useState(0);
  const { data, unAssignedPagination, isUnassignedLoading, trashedPagination } =
    useSelector(automationsSliceSelector);
  const [rowCountState, setRowCountState] = useState(
    unAssignedPagination?.total || 0
  );
  const [isDeletedOnly, setIsDeletedOnly] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (page > 0 && Math.ceil(data?.still?.length / size) === page) {
      const params = {
        limit: isDeletedOnly
          ? trashedPagination.limit
          : unAssignedPagination.limit,
        offset: isDeletedOnly
          ? trashedPagination.offset
          : unAssignedPagination.offset,
        filter: {
          and: [
            {
              assigned: { eq: false },
            },
            {
              trashed: { eq: isDeletedOnly },
            },
          ],
        },
      };
      dispatch(
        isDeletedOnly
          ? fetchTrashedOrders(params)
          : fetchUnassignedOrders(params)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const dataRows = useMemo(() => {
    if (isDeletedOnly) {
      return data?.trashed?.map((item, index) => {
        return {
          ...item,
          id: index + 1,
        };
      });
    }
    return data?.still?.map((item, index) => {
      return {
        ...item,
        id: index + 1,
      };
    });
  }, [data, isDeletedOnly]);

  useEffect(() => {
    setRowCountState((prevRowCountState) =>
      isDeletedOnly
        ? trashedPagination?.total
        : unAssignedPagination?.total !== undefined
        ? unAssignedPagination?.total
        : prevRowCountState
    );
  }, [
    unAssignedPagination?.total,
    setRowCountState,
    trashedPagination?.total,
    isDeletedOnly,
  ]);

  return (
    <Box boxShadow={3} p={3} borderRadius={1} marginBottom={2}>
      <Stack
        direction="row"
        justifyContent={isDeletedOnly ? "flex-end" : "space-between"}
      >
        <Typography
          color="secondary"
          sx={{ display: "flex", alignItems: "center", mb: 2, height: 24 }}
        >
          {!isDeletedOnly && (
            <>
              <WarningOutlined
                fontSize="large"
                color="secondary"
                sx={{ mr: 1 }}
              />
              未配車の回収スケジュールがあります。
            </>
          )}
        </Typography>

        <FormControlLabel
          label="設定対象外を表示する"
          control={
            <Checkbox
              checked={isDeletedOnly}
              onChange={() => setIsDeletedOnly((prev) => !prev)}
            />
          }
        />
      </Stack>

      <Box
        sx={{
          ...(rowCountState > size && { height: 571 }),
          width: "100%",
        }}
      >
        <DataGridPro
          autoHeight={rowCountState > size ? false : true}
          rows={dataRows}
          rowCount={rowCountState}
          columns={columns}
          rowHeight={46}
          onToolbar={false}
          checkboxSelection={false}
          multipleSelection={false}
          disableSelectionOnClick={false}
          disableColumnMenu={true}
          rowsPerPageOptions={[10]}
          pageSize={size}
          onPageChange={(newPage) => setPage(newPage)}
          loading={isUnassignedLoading}
          pagination
          hideFooterPagination={isUnassignedLoading}
        />
      </Box>
    </Box>
  );
};

export default NotDispatchVehicles;
