import { createWaste } from "api/graphql/mutations";
import { searchWasteIncludingClientsByOffset } from "api/graphql/queries";
import { API, graphqlOperation } from "utils/graphqlOperation";
import { add as addAlert } from "ducks/Alert";
import { WeightConvevrtedValueUnitsSelector } from "ducks/WeightConvertedValueUnits";
import { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { XGridTableGuiderSteps } from "utils/GuiderSteps";
import { debugLog } from "utils/log";
import { useXGridComponents } from "utils/useXGridComponents";
import { FILTER_OPERATORS_CONTAINS_EQUAL } from "utils/constant";

const columns = [
  {
    field: "wasteTypeOrder",
    headerName: "廃棄物種類コード",
    minWidth: 200,
    flex: 1,
    valueGetter: (params) =>
      `${params.row.type?.largeClass?.code ?? ""}${
        params.row.type?.middleClass?.code ?? ""
      }${params.row.type?.smallClass?.code ?? ""}`,
    filterable: false,
  },
  {
    field: "wasteTypeLargeClassName",
    headerName: "廃棄物種類大分類",
    minWidth: 200,
    flex: 1,
    valueGetter: (params) => params.row.type?.largeClass?.name,
    filterOperators: FILTER_OPERATORS_CONTAINS_EQUAL,
  },
  {
    field: "wasteTypeMiddleClassName",
    headerName: "廃棄物種類中分類",
    minWidth: 200,
    flex: 1,
    valueGetter: (params) => params.row.type?.middleClass?.name,
    hide: true,
    filterOperators: FILTER_OPERATORS_CONTAINS_EQUAL,
  },
  {
    field: "wasteTypeSmallClassName",
    headerName: "廃棄物種類小分類",
    minWidth: 200,
    flex: 1,
    valueGetter: (params) => params.row.type?.smallClass?.name,
    hide: true,
    filterOperators: FILTER_OPERATORS_CONTAINS_EQUAL,
  },
  {
    field: "name",
    headerName: "廃棄物の名称",
    minWidth: 200,
    flex: 1,
    filterOperators: FILTER_OPERATORS_CONTAINS_EQUAL,
  },
  {
    field: "wastePackingName",
    headerName: "荷姿",
    minWidth: 135,
    flex: 1,
    valueGetter: (params) => params.row.packing?.name,
    filterOperators: FILTER_OPERATORS_CONTAINS_EQUAL,
  },
  {
    field: "dischargeFrequencyIntervalName",
    headerName: "排出頻度",
    minWidth: 135,
    flex: 1,
    valueGetter: (params) => params.row.dischargeFrequency?.interval?.name,
    filterOperators: FILTER_OPERATORS_CONTAINS_EQUAL,
  },
  {
    field: "flowCount",
    type: "number",
    headerName: "処理フロー数",
    minWidth: 165,
    flex: 1,
    filterOperators: FILTER_OPERATORS_CONTAINS_EQUAL,
  },
  {
    field: "reportingMedia",
    headerName: "報告方法",
    valueGetter: (params) =>
      params.row.reportingMedia.map((item) => item).join(),
    minWidth: 155,
    flex: 1,
    filterOperators: FILTER_OPERATORS_CONTAINS_EQUAL,
  },
];

/**
 * 廃棄物を選択するコンテナコンポーネントです
 */
export const Container = ({
  render,
  value,
  managedCompanyId,
  dischargeSourceWorkplaceId,
  multiple = false,
  checkbox = false,
  specificEndDate = false,
  onSpecificEndDate = (data) => debugLog(data),
  onSelected = (data) => debugLog(data),
  initialState,
  visibleActions = true,
  onRegistered = () => {},
  ...props
}) => {
  const [open, setOpen] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const weightConvevrtedValueUnits = useSelector(
    WeightConvevrtedValueUnitsSelector
  );

  const xGridObject = useXGridComponents(
    columns,
    searchWasteIncludingClientsByOffset,
    {
      other: {
        dischargeSourceWorkplaceId: dischargeSourceWorkplaceId,
      },
    },
    initialState
  );

  const formRef = useRef(null);

  const dispatch = useDispatch();

  const handleChangeSelection = (value) => {
    onSelected &&
      onSelected({
        value: multiple
          ? xGridObject.params.rows.filter((row) => value.includes(row.id))
          : xGridObject.params.rows.find((row) => row.id === value[0]),
        info: {
          search: xGridObject.search,
        },
      });
  };

  const handleSubmit = (data) => {
    setIsSubmit(true);
    const {
      number,
      type,
      kind,
      name,
      packing,
      packingDetails,
      quantityUnit,
      weightConvertedValue,
      dischargeFrequency,
      dischargeAmountPerOneTime,
      isImportedWaste,
      flows,
    } = data;

    API.graphql(
      graphqlOperation(createWaste, {
        input: {
          number: number,
          wasteTypeId: type.id,
          kind: kind,
          name: name,
          wastePackingCode: packing?.code,
          packingDetails: packingDetails,
          wasteQuantityUnitCode: quantityUnit.code,
          weightConvertedValue:
            quantityUnit?.name === "kg"
              ? 1
              : quantityUnit?.name === "t"
              ? 1000
              : weightConvertedValue,
          weightConvertedValueUnitId: weightConvevrtedValueUnits.find(
            (item) => item.name === quantityUnit.name
          )?.id,
          dischargeFrequency: {
            wasteDischargeIntervalId:
              dischargeFrequency?.wasteDischargeInterval?.id,
            numberOfTimes: dischargeFrequency?.numberOfTimes,
          },
          dischargeAmountPerOneTime: dischargeAmountPerOneTime,
          isImportedWaste: isImportedWaste,
          dischargeSourceWorkplaceId: dischargeSourceWorkplaceId,
          wasteFlowIds: flows.map((item) => item.id),
        },
      })
    )
      .then((res) => {
        xGridObject.functions.refetch();
        dispatch(
          addAlert({
            value: "登録しました。",
            severity: "success",
          })
        );
        setOpen(false);
        onRegistered(res.data.createWaste);
      })
      .catch((err) => {
        debugLog(err);
        dispatch(
          addAlert({
            value: "エラーが発生したため、登録できませんでした。",
            severity: "error",
          })
        );
      })
      .finally(() => {
        setIsSubmit(false);
      });
  };

  const handleClickRegister = () => {
    formRef.current.submit();
  };

  return render({
    ...props,
    open: open,
    multiple: multiple,
    checkbox: checkbox,
    onCloseDialog: () => setOpen(false),
    onOpenDialog: () => setOpen(true),
    getDataSubmit: handleSubmit,
    onChangeSelection: handleChangeSelection,
    GuiderSteps: XGridTableGuiderSteps,
    xGridParams: xGridObject.params,
    isSubmit: isSubmit,
    formRef: formRef,
    onClickRegister: handleClickRegister,
    managedCompanyId: managedCompanyId,
    visibleActions: visibleActions,
  });
};
