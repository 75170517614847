import React from "react";
import { Tooltip, Typography } from "@mui/material";

/**
 * トリミングされたテキストを表示するコンポーネントです
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */

export const TruncatedText = ({ text, maxWidth = 200 }) => {
  return (
    <Tooltip title={text}>
      <Typography
        sx={{
          maxWidth: maxWidth,
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          display: "block",
          cursor: "pointer",
        }}
      >
        {text}
      </Typography>
    </Tooltip>
  );
};
