import { yupResolver } from "@hookform/resolvers/yup";
import { useImperativeHandle, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useOpener } from "utils/useOpener";
import { basicSchema } from "./Validations";
import { companySelector } from "ducks/Company";
import { useSelector } from "react-redux";

/**
 * コンテナ交換入力フォームを表示するコンテナコンポーネントです。
 */
export const Container = ({
  render,
  onSubmit = () => {},
  onError = () => {},
  disabled = false,
  info,
  ...props
}) => {
  const containerSelectorForm = useOpener();
  const clientWorkplaceSelectorForm = useOpener();
  const vehicleSelectorForm = useOpener();
  const company = useSelector(companySelector);

  const { trigger, getValues, control, formState, setValue, watch } = useForm({
    mode: "onChange",
    resolver: yupResolver(basicSchema),
    defaultValues: {
      replacementContainer: null,
      storageWorkplace: null,
      shippedOn: null,
      replacedOn: null,
      storedOn: null,
      vehicle: null,
      personInChargeName: "",
      remarks: "",
    },
  });

  const [shippedOn, replacedOn, storedOn] = watch([
    "shippedOn",
    "replacedOn",
    "storedOn",
  ]);

  useEffect(() => {
    if (!!shippedOn && !watch("replacedOn")) {
      setValue("replacedOn", shippedOn, {
        shouldValidate: true,
        shouldDirty: true,
      });
    }

    if (!!shippedOn && !watch("storedOn")) {
      setValue("storedOn", shippedOn, {
        shouldValidate: true,
        shouldDirty: true,
      });
    }
  }, [watch, setValue, shippedOn]);

  useEffect(() => {
    if (!!replacedOn && !watch("shippedOn")) {
      setValue("shippedOn", replacedOn, {
        shouldValidate: true,
        shouldDirty: true,
      });
    }

    if (!!replacedOn && !watch("storedOn")) {
      setValue("storedOn", replacedOn, {
        shouldValidate: true,
        shouldDirty: true,
      });
    }
  }, [watch, setValue, replacedOn]);

  useEffect(() => {
    if (!!storedOn && !watch("shippedOn")) {
      setValue("shippedOn", storedOn, {
        shouldValidate: true,
        shouldDirty: true,
      });
    }

    if (!!storedOn && !watch("replacedOn")) {
      setValue("replacedOn", storedOn, {
        shouldValidate: true,
        shouldDirty: true,
      });
    }
  }, [watch, setValue, storedOn]);

  useImperativeHandle(props._ref, () => ({
    submit: () => {
      trigger().then((res) => {
        if (res) {
          onSubmit(getValues());
        } else {
          onError(formState.errors);
        }
      });
    },
    get: () => getValues(),
  }));

  return render({
    ...props,
    control: control,
    disabled: disabled,
    containerSelectorForm: containerSelectorForm,
    clientWorkplaceSelectorForm: clientWorkplaceSelectorForm,
    vehicleSelectorForm: vehicleSelectorForm,
    company: company,
    info: info,
    watch: watch,
    setValue: setValue,
    trigger: trigger,
  });
};
