import yup, { dateSchema, textSchema, remarksSchema } from "utils/yup";

export const basicSchema = yup.object().shape({
  storageWorkplace: yup.object().required(),
  storedOn: dateSchema
    .required()
    .test(
      "storedOn",
      "移動終了日は撤去日以降である必要があります。",
      function (value) {
        const { removedOn } = this.parent;
        if (removedOn) {
          return value >= removedOn;
        }
        return true;
      }
    ),
  removedOn: dateSchema
    .required()
    .test(
      "removedOn",
      "撤去日は移動終了日以前である必要があります。",
      function (value) {
        const { storedOn } = this.parent;
        if (storedOn) {
          return value <= storedOn;
        }
        return true;
      }
    ),
  vehicle: yup.object().nullable(),
  personInChargeName: textSchema.nullable(),
  remarks: remarksSchema.nullable(),
});
