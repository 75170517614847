import { yupResolver } from "@hookform/resolvers/yup";
import { useSelector } from "react-redux";
import {
  Box,
  CircularProgress,
  Container,
  Grid,
  Paper,
  Stack,
} from "@mui/material";
import { getWorkplace } from "api/graphql/queries";
import { Controller, useForm } from "react-hook-form";
import { selector } from "ducks/User";
import { formatDisplayAddress, formatDisplayDate } from "utils/format";
import { useQuery } from "utils/useQuery";
import { DatePicker, InformationItem, TextField } from "views/atoms";
import { FullScreenDialog } from "views/molecules";
import { basicSchema } from "./Validations";

/**
 * 現地確認を行うフォームコンポーネントです。
 * @param {object} props プロパティ
 * @param {boolean} props.open 開閉状態
 * @fires ConfirmForm#onClose 閉じる時
 * @fires ConfirmForm#onSubmit 送信時
 * @fires ConfirmForm#onError エラー時
 * @param {boolean} props.disabled 無効設定
 * @param {object} details 情報
 * @returns {JSX.Element}
 */
export const ConfirmForm = ({
  open = false,
  onClose = () => {},
  value,
  onSubmit = () => {},
  onError = () => {},
  disabled = false,
  details,
}) => {
  const workplace = useQuery({
    query: getWorkplace,
    variables: {
      id: details?.installationWorkplaceId,
    },
  });

  const { user } = useSelector(selector);

  const { trigger, getValues, formState, control } = useForm({
    mode: "onBlur",
    resolver: yupResolver(basicSchema),
    defaultValues: {
      personInChargeName: user?.name ?? "",
      confirmedOn: new Date(),
    },
  });

  const handleSubmit = async () => {
    const isValid = await trigger();
    if (isValid) {
      onSubmit(getValues());
    } else {
      onError(formState.errors);
    }
  };

  if (workplace?.loading) {
    return <CircularProgress />;
  }

  return (
    <FullScreenDialog
      title="コンテナの現在地を確認する"
      textConfirm="登録"
      open={open}
      onClose={onClose}
      onClickSubmit={handleSubmit}
    >
      <Container maxWidth={"md"}>
        <Stack spacing={4}>
          <Paper sx={{ p: 3 }}>
            <Stack spacing={3}>
              <Box>
                <InformationItem label={"最終確認日"}>
                  {formatDisplayDate({ source: details?.latestConfirmedOn })}
                </InformationItem>
              </Box>
              <Box>
                <InformationItem label={"最終確認者"}>
                  {details?.latestConfirmerName ?? ""}
                </InformationItem>
              </Box>
              <Box>
                <InformationItem label={"設置・保管日"}>
                  {formatDisplayDate({
                    source: details?.installedOn,
                  })}
                </InformationItem>
              </Box>
              <Box>
                <InformationItem label={"事業者"}>
                  {details?.installationCompanyName ?? ""}
                </InformationItem>
              </Box>
              <Box>
                <InformationItem label={"事業場"}>
                  {details?.installationWorkplaceName ?? ""}
                </InformationItem>
              </Box>
              <Box>
                <InformationItem label={"住所"}>
                  {formatDisplayAddress(workplace?.data?.getWorkplace)}
                </InformationItem>
              </Box>
            </Stack>
          </Paper>
          <Paper sx={{ p: 3 }}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Controller
                  name={`personInChargeName`}
                  control={control}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => (
                    <TextField
                      label="確認者"
                      required={true}
                      placeholder="確認者を入力します。"
                      value={value}
                      onChange={onChange}
                      error={!!error}
                      helperText={error?.message}
                      disabled={disabled}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name={`confirmedOn`}
                  control={control}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => (
                    <DatePicker
                      label="確認日"
                      required={true}
                      value={value}
                      onChange={onChange}
                      error={!!error}
                      helperText={error?.message}
                      disabled={disabled}
                      maxDate={new Date()}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Paper>
        </Stack>
      </Container>
    </FullScreenDialog>
  );
};
