import React, { useState, useMemo } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import CircularProgress from "@mui/material/CircularProgress";
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";

pdfjs.GlobalWorkerOptions.workerSrc = `${process.env.PUBLIC_URL}/pdf.worker.min.mjs`;

/**
 * PDFを表示するコンポーネントです。
 * @param {object} props プロパティ
 * @param {object} props.source PDFのソース
 * @returns {JSX.Element}
 */
export const AllPagesPDFView = ({ source }) => {
  const [pagesNumber, setPagesNumber] = useState(null);
  const [error, setError] = useState("");

  const handleLoadSuccess = ({ numPages }) => {
    setPagesNumber(numPages);
  };

  const options = useMemo(() => {
    return {
      cMapUrl: `${process.env.PUBLIC_URL}/cmaps/`,
      cMapPacked: true,
      standardFontDataUrl: `${process.env.PUBLIC_URL}/standard_fonts/`,
    };
  }, []);

  if (error.length) {
    return <div>{error}</div>;
  }

  return (
    <Document
      file={source}
      options={options}
      loading={<CircularProgress />}
      onLoadError={() => {
        setError("ドキュメントのロード中にエラーが発生しました!");
      }}
      onSourceError={() => {
        setError("ドキュメントのソースが見つかりません");
      }}
      onLoadSuccess={handleLoadSuccess}
    >
      {pagesNumber &&
        Array.from(new Array(pagesNumber), (el, index) => (
          <Page key={`page_${index + 1}`} pageNumber={index + 1} />
        ))}
    </Document>
  );
};
